import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import "./employeeCountry.scss";

const EmployeeCountry = ({ onChange, itemToAdd, isAlreayDataPresent }) => {
    const asyncTypeaheadRef = useRef(null);
    const [option, setOption] = useState([]);
    const [selectedCountryLocationObject, setSelectedCountryLocationObject] = useState({
        isHidden: true,
        selected: [],
    });

    useEffect(() => {
        if (isAlreayDataPresent) {
            if (!isAlreayDataPresent?.data?.length) {
                setSelectedCountryLocationObject(prevState => ({
                    ...prevState,
                    selected: []
                }));
            } else if (
                !isAlreayDataPresent?.data.every((element) =>
                    selectedCountryLocationObject.selected.includes(element)
                )
            ) {
                setSelectedCountryLocationObject(prevState => ({
                    ...prevState,
                    selected: isAlreayDataPresent?.data
                }));
            }
        }
    }, [isAlreayDataPresent]);

    const countries = useSelector(({ discoveryReducer }) => discoveryReducer.metadata.countries);

    useEffect(() => {
        if (countries?.length) {
            setOption(countries);
        }
    }, [countries]);

    useEffect(() => {
        if (Object.keys(itemToAdd)?.length) {
            setSelectedCountryLocationObject((prevState) => ({
                ...prevState,
                selected: prevState.selected.filter((item) => item !== itemToAdd)
            }));
        }
    }, [itemToAdd]);

    useEffect(() => {
        if (!selectedCountryLocationObject?.isHidden) {
            setTimeout(() => {
                asyncTypeaheadRef?.current?.focus();
            }, 0);
        }
    }, [selectedCountryLocationObject?.isHidden]);

    const handlePlus = () => {
        setSelectedCountryLocationObject({
            ...selectedCountryLocationObject,
            isHidden: false
        });
    };

    const handleBlur = () => {
        setSelectedCountryLocationObject({
            ...selectedCountryLocationObject,
            isHidden: true
        });
    };

    const handleChange = (val) => {
        if (val.length > 0) {
            const newSelected = [val[0]];
    
            setSelectedCountryLocationObject((prevState) => ({
                ...prevState,
                selected: newSelected,
                isHidden: true,
            }));
    
            onChange(newSelected);
        }
    };

    const handleRemove = (itemToRemove) => {
        const newSelected = selectedCountryLocationObject.selected.filter(
            (item) => item !== itemToRemove
        );

        setSelectedCountryLocationObject((prevState) => ({
            ...prevState,
            selected: newSelected
        }));
        onChange(newSelected);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const renderComponent = () => {
        return (
            <Form.Group className="mb-3 m-r-5" controlId="formBasicEmail">
                <div className="f-s-15 mb-2">Country</div>
                <div className="d-flex flex-wrap">
                    <div
                        className="plusBtn d-flex justify-content-center align-items-center"
                        onClick={() => handlePlus()}
                    >
                        {" "}
                        +{" "}
                    </div>
                    {selectedCountryLocationObject?.selected?.length
                        ? selectedCountryLocationObject?.selected?.map((item, index) => (
                            <span
                                key={index}
                                className="selectedItem d-flex justify-content-between align-items-center"
                            >
                                {item}{" "}
                                <span className="closeBtn" onClick={() => handleRemove(item)}>
                                    {" "}
                                    x{" "}
                                </span>
                            </span>
                        ))
                        : null}
                    {!selectedCountryLocationObject?.isHidden ? (
                        <Typeahead
                            ref={asyncTypeaheadRef}
                            labelKey="countries"
                            id={`country-id`}
                            multiple={false}
                            flip={true}
                            clearButton={false}
                            onBlur={() => handleBlur()}
                            onChange={(val) => handleChange(val)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            options={option || []}
                            selected={[]}
                            placeholder="Select"
                            className="mb-3 founder-metro-typeahead-dropdown"
                            useCache={false}
                            maxResults={15}
                            positionFixed={true}
                            renderToken={(option, props, idx) => (
                                <Token className={idx > 0 ? "hidden" : "hidden"}>
                                    <></>
                                </Token>
                            )}
                            renderMenuItemChildren={(option, props) => <div>{option}</div>}
                        />
                    ) : null}
                </div>
            </Form.Group>
        );
    };

    return <div>{renderComponent()}</div>;
};

export default EmployeeCountry;
