import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListsAndBucketsOptions } from '../../../store/actions';
import { axiosListsAndBucketsOptions } from '../../../config/appConfig/axiosInstance';
import { Form } from 'react-bootstrap';
import { ReactComponent as CrossIcon } from "../../../assets/images/icon_svg/X.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/icon_svg/search_icon.svg";
import imgLoader from "../../../assets/images/spinner.gif";
import './companyLists.scss';

const CompanyLists = ({ position, className, onClose, selectedOptions, loadingListData, callbackFn }) => {
  const dispatch = useDispatch();
  const dropdownListRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const listOptions = useSelector(({ commonReducer }) => commonReducer.commonListsBucketsOptions.lists || []);

  useEffect(() => {
    if (!listOptions.length) {
      dispatch(getListsAndBucketsOptions(axiosListsAndBucketsOptions));
    }
  }, [dispatch]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownListRef.current && !dropdownListRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [onClose]);

  const handleSelectList = (list) => {
    const isAlreadySelected = selectedOptions.some((item) => item.workboard_id === list.workboard_id);
     let finalArr = isAlreadySelected
        ? selectedOptions.filter((item) => item.workboard_id !== list.workboard_id)
        : [list, ...selectedOptions, ];
        callbackFn(finalArr);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredListOptions = listOptions.filter((list) =>
    list.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleClearListSearch = () => {
    setSearchQuery('');
  }

  return (
    <div className="company-lists-container" ref={dropdownListRef}>
      <div className="dropdown-options">
        <Form.Group controlId="searchTerm">
          <div className="list-input-container">
            <SearchIcon className="list-search-icon" />
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="mb-2 custom-box"
              autoComplete='off'
            />
            <CrossIcon className="list-cross-icon" onClick={handleClearListSearch} />
          </div>
        </Form.Group>
        {selectedOptions?.length > 0 && !searchQuery && (
          <div className="selected-options">
            {selectedOptions.map((list) => (
              <span className="dropdown-item" key={`${list.workboard_id}-${selectedOptions.length}`}>
                <Form.Check
                  type="checkbox"
                  id={`selected-checkbox-${list.workboard_id}`}
                  label={list.name}
                  checked={true}
                  onChange={() => handleSelectList(list)}
                  disabled={loadingListData && selectedOptions[0]?.workboard_id !== list.workboard_id}
                  className={`${className} ${loadingListData && selectedOptions[0]?.workboard_id !== list.workboard_id ? 'disabled-checkbox' : ''}`.trim()}
                />
              </span>
            ))}
            <hr className="separator-line" />
          </div>
        )}
        {listOptions.length === 0 ? (
          <div className="loader-container">
            <img src={imgLoader} alt="Loading..." className="loader-img" />
          </div>
        ) : (
          <>
            <div className="unselected-options">
              <div className="dropdown-list">
                {filteredListOptions.map((list) => (
                  <span className="dropdown-item" key={`${list.workboard_id}-${selectedOptions.length}`}>
                    <Form.Check
                      type="checkbox"
                      id={`checkbox-${list.workboard_id}`}
                      label={list.name}
                      checked={selectedOptions?.some((item) => item.workboard_id === list.workboard_id)}
                      onChange={() => handleSelectList(list)}
                      disabled={loadingListData && selectedOptions[0]?.workboard_id !== list.workboard_id}
                      className={`${className} ${loadingListData && selectedOptions[0]?.workboard_id !== list.workboard_id ? 'disabled-checkbox' : ''}`.trim()}
                    />
                  </span>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyLists;
