// import lib
import React, { useState, useEffect,useRef, createRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Card, Button, Modal, ModalFooter } from "react-bootstrap";
import 'babel-polyfill'
// import DatePicker from "react-datepicker";
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import moment from "moment";
import axios from "axios";
import ReactQuill from 'react-quill';
import Highcharts from "highcharts";
import Dropzone from 'react-dropzone';
import * as amplitude from '@amplitude/analytics-browser';
// import misc
import Loader from "../../commonComponents/Loader/Loader";
import CommonError from "../../commonComponents/CommonError/CommonError";
import { axiosDraftList, axiosDraftDetail } from "../../config/appConfig/axiosInstance";
import { getFileMatricsTemplate,  getSearchResultWithoutTerm, submitMatrics,  getHtml, saveGraphOptions, submitGraph, getTable, addMoreFn,
     resetMoreData, deleteMoreFn, resetMultiOccurence, getAllDraft, getDraftDetail } from "../../store/actions/submissionsSliderAction"
import { getAddress } from "../../utils/getServerAddress";
import Chart from "../../commonComponents/Chart/Chart";
import { removeUnderScoreAndCapitalize } from "../../utils/removeUnderScoreAndCapitalize";
import SubmitCard from "../../commonComponents/SubmitCard/SubmitCard"
import * as actionTypes from '../../store/actionTypes'
import {CustomDate} from "../../commonComponents/Datepicker/CustomDatePicker";
import {CustomRangePicker} from "../../commonComponents/Datepicker/RangePicker";
import { DraftSlide} from "./DraftSlide"
import {LoaderSubmission} from "../../commonComponents/LoadingAnimation/LoaderComponents"
import LoaderTable from "../../commonComponents/LoadingAnimation/LoaderTable";
import SubmissionTableView from "../../commonComponents/TableView/SubmissionTableView";
// import svg
import { ReactComponent as EditSvg } from "../../assets/images/icon_svg/edit.svg";
import { ReactComponent as SaveSvg } from "../../assets/images/icon_svg/save.svg";
import { ReactComponent as DeleteSvg } from "../../assets/images/icon_svg/delete.svg";
import { ReactComponent as DocumentSvg } from "../../assets/images/icon_svg/document.svg";
import { ReactComponent as BackwardSvg } from "../../assets/images/icon_svg/backward.svg";
// import css
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-quill/dist/quill.snow.css';
import './submissions.scss'
import {emailValidation } from "../../utils/emailValidation";
import Nofilter from "../../commonComponents/NoFilter/NoFilter";
// import {DraftView} from "../../component/Submissions/DraftView";
let modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false, 5, 6] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote',],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
let formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]
let updateStatic = {}, isCallSummariesEditing= false, currentUuid="";
let final, timeout;
let cancelToken;
let axiosInstance;
let requestUrl;
const Submissions = () => {
    const { activeSubmissionItem} = useSelector((state => state.commonReducer))

    const dropZoneRefs = createRef();
    const dispatch = useDispatch();
    const [draftModal, setDraftModal] = useState({
        show: false,
        modalData: {},
        selectedBtn: "",
        draftId: ""
    })
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [focusedEditor, setFocusedEditor] = useState(false)

    const [modal, setTableModal] = useState({
        show: false,
        tableObject: {},
        itemObject: []
    })
    const [apiModal, setApiModal] = useState({
        isLoading: false,
        show: false,
        modalObject: {},
        hasError: false,
        errorMessage: "",
        isBtnDisable: false,
    })
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState({
        show: false,
        selectedid: "",
        isLoading: false,
        isBtnDisable: false
    });
    const [file, setFile] = useState(null);
    const [display, setDisplay] = useState({})
    const [form, setForm] = useState({})
    const [editor, setEditor] = useState({})
    const [selected, setSelected] = useState({});
    const [data, setData] = useState({
        loading: false,
        isError: false,
        errorMessage: "",
        btnFlag: false,
        template: "",
        templateUrl: "",
        openModal: false,
        isSuccess: null,
        message: "",
    });
     
    const [dateFields, setDateFields] = useState({
        from: moment(new Date().setHours(0, 0, 0, 0)),
        to: moment(new Date().setHours(23, 59, 59, 0)),
    });

    const [matricTemplate,  graphOptions, tableData, moreCallData, multipleOccurence, draftList, draftDetails] = useSelector(({ submissionsSliderReducer }) => [submissionsSliderReducer.template, submissionsSliderReducer.graphOption, submissionsSliderReducer.tableData, submissionsSliderReducer.moreCallData, submissionsSliderReducer.summaryObj, submissionsSliderReducer?.draftList, submissionsSliderReducer?.draftDetail]);

    useEffect(() => {
        dispatch(getAllDraft(axiosDraftList))
        return () => {
            setData({})
            setForm({})
            setDisplay({})
            setDateFields({
                from: moment(new Date().setHours(0, 0, 0, 0)),
                to: moment(new Date().setHours(23, 59, 59, 0)),
            })
            dispatch({type:actionTypes?.RESET_FILE_MATRICS_TEMPLATE_SLIDER})
            dispatch(resetMoreData())
            dispatch({type:actionTypes?.RESET_DRAFT_DETAIL_SLIDER})
        }
    }, [])
 
    useEffect(()=>{
        if(data?.templateUrl != activeSubmissionItem?.url) {
            dispatch({type:actionTypes?.RESET_FILE_MATRICS_TEMPLATE_SLIDER})
        }
        setData({
            ...data,
            template: activeSubmissionItem?.name,
            templateUrl: activeSubmissionItem?.url,
            templateObj: activeSubmissionItem,
            isSuccess: false,
            openModal: false,
        })
        amplitude.logEvent(`${activeSubmissionItem?.name} Loaded`);
    },[activeSubmissionItem])

    const handleDrop = (e) => {
        setFile([...e]);
    };

    useEffect(() => {
        if(data?.template && !(matricTemplate && Object.keys(matricTemplate).length) ) {
            if (axiosInstance) {
                requestUrl.cancel("operation cancelled due to new req")
                getTemplate()
            } else {
                if(data?.templateUrl != activeSubmissionItem?.url) return
                getTemplate()
            }
        }  
    }, [data?.template])


    const getTemplate = () => {
        const CancelToken = axios.CancelToken;
        requestUrl = CancelToken.source();
        let urlEndpoints = data?.templateUrl?.split("v1")
        axiosInstance = axios.create({
            cancelToken: requestUrl.token,
            baseURL: getAddress() + urlEndpoints[1]
        });
        setData({
            ...data,
            loading: true,
        })
        
        dispatch(getFileMatricsTemplate(axiosInstance)).then((res) => {
            axiosInstance = undefined;
            requestUrl = undefined;
            if (res.status === "success") {
                setData({
                    ...data,
                    isError: false,
                    errorMessage: "",
                    loading: false,
                })
            } else {
                setData({
                    ...data,
                    isError: res?.code === "ERR_CANCELED" ? false : true,
                    errorMessage: "Something went wrong!",
                    loading: res?.code === "ERR_CANCELED" ? true : false,
                })
            }
        })
    }

    useEffect(()=> {
        if(draftDetails && Object.keys(draftDetails)?.length ) {
            let moredataArr = [], compActiveWorking = [],activeMarket=[];
            draftDetails?.company_call_summaries?.map(i=>{
                let moreData = {
                    call_date:moment(i?.call_date).format(),
                    company_name: i?.company_name,
                    content:i?.content,
                    deck_attached: i?.deck_attached,
                    deck_file_urls: i?.deck_file_urls || [],
                    domain: i?.domain,
                    uuid: i?.uuid
                }
                moredataArr?.push(moreData);
            })
            dispatch({type: actionTypes?.UPDATE_MORE_CALL_DATA_SLIDER, data: moredataArr});
            draftDetails?.companies_actively_working?.forEach((item, idx) => {
                let compActiveWokingObj = {
                    companies: item?.companies?.map(i => {
                        return {
                            display_name: i?.name,
                            server_value: i?.domain
                        }
                    }),
                    companies_actively_working_companies_isLoading: false,
                    isEditing: false,
                    id: new Date().getTime() + idx + 1,
                    principal: [{
                        display_name: item?.principal,
                        server_value: item?.principal,
                    }]
                }
                compActiveWorking?.push(compActiveWokingObj)
            })
            draftDetails?.active_market_dives?.forEach((item,idx) => {
                let activeMarketObj =  {
                    markets_list: [...item?.project],
                    isEditing: false,
                    markets: "",
                    id: new Date().getTime() + idx+1,
                    principal: [{
                        display_name: item?.principal,
                        server_value: item?.principal,
                    }]
                }
                activeMarket?.push(activeMarketObj)
            })
            setForm({
                ...form,
                [`markets_list`]:[],
                ["companies_actively_working"]:compActiveWorking,
                [`active_market_dives`]: activeMarket,
                [`company_outreach`]: String(draftDetails?.interaction_metrics?.company_outreach),
                [`company_conversations`]: String(draftDetails?.interaction_metrics?.company_conversations),
                [`customer_network_outreach`]: String(draftDetails?.interaction_metrics?.customer_network_outreach),
                [`customer_network_conversations`]: String(draftDetails?.interaction_metrics?.customer_network_conversations),
            })
            setDateFields({
                ...dateFields,
                [`from`]: moment(draftDetails?.from_date),
                ["to"]: moment(draftDetails?.to_date),
            })
            setEditor({
                ...editor,
                content: ""
            })
        }   
    },[draftDetails])

    useEffect(() => {
        let finalArr = {}, displayArry = {},  editors={}, dropError = {};
        if (matricTemplate) {
            if (matricTemplate?.hasOwnProperty("display")) {
                matricTemplate?.display?.sections?.map((cards) => {
                    if (cards?.occurrence === "multiple") {
                        displayArry[cards.name] = []
                    }
                    cards?.data_model?.attributes?.map((item) => {
                        let opt = cards.name?.split("_")?.shift()
                        if (data?.template === "Associate Weekly Metrics Graph" && item?.data_type === "datetime") {
                            displayArry[`${opt}_${item?.name}`] = item?.name === "start_time" ? (moment(new Date(new Date().setHours(0, 0, 0, 0))).subtract(30, 'days')) : item?.name === "end_time" ? moment(new Date().setHours(23, 59, 59, 0)) : "";
                        }
                        if (data?.template === "Associate Weekly Metrics Graph" && item?.data_type === "graph") {
                            getGraphOption(item, opt).then(res => {
                                dispatch(saveGraphOptions(res))
                            })
                        }
                        if (item?.data_type === "list") {
                            displayArry[`${item?.name}_list`] = []
                        }
                        if (item?.data_type === "editor") {
                            editors[`${item?.name}`] = ``
                        }
                        if (item?.data_type === "dropdown") {
                            displayArry[`${cards.name}_${item?.name}`] = []
                        }
                        if (item?.data_type === "dropdown" && item?.describe?.hasOwnProperty("values") && item?.describe?.typeahead === "false") {
                            updateStatic[`${cards.name}_${item?.name}`] = item?.describe?.values?.map(String)
                            setSelected({
                                ...selected,
                                ...updateStatic
                            })
                        } else if (item?.data_type === "dropdown" && item?.describe?.hasOwnProperty("values_endpoint") && item?.describe?.typeahead === "false") {
                            getOptions(item?.describe?.values_endpoint, item?.name, cards.name).then(res => {
                                setSelected({
                                    ...selected,
                                    ...res
                                })
                            })
                        } else {
                            displayArry[item?.name] = "";
                        }
                    })
                })
                setDisplay(displayArry)
            }
            matricTemplate?.request_schema?.sections?.map((cards) => {
                let dateRange=[];
                if (cards?.occurrence === "multiple") {
                    finalArr[cards.name] = []
                }
                cards?.data_model?.attributes?.map((item) => {
                    let opt = cards.name?.split("_")?.shift()
                    if (data?.template === "Associate Weekly Metrics Graph" && item?.data_type === "datetime") {
                        finalArr[`${opt}_${item?.name}`] = item?.name === "start_time" ? (moment(new Date(new Date().setHours(0, 0, 0, 0))).subtract(30, 'days')) : item?.name === "end_time" ? moment(new Date().setHours(23, 59, 59, 0)) : "";
                        dateRange?.push(finalArr[`${opt}_${item?.name}`])
                        finalArr[`${opt}_dateRange`] = [...dateRange]
                    }
                    if (data?.template === "Associate Weekly Metrics Graph" && item?.data_type === "graph") {
                        getGraphOption(item, opt).then(res => {
                            dispatch(saveGraphOptions(res))
                        })
                    }
                    if (item?.data_type === "file") {
                        finalArr[`${item?.name}_list`] = null
                    }
                    if (item?.data_type === "list") {
                        finalArr[`${item?.name}_list`] = []
                    }
                    if (item?.data_type === "editor") {
                        editors[`${item?.name}`] = ``
                    } 
                    if(item?.data_type !== "editor"  || item?.data_type !== "list" || item?.data_type === "file" ) {
                        finalArr[item?.name] = item?.data_type === "dropdown" ? [] : "";
                    }
                    if (item?.data_type === "dropdown") {
                        finalArr[`${cards.name}_${item?.name}`] = item?.describe?.hasOwnProperty("default") ? [item?.describe?.default] : []
                        dropError[`${cards.name}_${item?.name}`] = ""

                    }
                    if (item?.data_type === "dropdown" && item?.describe?.hasOwnProperty("values") && item?.describe?.typeahead === "false") {
                        updateStatic[`${cards.name}_${item?.name}`] = item?.describe?.values?.map(String)
                        setSelected({
                            ...selected,
                            ...updateStatic
                        })
                    } else if (item?.data_type === "dropdown" && item?.describe?.hasOwnProperty("values_endpoint") && item?.describe?.typeahead === "false") {
                        getOptions(item?.describe?.values_endpoint, item?.name, cards.name).then(res => {
                            setSelected({
                                ...selected,
                                ...res
                            })
                        })
                    } else if (item?.data_type === "dropdown" && item?.describe?.hasOwnProperty("values_endpoint") && item?.describe?.typeahead === "true") {
                        finalArr[`${cards.name}_${item?.name}_isLoading`] = false
                        finalArr[`${cards.name}_${item?.name}_isLoading_error`] = ""
                    }
                })
            })
            finalArr[`dropErrors`] = {...dropError};
            finalArr[`errors`] = {};
            editors[`errors`] = {};
            setEditor(editors)
            if(draftDetails && Object.keys(draftDetails)?.length) {
                return
            }   
            setForm(finalArr)
        }
    }, [matricTemplate])

    useEffect(() => {
        if (matricTemplate?.hasOwnProperty("display")) {
            getDisplayRefresh()
        }
    }, [display])

    const getDisplayRefresh = () => {
        let param = {}, url;
        matricTemplate?.display?.sections?.map((cards) => {
            cards?.data_model?.attributes?.map((item) => {
                if (item?.data_type === "dropdown") {
                    param[item.name] = typeof display[`${cards.name}_${item.name}`]?.[0] === "object" ? display[`${cards.name}_${item.name}`]?.[0]?.domain : typeof display[`${cards.name}_${item.name}`]?.[0] === "string" ? display[`${cards.name}_${item.name}`]?.[0] : null
                } else if (item?.data_type !== "table") {
                    param[item.name] = display[item.name] ? display[item.name] : null
                }
                if (item?.data_type === "table") {
                    let urlArr = item?.describe?.values_endpoint?.split("v1")
                    let newArr = urlArr[1]?.split("?")
                    url = getAddress() + newArr[0]
                }
            })
        })
        setIsTableLoading(true)
        dispatch(getTable(url, param)).then(res=>{
            setIsTableLoading(false)
        })
    }

    const getGraphOption = async (currItem, name) => {
        let urlArr = currItem?.describe?.values_endpoint?.split("v1")
        let url = getAddress() + urlArr[1]
        let param = {
            scoper_email: currItem?.describe?.input_parameters?.scoper_email?.value,
            start_time: moment(new Date(moment(new Date(new Date().setHours(0, 0, 0, 0))).subtract(30, 'days'))).format(),
            end_time: moment(new Date(new Date().setHours(23, 59, 59, 0))).format()
        }
        let resp = await axios.get(`${url}`, {
            params: { ...param },
            headers: {
                "Authorization": localStorage.getItem('authData') ? "Bearer " + localStorage.getItem('authData') : null
            },
        })

        let groups = {}, newDate = "", output = [];
        if (!groups[name]) {
            groups[name] = {
                xAxis: {
                    categories: []
                },
                series: [],
            }
        }
        if (groups[name]) {
            resp?.data?.results?.forEach((item) => {
                newDate = `${moment(item?.from_date).format("L")}-${moment(item?.to_date).format("L")}`;
                groups[name][`xAxis`][`categories`].push(newDate)
                for (let itemKeys in item) {
                    let keyName = removeUnderScoreAndCapitalize(itemKeys)
                    let unique = output?.find(o => o.name === keyName);
                    if (!unique && keyName !== "Scoper" && keyName !== "From Date" && keyName !== "To Date" && keyName !== "Uuid") {
                        let newO = {
                            name: keyName,
                            data: [item[itemKeys || null]]
                        }
                        output.push(newO)
                    } else if (unique && Object.keys(unique).length) {
                        var existingIndex = output.findIndex(i => i.name === keyName);
                        output[existingIndex]?.data?.push(item[itemKeys])
                    }
                }
            })
            groups[name][`series`] = output
        }
        return groups
    }

    const handleDate = (date, name) => {
        let isError = { ...dateFields.errors };
        if (name === "from") {
            isError[name] = !date ? "Please enter start date" : "";
        }
        if (name === "to") {
            isError[name] = !date ? "Please enter end date" : "";
        }
        if (name === "from" && new Date(date) > new Date(dateFields?.to)) {
            isError["to"] = "To date must be greater than from date.";
            setDateFields({
                ...dateFields,
                ["to"]: "",
                [name]: date,
                errors: isError
            })

        } else if (name === "to" && new Date(date) < new Date(dateFields?.from)) {
            isError["from"] = "From date cannot be greater than To date.";
            setDateFields({
                ...dateFields,
                ["from"]: "",
                [name]: date,
                errors: isError
            })
        } else {
            setDateFields({
                ...dateFields,
                [name]: date,
                errors: isError
            })
        }
    }
    const handleAddListInputChange = (e, title) => {
        const { name, value } = e.target
        let isError = { ...form[`errors`] };
        isError[name] = !value ? `Please enter ${title?.toLowerCase()}` : ""
        if ((name === "company_name" && value) || (name === "domain" && value) || (name === "product" && value) || (name === "why_now" && value)) {
            isError[`company_call_summaries`] = ""
        }
        setForm({
            ...form,
            [name]: value == "," ? "": value,
            [`errors`]: isError
        })
    }

    const handleInputChange = (e, title) => {
        const { name, value } = e.target
        onChangeValidate(name, value, title)
    }
    const handleDateInputChange = (value, name, title) => {
        onChangeValidate(name, value, title)
    }
    const handleGraphDateInputChange = (dates, name, title, cardName) => {
        let start = "", end=""; 
        if(dates) {
            [start, end] = dates;
        }
        
        setForm({
            ...form,
            [`${cardName}_dateRange`]: [start, end]

        })
        let submissionBody = []
        matricTemplate?.request_schema?.sections?.forEach((cards, idx) => {
            let cardTitle = cards?.name?.split("_").shift();
            cards?.data_model?.attributes?.forEach((cardItem) => {
                if (cardItem?.data_type === "graph" && cardTitle === cardName) {
                    let urlArr = cardItem?.describe?.values_endpoint?.split("v1")
                    let url = urlArr ? getAddress() + urlArr[1] : null
                    let payload = {
                        scoper_email: cardItem?.describe?.input_parameters?.scoper_email?.value,
                        start_time: start ? moment(start).format() : null,
                        end_time: end ? moment(end).format() : null
                    }
                    let finalObj = {
                        url: url,
                        payload: { ...payload }
                    }
                    submissionBody.push(finalObj)
                }
            })
        })

        dispatch(submitGraph(submissionBody, cardName)).then(res => {
            setData({
                ...data,
            })
        })
    }
    const handleSearch = async (q, item, name, type, editIndx, selectedArr) => {
        let editObj = {...form[name]}
        if(type === "edit"){
            editObj[editIndx][`${name}_${item?.name}_isLoading`] = true
            setForm({
                ...form,
                ...editObj,
                [item?.name] : []
            })
        } else {
            setForm({
                ...form,
                [`${name}_${item?.name}_isLoading`] : type === "create"? true : false,
                [`${name}_${item?.name}_isLoading_error`] : "",
                [item?.name] : []
            })
        }
        if(cancelToken) {
            cancelToken.cancel("operation cancelled due to new req")
        }
        const CancelToken = axios.CancelToken;
        cancelToken = CancelToken.source();
        let result;
        try {
            let urlArr = item?.describe?.values_endpoint?.split("v1")
            let url = getAddress() + urlArr[1]
            result = await axios.get(`${url}?search_term=${q}`, {
                cancelToken: cancelToken.token,
                headers: {
                    "Authorization": localStorage.getItem('authData') ? "Bearer " + localStorage.getItem('authData') : null
                },
            })
            if (type === "edit") {
                editObj[editIndx][`${name}_${item?.name}_isLoading`] = false
            }
            let finalArr ;
            if(item.describe.choose_one === "true") {
                finalArr = result?.data?.results;
            } else {
                finalArr = result?.data?.results.filter(e => {
                return !selectedArr.some(item => item.server_value == e.server_value); // take the ! out and you're done
             });
            }
             
            setForm({
                ...form,
                ...editObj,
                [`${name}_${item?.name}_isLoading`]: false,
                [item?.name]: finalArr?.map((item) => {
                    if (item.hasOwnProperty("display_name")) {
                        return {
                            ...item
                        }
                    } else {
                        return {
                            ...item,
                            date_acquired: item?.date_acquired ? String(item?.date_acquired) : "",
                            display_name: item?.name ? item?.name : "",
                            server_value: item?.domain ? item?.domain : ""
                        }
                    }

                }) || [],
            })

        } catch (error) {
            if(error?.code === "ERR_CANCELED")  {
                console.log(error)
            } else {
                    if (type === "edit") {
                    editObj[editIndx][`${name}_${item?.name}_isLoading`] = false
                }
                setForm({
                    ...form,
                    ...editObj,
                    [`${name}_${item?.name}_isLoading`]: false,
                })
            }
        }
    }

    const getOptions = async (endpoint, name, cardName) => {
        let urlArr = endpoint?.split("v1")
        let url = getAddress() + urlArr[1]
        const { data } = await dispatch(getSearchResultWithoutTerm(url))
        if (data?.hasOwnProperty("results")) {
            updateStatic[`${cardName}_${name}`] =
                data?.results?.map((item) => {
                    if (item.hasOwnProperty("display_name")) {
                        return {
                            ...item
                        }
                    } else {
                        return {
                            ...item,
                            display_name: item?.name ? item?.name : "",
                            server_value: item?.id ? item?.id : ""
                        }
                    }
                })
        }
        if (data?.hasOwnProperty("data")) {
            updateStatic[`${cardName}_${name}`] = data?.data?.map((item) => {
                if (item.hasOwnProperty("display_name")) {
                    return {
                        ...item
                    }
                } else {
                    return {
                        display_name: item,
                        server_value: item
                    }
                }
            })
        }
        return updateStatic
    }
    const handleDropdownChange = (value, name, title, type, cardName) => {
        let isError = { ...form[`dropErrors`] };
        isError[`${cardName}_${name}`] = !value.length ? `Please enter ${title?.toLowerCase()}` : ""
        let selectedOptKey = `${cardName}_${name}`
        if (type === "display") {
            setDisplay({
                ...display,
                [selectedOptKey]: value,
            })
        } else {
            setForm({
                ...form,
                [selectedOptKey]: value,
                [`dropErrors`]: isError
            })
        }
    }

    const handleTypeaheadChanges = (value, item, type, cardName) => {
        let isError = { ...form[`dropErrors`] };
        isError[`${cardName}_${item?.name}`] = !value.length ? `Please enter ${item?.title?.toLowerCase()}` : ""
        let selectedOptKey = `${cardName}_${item?.name}`
        if (type === "display") {
            setDisplay({
                ...display,
                [selectedOptKey]: value,
                [item?.name]: [],
            })
        } else {
            setForm({
                ...form,
                [selectedOptKey]: value,
                // [item?.name]: [],
                [`dropErrors`]: isError
            })
        }
        if (value[0]?.server_value?.includes("https://")) {
            getHtmlForTemplate(value[0]?.server_value)
        }
    }
    const getHtmlForTemplate = (urlVal) => {
        let urlArr = urlVal?.split("v1")
        let url = getAddress() + urlArr[1]
        dispatch(getHtml(url)).then((resp) => {
            if (resp.status === "success") {
                setEditor({
                    ...editor,
                    [`html_template`]: resp?.data?.template
                })
            }
        });
    }
    const addList = (e, val, name) => {
        if (e.key == "Enter" && val) {
            let errorObj= {...form[`errors`]} ;
            errorObj[`${name}_list`] = ""
            setForm({
                ...form,
                [`${name}_list`]: [...form[`${name}_list`], val],
                [name]: "",
                [`errors`]: errorObj
            })
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const removeFromList = (name, index) => {
        let newForm = { ...form }
        let newlistArr = newForm[`${name}_list`].filter((ele, idx) => {
            return idx != index
        })
        setForm({
            ...form,
            [`${name}_list`]: [...newlistArr],
        })
    }
    const addMore = (cardObj) => {
        let errorObj= {...form[`errors`]} ;
        errorObj[cardObj?.name] = ""; 
        if (validateMoreCall(cardObj)) {
            return
        }
        if(cardObj?.data_model?.hasOwnProperty("api")) {
            final = cardObj?.data_model.attributes?.reduce((obj, item) => {
            obj[item.name] = item?.data_type === "editor" ? editor[item.name]: item?.data_type === "dropdown" ? form[`${cardObj?.name}_${item.name}`] : item?.data_type === "file" && form[`${cardObj?.name}_deck_attached`][0] === "Yes"? file : form[item.name]
            if(item?.data_type === "dropdown"){
                obj[`${cardObj?.name}_${item.name}`] = []
            }
            return obj
        }, {})

        let urlArr = cardObj?.data_model?.api?.endpoint?.split("v1")
        let url = getAddress() + urlArr[1] 

        setApiModal({
            ...apiModal,
            show: true,
            modalObject: final,
            api:url,
            cardName: cardObj?.name
        })
        setForm({
            ...form,
            [`errors`]: errorObj
        }) 
        } else {
            let final = cardObj?.data_model.attributes?.reduce((obj, item) => {
                obj[item.name] = item?.data_type === "editor" ? editor[item.name] : item?.data_type === "dropdown" ? form[`${cardObj?.name}_${item.name}`] : form[item.name]
                obj["id"] = Date.now()
                if(item?.data_type === "list"){
                    obj[`${item?.name}_list`] =  form[`${item?.name}_list`] 
                }
                if(item?.data_type === "dropdown" && item?.describe?.hasOwnProperty("values_endpoint") && item?.describe?.typeahead === "true"){
                    obj[`${cardObj?.name}_${item?.name}_isLoading`] = false
                }
                obj["isEditing"] = false;
                return obj
            }, {})
            setForm({
                ...form,
                [cardObj?.name]: [...form[cardObj?.name], final],
                [`errors`]: errorObj
            }) 
            dispatch(resetMultiOccurence({cardName: cardObj?.name, cardObject: final}))
        }
    }

    useEffect(() => {
        if (multipleOccurence && Object.keys(multipleOccurence)?.length) {
            let neWForm = { ...form }
            let neWEdit = { ...editor }
            for (var i in multipleOccurence[`cardObject`]) {
                if (i !== "id" && i !== "isEditing" && !i?.includes("list")) {
                    neWForm[`${multipleOccurence["cardName"]}_${i}`] = []
                } else if(i?.includes("list")) {
                    neWForm[`${i}`] = []
                }
                neWEdit[i] = ""
            }
            setForm({
                ...form,
                ...neWForm
            })
            setEditor({
                ...editor,
                ...neWEdit
            })
        }
    }, [multipleOccurence])
    
    const getFiles = async (url, count) => {
        const res = await axios({
            url: url,
            method: "GET",
            responseType: `blob`
        })
        return res?.data
        
    }

    const updateMore = (cardObj) => {
        let errorObj= {...form[`errors`]} ;
        errorObj[cardObj?.name] = ""; 
        if (validateMoreCall(cardObj)) {
            return
        }
        if (cardObj?.data_model?.hasOwnProperty("api")) {
            final = cardObj?.data_model.attributes?.reduce((obj, item) => {
                obj[item.name] = item?.data_type === "editor" ? editor[item.name] : item?.data_type === "dropdown" ? form[`${cardObj?.name}_${item.name}`] : item?.data_type === "file" && form[`${cardObj?.name}_deck_attached`][0] === "Yes" ? file : form[item.name]
                if (item?.data_type === "dropdown") {
                    obj[`${cardObj?.name}_${item.name}`] = []
                }
                return obj
            }, {})
            let editedFileObject = [];
            setApiModal({
                ...apiModal,
                show: true,
                isLoading: true,
                hasError: false,
                errorMessage: "",
                isBtnDisable: true
            })
            if (form[`${cardObj?.name}_deck_file_urls`]?.length) {
                form[`${cardObj?.name}_deck_file_urls`]?.forEach((i, ind) => {
                   const  filename = i.split("/").pop();
                    getFiles(`${i}`, ind + 1).then(res => {
                        let editedObject = {
                            file: res,
                            filename: filename
                        }
                        editedFileObject?.push(editedObject)
                        let urlArr = cardObj?.data_model?.api?.endpoint?.split("v1")
                        let url = getAddress() + urlArr[1]
                        setApiModal({
                            ...apiModal,
                            show: true,
                            modalObject: final,
                            editedFile: editedFileObject,
                            api: url,
                            cardName: cardObj?.name,
                            isLoading: false,
                            isBtnDisable: false
                        })
                    }).catch(err => {
                        setApiModal({
                            ...apiModal,
                            show: true,
                            hasError: true,
                            errorMessage: "Error fetching file. Please try again later!",
                            isLoading: false,
                            isBtnDisable: false
                        })
                    })
                })
            } else {
                let urlArr = cardObj?.data_model?.api?.endpoint?.split("v1")
                        let url = getAddress() + urlArr[1]
                        setApiModal({
                            ...apiModal,
                            show: true,
                            modalObject: final,
                            editedFile: editedFileObject,
                            api: url,
                            cardName: cardObj?.name,
                            hasError: false,
                            errorMessage: "",
                            isLoading: false,
                            isBtnDisable: false
                        })
            } 
        }
    }
    useEffect(() => {
        if(moreCallData?.length) {
            let neWForm = { ...form }
            let neWEdit = { ...editor }
            for (var i in final) {
                if(i === "company_call_summaries_deck_attached") {
                    neWForm[`company_call_summaries_deck_attached`] = ["No"]
                } else if(i !== "company_call_summaries_deck_attached") {
                    neWForm[i] = Array.isArray(final[i]) ? [] : ""
                }
                neWEdit[i] = ""
            }
            setTimeout(()=>{
                setFile(null)
                setForm({
                    ...form,
                    ...neWForm
                })
                setEditor({
                    ...editor,
                    ...neWEdit
                })
            },200)
        }
    }, [moreCallData])

    

    const handleMoreInputChange = (val,item, name, idx) => {
        let newObj = {...form}
        newObj[name][idx][item] = val === "," ? "" : val;
        setForm({
            ...newObj
        })
    }

    const addMoreList = (e,item, name, idx) => {
        if (e.key == "Enter" && e.target.value) {
            let newObj = {...form}
            newObj[name][idx][`${item}_list`] = [...newObj[name][idx][`${item}_list`], e.target.value]
            newObj[name][idx][item]= ""
            setForm({
                ...newObj
            })
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const removeMoreFromList = (e,item, name, index, itm, listIndex) => {
        if(!itm?.isEditing ) {
           return
        }
        let newForm = { ...form }
        let newlistArr = newForm[name][index][`${item}_list`].filter((ele, idx) => {
            return idx != listIndex
        })
        newForm[name][index][`${item}_list`] = [...newlistArr]
        setForm({
            ...newForm
        })
    }

    const handleMoreTypeaheadChanges = (val,item, name, idx) => {
        let newObj = {...form}
        newObj[name][idx][item] = val;
        newObj[item] = [];
        setForm({
            ...newObj
        })
    }
    const handleMoreDropdownChange = (val,item, name, idx) => {
        let newObj = {...form}
        newObj[name][idx][item] = val;
        setForm({
            ...newObj
        })
    }

    const HandleMoreCardEdit = (id, name) => {
      
        let filtered = moreCallData?.filter(itm => {
            return itm?.uuid === id
        })
        let newForm = {...form};
        let newEditor= {...editor};
        for(let key in filtered[0]){
            if(key === "domain"){
                newForm[`${name}_${key}`] = [{
                    display_name : filtered[0][`company_name`],
                    server_value:filtered[0]['domain']
                }]
            } else if (key !== "uuid") {
                if(key === "content") {
                    newEditor[key] = filtered[0][key]
                }else if(key === "deck_attached") {
                    newForm[`${name}_${key}`]  = [filtered[0][key]]
                } else if(key === "deck_file_urls") {
                    newForm[`${name}_${key}`]  = filtered[0][key]
                } else if(key === "call_date")  {
                    newForm[key] = moment(filtered[0][key])
                }
            }
        }
        setForm({
            ...newForm
        })
        setEditor({
            ...newEditor
        })
        isCallSummariesEditing = true;
        currentUuid = id
    }

    const HandleMoreCardDelete = (id) => {
        setDeleteConfirmation({
            ...deleteConfirmation,
            show: true,
            selectedid :id,
        })
    }

    const handleDeleteProceed = () => {
        setDeleteConfirmation({
            ...deleteConfirmation,
            show: true,
            isLoading: true,
            isBtnDisable: true
        })
        let url = getAddress() + "/file-metrics/company-call-summaries"
        dispatch(deleteMoreFn(url, deleteConfirmation?.selectedid)).then(res=> {
            if(res.status === "success"){
                setDeleteConfirmation({
                    ...deleteConfirmation,
                    show: false,
                    isLoading: false,
                    isBtnDisable: false
                })
            }
        })
    }

    const HandleCardEdit = (name, idx) => {
        let finalObj = {...form}
        finalObj[name][idx][`isEditing`] = true;
        setForm({
            ...finalObj
        })
    }

    const HandleCardSave = (name, idx) => {
        const {isError, errorObj} = saveValidation(idx,name)
        if (isError) {
            return
        }
        let finalObj = {...form}
        finalObj[name][idx][`isEditing`] = false;
        setForm({
            ...finalObj,
            [`errors`]: errorObj,
        })
    }
    const saveValidation = (idx,name) => {
        let isError = false, errorObj = { ...form[`errors`]};
        let newObject = {...form}
        let first_Column = newObject[name][idx][`markets_list`]?.length
        let second_Column = newObject[name][idx][`companies`]?.length
        let third_Column = newObject[name][idx][`principal`]?.length

        if (name === "active_market_dives") {
            if (first_Column === 0) {
                errorObj[`markets_list_${idx}`] = "Please add markets and save"
                isError = true
            } else if (first_Column > 0) {
                errorObj[`markets_list_${idx}`] = ""
            }
        }
        if (name === "companies_actively_working") {
            if (second_Column === 0) {
                errorObj[`companies_${idx}`] = "Please enter company and save"
                isError = true
            } else if (second_Column > 0) {
                errorObj[`companies_${idx}`] = ""
            }
        }
        
        if(third_Column === 0){
            errorObj[`${name}_principal_${idx}`] = "Please enter principal and save"
            isError = true
        }else if(third_Column > 0  ) {
            errorObj[`${name}_principal_${idx}`] = ""
        }
        setForm({
            ...form,
            [`errors`]: errorObj,
        })
   
      return {isError: isError, errorObj: errorObj}
    }

    const HandleCardDelete = (id, name, idx) => {
        let finalObj = {...form}, errorObj = { ...form[`errors`]};
        errorObj[`markets_list_${idx}`] = ""
        errorObj[`companies_${idx}`] = ""
        errorObj[`${name}_principal_${idx}`] = ""
        finalObj = finalObj[name]?.filter((item, index) => {
            return index !== idx
        })
        
        setForm({
            ...form,
            [name]: [...finalObj],
            [`errors`]:errorObj
        })
    }

    const handleKeyDown = (e) => {
        if(e.key ==='.' || e.key ==="+" || e.key ==="-" ){
            e.preventDefault()
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", keyBoardEvent)
        return () => {
            window.removeEventListener("keydown", keyBoardEvent);
        };
    })
    const keyBoardEvent = (event) => {
        if(((event.key ==="Enter" || event.code==="Enter") && !event.shiftKey && !focusedEditor) && data?.template !== "Associate Weekly Metrics Graph"){
            if(confirmDialog) {
                handleProceed(event)
            }else if(!confirmDialog) {
                handleSubmit(event, "submit")
            }
        }
    }

    const handleSubmit = (event, type) => {
        event.preventDefault();
        event.stopPropagation();
        if(type == 'submit') amplitude.logEvent(`${data.template} Submitted`)
        if (isValidate(data?.template, type)) {
            return
        }
        setDraftModal({
            ...draftModal,
            selectedBtn: type
        })
        setConfirmDialog(true)
    }
    const handleCancel = () =>{
        setConfirmDialog(false);
        resetData()
        dispatch(resetMoreData())
    }

    const handleProceed = () => {
        setConfirmDialog(false);
        let urlArr = matricTemplate?.api ? matricTemplate?.api?.endpoint?.split("v1") : matricTemplate?.request_schema?.api ? matricTemplate?.request_schema?.api?.endpoint?.split("v1") : null
        let url = urlArr ? getAddress() + urlArr[1] : null
        let submissionBody = {};
        if (data?.template === "Associate Weekly Metrics") {
            submissionBody = matricTemplate?.request_schema?.sections?.reduce((obj, cards) => {
                if (cards?.occurrence === "multiple") { 
                    if(cards?.data_model?.hasOwnProperty("api")){
                            if(moreCallData?.length) {
                                obj[cards?.name] = [...moreCallData] 
                            }
                    } else {
                        if(form[cards.name]?.length) {
                            obj[cards?.name] = form[cards.name]?.reduce((itemObj, cardItem) => {
                                let newOb = {};
                               for(let keys in cardItem)  {
                                    if(keys !== `id` && keys !== `isEditing`) {
                                        if(keys === "principal") {
                                           let prin = cardItem[keys]?.map(i=>{
                                                return i.server_value
                                            })
                                            newOb[keys] =  prin?.toString()
                                        }
                                        if( keys === "companies") {
                                            let comp = cardItem[keys]?.map(i=>{
                                                 return i.server_value
                                             })
                                             newOb[keys] =  comp
                                         }
                                         if( keys === "markets") {
                                             newOb[keys] = cardItem[`${keys}_list`]
                                         }
                                    }
                               }
                               itemObj.push(newOb);
                            return itemObj
                            }, []) 
                        }
                        
                    }
                } else {
                    obj[cards?.name] = cards?.data_model?.attributes?.reduce((itemObj, cardItem) => {
                        itemObj[cardItem.name] = form[cardItem.name] ? form[cardItem.name] : 0
                        return itemObj
                    }, {})
                }
                return obj
            }, {})
            submissionBody[`from_date`] = moment(dateFields[`from`]).format();
            submissionBody[`to_date`] = moment(dateFields[`to`]).format();
            if(draftModal?.draftId) {
                submissionBody[`draft_id`] = draftModal?.draftId;
            }
            submissionBody[`save`] = draftModal?.selectedBtn === "saveDraft" ? true : false;
        } else {
            submissionBody = matricTemplate?.request_schema?.sections?.reduce((obj, cards) => {
                let cardObj = cards?.data_model?.attributes?.reduce((itemObj, cardItem) => {
                    if (cardItem?.data_type === "dropdown") {
                        let items = ""
                        form[`${cards?.name}_${cardItem.name}`]?.forEach(function (item) {
                            if (typeof item == "string") {
                                items = item;
                            } else {
                                if (item['server_value'].includes("https://")) {
                                    items = item['display_name']
                                } else {
                                    items = item['server_value'] || item['domain'] || "";
                                }
                            }
                        })
                        itemObj[cardItem.name] = items
                    } else if (cardItem?.data_type === "list") {
                        itemObj[cardItem.name] = form[`${cardItem.name}_list`]
                    }else if (cardItem?.data_type === "editor") {
                        itemObj[cardItem.name] = editor[`${cardItem.name}`]
                    } else {
                        if(form[cardItem.name]){
                            itemObj[cardItem.name] = form[cardItem.name]
                        } 
                    }
                    return itemObj
                }, {})
                return cardObj
            }, {})
        }
        urlArr && setData({
            ...data,
            loading: true,
        }) 
        urlArr && dispatch(submitMatrics(url, submissionBody)).then(res => {
            if (res.status === "success") {
                setData({
                    ...data,
                    isError: false,
                    errorMessage: "",
                    loading: false,
                    message: draftModal?.selectedBtn === "saveDraft" ? "Your draft is saved successfully!" : "Your data is submitted successfully!",
                    openModal: true,
                    isSuccess: true,
                })
                resetData()
                dispatch(resetMoreData())
                if(draftModal?.selectedBtn === "saveDraft") {
                    setTimeout(()=> {
                        dispatch(getAllDraft(axiosDraftList))
                    },500)
                }
                if (matricTemplate?.hasOwnProperty("display")) {
                    getDisplayRefresh()
                }
            } else {
                setData({
                    ...data,
                    isError: false,
                    errorMessage: "",
                    loading: false,
                    message: "Error Saving Data",
                    openModal: true,
                    isSuccess: false
                })
            }
            if(draftModal?.selectedBtn === "submit" && draftModal?.draftId) {
                dispatch({
                    type: actionTypes.UPDATE_DRAFT_LIST_SLIDER, id: draftModal?.draftId
                })
            }
        })
    }

    const validateMoreCall = (cards) => {
        let isError = false, errorObj = { ...form[`errors`] }, editorErrorObj = {...editor[`errors`]}, dropError= {...form[`dropErrors`]};
        cards?.data_model?.attributes?.forEach((item) => {
            let fieldName = item.name
            let typeOfObject = typeof form[fieldName]
            let isRequired = item?.required === "false" ? false : true
            if((item.data_type === "timestamp") && isRequired) {
                if(!form[fieldName]) {
                    errorObj[fieldName] = `Please enter date`
                    isError = true
                }
            }else if((item.data_type === "list") && isRequired) {
                if(!form[`${fieldName}_list`]?.length) {
                    errorObj[`${fieldName}_list`] = `Please add ${fieldName?.toLowerCase()} and hit enter to save.`
                    isError = true
                }
            } else if (item.data_type === "file" && form[`${cards?.name}_deck_attached`][0] === "Yes" && !file && (!isCallSummariesEditing || (isCallSummariesEditing && !form[`company_call_summaries_deck_file_urls`]?.length))) {
                errorObj[fieldName] = `Please upload file`
                isError = true
            }else if(item.data_type !== "file" && item.data_type !== "timestamp" && item.data_type !== "list") {
                if (typeOfObject == "string" && (!(form[fieldName] || editor[fieldName])) && isRequired) {
                    errorObj[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                    if(!editor[fieldName])  editorErrorObj[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                    isError = true
                }
                if (typeOfObject == "object" && !(form[`${cards?.name}_${fieldName}`]?.length) && isRequired) {
                    errorObj[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                    dropError[`${cards?.name}_${fieldName}`] = `Please enter ${item.title?.toLowerCase()}`
                    isError = true
                }
            }
        })
        setForm({
            ...form,
            [`errors`]: errorObj,
            [`dropErrors`]: dropError,
        })
        setEditor({
            ...editor,
            [`errors`]: editorErrorObj
        })
        return isError
    }
    const isValidate = (selectedTemplate, selctedBtnType) => {
        
        let isError = false, errorObj = {}, editorError={...editor[`errors`]}, dropError= {...form[`dropErrors`]};
        if (selectedTemplate === "Associate Weekly Metrics Graph") {
            return isError
        }
        if (selectedTemplate === "Associate Weekly Metrics") {
            let dateError = { ...dateFields.errors };
            matricTemplate?.request_schema?.sections?.forEach(element => {
                if (element.occurrence !== "multiple") {
                    element?.data_model?.attributes?.forEach((item) => {
                        let fieldName = item.name
                        let typeOfObject = typeof form[fieldName]
                        let isRequired = item?.required === "false" ? false : true

                        if (typeOfObject == "string" && !form[fieldName] && isRequired && selctedBtnType !== "saveDraft") {
                            errorObj[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                            isError = true
                        }
                        if (typeOfObject == "object" && form[`${element?.name}_${fieldName}`].length === 0 && isRequired) {
                            errorObj[fieldName] = `Please select ${item.title?.toLowerCase()}`
                            dropError[`${element?.name}_${fieldName}`] = `Please select ${item.title?.toLowerCase()}`
                            isError = true
                        }
                        if (typeOfObject == "string" && (item.data_type == "timestamp") && !form[fieldName] && isRequired) {
                            errorObj[fieldName] = `Please enter date`
                            isError = true
                        }
                    })
                }
                if (element.occurrence === "multiple" && !element.data_model?.hasOwnProperty("api") && !form[element.name]?.length && selctedBtnType !== "saveDraft") {
                    errorObj[element.name] = `Please  add ${element.title?.toLowerCase()} and save`
                    isError = true
                }

                if (element.occurrence === "multiple" && element.data_model?.hasOwnProperty("api") && !moreCallData?.length && selctedBtnType !== "saveDraft") {
                    errorObj[element.name] = `Please  add ${element.title?.toLowerCase()} and save`
                    isError = true
                }
            });
            if(!dateFields[`from`]) {
                dateError[`from`] =  "Please enter start date"
                isError = true
                setDateFields({
                    ...dateFields,
                    errors: dateError
                })
            }
            if(!dateFields[`to`]) {
                dateError[`to`] =  "Please enter end date"
                isError = true
                setDateFields({
                    ...dateFields,
                    errors: dateError
                })
            }
            
        } else {
            matricTemplate?.request_schema?.sections?.forEach(element => {
                element?.data_model?.attributes?.forEach((item) => {
                    let fieldName = item.name
                    let typeOfObject = typeof form[fieldName]
                    let isRequired = item?.required === "false" ? false : true

                    if (data?.template === "Downstream Cookie" && typeOfObject === "string" && isRequired) {
                        if (item?.hasOwnProperty("describe") && form[`${element?.name}_${item?.describe?.dependent_on}`][0] === item?.describe?.visible_on_selected_value && !form[fieldName]) {
                            errorObj[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                            isError = true
                        } else if (!item?.hasOwnProperty("describe") && !form[fieldName]) {
                            errorObj[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                            isError = true
                        }
                    } else if (data?.template !== "Downstream Cookie" && typeOfObject === "string" && !(form[fieldName] || editor[fieldName]) && isRequired) {
                        errorObj[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                        editorError[fieldName] = `Please enter ${item.title?.toLowerCase()}`
                        isError = true
                    } else if( typeOfObject === "string" && (item?.data_type =="email") && !emailValidation(form[fieldName])){
                        errorObj[fieldName] = `Please enter a valid ${item?.title.toLowerCase()}`
                        isError = true
                    }
                    if (typeOfObject === "object" && (item.data_type == "list") && form[`list_${fieldName}`].length === 0 && isRequired) {
                        errorObj[fieldName] = `Please select ${item.title?.toLowerCase()}`
                        isError = true
                    } else if (typeOfObject === "object" && form[`${element?.name}_${fieldName}`].length === 0 && isRequired) {
                        errorObj[fieldName] = `Please select ${item.title?.toLowerCase()}`
                        dropError[`${element?.name}_${fieldName}`] = `Please select ${item.title?.toLowerCase()}`
                        isError = true
                    }
                })
            })
        }
        setForm({
            ...form,
            [`errors`]: errorObj,
            [`dropErrors`]: dropError,
        })
        setEditor({
            ...editor,
            [`errors`]: editorError
        })
        return isError
    }

    const handleEditor = (val, name, title) => {
        let isError = { ...editor[`errors`] };
        isError[name] = !val ? `Please enter ${title?.toLowerCase()}` : ""
        setEditor({
            ...editor,
            [name]: !val||val === `<p><br></p>` ? "" : val,
            [`errors`]: isError
        })
    }
    const onChangeValidate = (name, value, title) => {
        let isError = { ...form[`errors`] };
        isError[name] = !value ? `Please enter ${title?.toLowerCase()}` : ""
        if ((name === "company_name" && value) || (name === "domain" && value) || (name === "product" && value) || (name === "why_now" && value)) {
            isError[`company_call_summaries`] = ""
        }
        setForm({
            ...form,
            [name]: value,
            [`errors`]: isError
        })
    }
    const renderCard = (schema, type) => {
        return schema?.sections?.map((cards, idx) => {
            return (
                <Row className="" key={`cards-${idx}`}>
                    <Col md={12}  className="m-b-40" keys={`cards-${idx}`}>
                        {cards?.occurrence === "multiple" ? <div className="d-flex justify-content-between align-items-center">
                            <span className="main-title m-b-10">{cards?.title}</span>
                            {isCallSummariesEditing && cards?.name === "company_call_summaries" ? 
                            <Button variant="primary" onClick={() => updateMore(cards)}>Update</Button> : 
                            <Button variant="primary" onClick={() => addMore(cards)}>Add/Save</Button>
                            }
                            
                        </div> : <div className="main-title m-b-10">{cards?.title}</div>}
                        <Card>
                            <Card.Body className="p-10" style={{ paddingBottom: "10px" }}>
                                <Row className="m-0" >
                                    {cards?.data_model?.attributes && renderCardItem(cards?.data_model?.attributes, cards?.name, type)}
                                </Row>
                                {cards?.occurrence === "multiple" && form[cards?.name]?.length ? <Row className="m-0">{renderAdditionalList(cards?.data_model?.attributes, cards?.name)}</Row> : null}
                                {cards?.occurrence === "multiple" && form[`errors`]?.[cards.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[cards.name]}</span>}
                            </Card.Body>
                        </Card>
                    </Col>
                    {cards?.occurrence === "multiple" && cards?.data_model?.hasOwnProperty("api") && moreCallData?.length > 0 ? renderMoreCard(cards?.name) : null}
                </Row>
            )
        })
    }
    const renderAdditionalList = (selectedCardObject, name,type) => {
        return form[name]?.map((itm, idx) => {
            return <>
                { selectedCardObject?.map((item, indx) => {
                    let isEven = indx % 2 == 0
                    switch (item.data_type) {
                        case "string":
                            return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`string-${indx}`}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control name={item?.name} type="text" placeholder={`Enter ${item?.title?.toLowerCase()}`} value={itm[item?.name]} onChange={(e) => handleMoreInputChange(e.target.value,item?.name, name,idx)} disabled={!itm?.isEditing}/>
                                </Form.Group>
                            </Col>
                            break;
                        case "list":
                                return <>
                                    <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`list-${item?.name}-${indx}`}>
                                        <Form.Group className="mb-2" controlId="formBasicEmail" style={{ position: "relative" }}>
                                            {!itm?.isEditing ? <ul className={!itm?.isEditing ? "tag-box d-flex disable" : "tag-box d-flex "}>
                                            {itm[`${item?.name}_list`]?.length ? itm[`${item?.name}_list`]?.map((listItem, index) => {
                                                    return (
                                                        <li className={!itm?.isEditing ? "disableTag tags d-inline-flex" : "tags d-inline-flex" }key={`listitem-${index}`} disabled={!itm?.isEditing}>{listItem}<span className="removeTag" onClick={(e) => removeMoreFromList(e.target.value, item?.name, name,idx, itm, index)}  disabled={!itm?.isEditing}>x</span></li>
                                                    )
                                                }) : null}
                                            </ul> : <ul className="tag-box d-flex mb-0">
                                                <Form.Control name={item?.name} type="text"  value={itm[`${item?.name}`] } onChange={(e) => handleMoreInputChange(e.target.value,item?.name, name,idx)} onKeyDown={(e) => addMoreList(e, item?.name, name,idx)} disabled={!itm?.isEditing} placeholder="Add markets"/>
                                                {itm[`${item?.name}_list`]?.length ? itm[`${item?.name}_list`]?.map((listItem, index) => {
                                                    return (
                                                        <li className="tags d-inline-flex" key={`listitem-${index}`} disabled={!itm?.isEditing}>{listItem}<span className="removeTag" onClick={(e) => removeMoreFromList(e.target.value, item?.name, name, idx, itm, index)}  disabled={!itm?.isEditing}>x</span></li>
                                                    )
                                                }) : null}
                                            </ul>}
                                            {form[`errors`] && form[`errors`]?.[`markets_list_${idx}`] && <span className="warningText card-body-paragraph">{form[`errors`]?.[`markets_list_${idx}`]}</span>} 
                                        </Form.Group>
                                    </Col>
                                </>
                                break;
                        case "dropdown":
                            if (item?.describe?.typeahead === "true") {
                                return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`dropdown-${indx}`}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <AsyncTypeahead
                                            bodyContainer={"#root"}
                                            id="dropdown"
                                            isLoading={itm[`${name}_${item?.name}_isLoading`] || false}
                                            labelKey={"display_name"}
                                            minLength={3}
                                            onChange={(val) => handleMoreTypeaheadChanges(val,item?.name, name,idx)}
                                            onSearch={(q) => {
                                                handleSearch(q, item, name, "edit", idx, itm[item?.name])
                                            }
                                            }
                                            options={form[item?.name] || []}
                                            placeholder={`Search ${item?.title?.toLowerCase()}`}
                                            selected={itm[item?.name]}
                                            renderMenuItemChildren={(option) => {
                                                return <div>
                                                    <div className="main-paragraph">{option?.display_name}</div>
                                                    <div className="lightGrey" style={{fontStyle:"italic"}}>{option?.server_value}</div>
                                                </div>
                                            }}
                                            useCache={false}
                                            multiple={item?.describe?.choose_one === "false"}
                                            disabled={!itm?.isEditing}
                                            filterBy={customFilterBy}
                                            clearButton={item?.describe?.choose_one === "true"}
                                            onKeyDown={handleDropdownKeyDown}
                                        />
                                        {form[`errors`] && form[`errors`]?.[`companies_${idx}`]  && <span className="warningText card-body-paragraph">{form[`errors`]?.[`companies_${idx}`]}</span>} 
                                    </Form.Group>
                                </Col>
                            } else {
                                return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`dropdown-${indx}`}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Typeahead
                                            labelKey="display_name"
                                            id="dropdown"
                                            name={item?.name}
                                            onChange={(val) => handleMoreDropdownChange(val, item?.name,name, idx)}
                                            options={selected[`${name}_${item?.name}`] || []}
                                            placeholder={`Select ${item?.title?.toLowerCase()}`}
                                            selected={itm[item?.name]}
                                            useCache={false}
                                            multiple={item?.describe?.choose_one === "false"}
                                            disabled={!itm?.isEditing}
                                            filterBy={customFilterBy}
                                            onKeyDown={handleDropdownKeyDown}
                                        />
                                         {form[`errors`] && form[`errors`]?.[`${name}_principal_${idx}`]  && <span className="warningText card-body-paragraph">{form[`errors`]?.[`${name}_principal_${idx}`]}</span>} 
                                    </Form.Group>
                                </Col>
                            }
                            break;
                    }
                })}
                <Col xs={1} className="d-flex justify-content-end mb-3 mt-1 icons p-0">
                    {!itm?.isEditing && (<span className="mr-1 cursor-pointer iconbox" style={{ marginRight: "4px" }} onClick={() => HandleCardEdit(name, idx)}><EditSvg /></span>)}
                    {itm?.isEditing && (<span className="mr-1 cursor-pointer iconbox" style={{ marginRight: "4px",  }} onClick={() => HandleCardSave(name, idx,itm)}><SaveSvg /></span>)}
                    <span className="ml-1 cursor-pointer iconbox" style={{ marginLeft: "4px" }} onClick={() => HandleCardDelete(itm.id, name, idx)}><DeleteSvg /></span>
                </Col>
            </>
        })
    }
    const renderMoreCard = (name) => {
        return (
            <Col md={12} className="m-t-40 mb-2">
                <Card style={{ maxHeight: "400px", overflowY: "scroll" }}>
                    <Card.Body className="p-10">
                        {moreCallData?.length > 0 ?
                            moreCallData?.map((cardItems) => {
                                return <>
                                    <Col xs={12}>
                                        <div className="d-flex justify-content-between ">
                                            {cardItems?.company_name ? <span className="main-title lightGrey">{cardItems?.company_name}</span> : <span className="main-title lightGrey">-</span>}
                                            <span className="d-flex">
                                                <span className="mr-1 cursor-pointer iconbox" style={{ marginRight: "4px" }} onClick={() => HandleMoreCardEdit(cardItems.uuid, name)}><EditSvg /></span>
                                                <span className="ml-1 cursor-pointer iconbox" style={{ marginLeft: "4px" }} onClick={() => HandleMoreCardDelete(cardItems.uuid)}><DeleteSvg /></span>
                                            </span>
                                        </div>
                                        <hr/>
                                    </Col>
                                    {(Object.keys(cardItems)?.map((itemKey, idx) => {
                                        return <div className="m-0" key={`cardItems-${idx}`}>
                                            {itemKey === "domain" ?<div className="mb-3"> <a className="primaryGrey " href={`https://${cardItems[`domain`]}`} target="_blank" >{cardItems[`domain`]}</a> </div>: null}
                                            {itemKey !== "domain" && itemKey !== "uuid" && itemKey !== "company_name" && itemKey !== "call_date" && itemKey !== "id" && typeof cardItems[itemKey] === "string" ?<>
                                                <div className="primaryGrey mb-2">{removeUnderScoreAndCapitalize(itemKey)}</div>
                                                {/* <div className="lightGrey mb-2">{itemKey=== "content" && cardItems[itemKey] ? cardItems[itemKey]?.replace(/\n|<.*?>/g,'')  : cardItems[itemKey] ? cardItems[itemKey] : "-"}</div> */}
                                                <div className=" mb-2">{itemKey=== "content" && cardItems[itemKey] ? <span dangerouslySetInnerHTML={{ __html: cardItems[itemKey] }}></span> : cardItems[itemKey] ? cardItems[itemKey] : "-"}</div>
                                            </>: itemKey !== "domain" && itemKey !== "uuid" && itemKey !== "company_name" && itemKey !== "call_date" && itemKey !== "id" && Array.isArray(cardItems[itemKey]) && cardItems[itemKey]?.length ? <>
                                                <div className="primaryGrey mb-2">{removeUnderScoreAndCapitalize(itemKey)}</div>
                                                <div className="lightGrey mb-2">{ cardItems[itemKey].map((i, idx)=> {return (<span>{idx > 0 ? `,` : null} {i} </span> )})}</div>
                                            </>  : null}
                                            {itemKey === "call_date" ? <>
                                                <div className="primaryGrey mb-2">Date:</div>
                                                <div className="lightGrey mb-2">{cardItems[`call_date`] ? moment(cardItems[`call_date`]).format("MMM DD YYYY") : "-"}</div>
                                            </> : null}
                                            
                                        </div>
                                    }))}
                                    <hr/>
                                    <div className="mb-2"></div>
                                </>
                            }) : null}

                    </Card.Body>
                </Card>
            </Col>
        )
    }
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
        // Prevent the page/container scrolling
        e.stopPropagation()
        // Refocus immediately, on the next tick (after the current function is done)
          setTimeout(() => {
            e.target.focus()
        }, 0)
    }
    const customFilterBy = () => true;

    const handleDropdownKeyDown = (event) => {
        const key = event.key;
        // Perform your logic based on the key pressed
        if (key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
          // Handle Enter key press, e.g., submit form, select highlighted option, etc.
        }
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedHTML = clipboardData.getData('text/html');
        const range = e.target.editor.getSelection();
        if (range) {
            const delta = e.target.editor.clipboard.convert(pastedHTML);
            e.target.editor.updateContents(delta);
        }
    };
      
    const renderCardItem = (arr, name, type) => {
        return arr?.map((item, indx) => {
            let isEven = indx % 2 == 0
            switch (item.data_type) {
                case "int":
                    return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`int-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{item?.title}</Form.Label>
                            <Form.Control name={item?.name} type="number" placeholder={`Enter ${item?.title?.toLowerCase()}`} value={type === "display" ? display[`${item?.name}`]: form[`${item?.name}`] } onChange={(e)=>handleInputChange(e, item?.title)} min={0} onKeyPress={(e) => handleKeyDown(e)}
                            onWheel={numberInputOnWheelPreventChange}
                            />
                            { type === "form" && form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name] }</span>}
                        </Form.Group>
                    </Col>
                    break;
                case "string":
                    if (data?.template === "Downstream Cookie") {
                        if (item?.hasOwnProperty("describe") && form[`${name}_${item?.describe?.dependent_on}`]?.[0] === item?.describe?.visible_on_selected_value) {
                            return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`string-${indx}`}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>{item?.title}</Form.Label>
                                    <Form.Control name={item?.name} type="text" placeholder={`Enter ${item?.title?.toLowerCase()}`}  value={type === "display" ? display[`${item?.name}`]: form[`${item?.name}`] } onChange={(e) => handleInputChange(e, item?.title)} />
                                    { type === "form" && form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                                </Form.Group>
                            </Col>
                            break;
                        } else if (!item?.hasOwnProperty("describe")) {
                            return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`string-${indx}`}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>{item?.title}</Form.Label>
                                    <Form.Control name={item?.name} type="text" placeholder={`Enter ${item?.title?.toLowerCase()}`}  value={type === "display" ? display[`${item?.name}`]: form[`${item?.name}`] } onChange={(e) => handleInputChange(e, item?.title)} />
                                    {type === "form" && form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                                </Form.Group>
                            </Col>
                            break;
                        } else {
                            return null
                            break;
                        }
                    } else if (data?.template !== "Downstream Cookie") {
                        return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`string-${indx}`}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{item?.title}</Form.Label>
                                <Form.Control name={item?.name} type="text" placeholder={`Enter ${item?.title?.toLowerCase()}`}  value={type === "display" ? display[`${item?.name}`]: form[`${item?.name}`] } onChange={(e) => handleInputChange(e, item?.title)} />
                                {type === "form" && form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                            </Form.Group>
                        </Col>
                        break;
                    }
                case "dropdown":
                    if (item?.describe?.typeahead === "true") {
                        return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`dropdown-${indx}`}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{item?.title}</Form.Label>
                                <AsyncTypeahead
                                    bodyContainer={"#root"}
                                    id="dropdown"
                                    isLoading={form[`${name}_${item?.name}_isLoading`] || false}
                                    labelKey={"display_name"}
                                    minLength={3}
                                    onChange={(val) => handleTypeaheadChanges(val, item, type, name)}
                                    onSearch={(q) => {
                                        handleSearch(q, item, name, "create", null, form[`${name}_${item?.name}`])
                                    }
                                    }
                                    options={form[item?.name]|| []}
                                    placeholder={`Search ${item?.title?.toLowerCase()}`}
                                    selected={type === "display" ? display[`${name}_${item?.name}`] : form[`${name}_${item?.name}`]}
                                    renderMenuItemChildren={(option) => {
                                        return <div>
                                            <div className="main-paragraph">{option.display_name}</div>
                                            <div className="lightGrey" style={{fontStyle:"italic"}} >{option.server_value}</div>
                                        </div>
                                    }}
                                    useCache={false}
                                    multiple={item?.describe?.choose_one === "false"}
                                    filterBy={customFilterBy}
                                    clearButton={item?.describe?.choose_one === "true"}
                                    onKeyDown={handleDropdownKeyDown}
                                />
                                {type === "form" && form[`dropErrors`] && form[`dropErrors`]?.[`${name}_${item?.name}`] && <span className="warningText card-body-paragraph">{form[`dropErrors`]?.[`${name}_${item?.name}`]}</span>}
                            </Form.Group>
                        </Col>
                    } else {
                        return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`dropdown-${indx}`}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{item?.title}</Form.Label>
                                <Typeahead
                                    labelKey="display_name"
                                    id="dropdown"
                                    name={item?.name}
                                    onChange={(val) => handleDropdownChange(val, item?.name, item?.title, type, name)}
                                    options={selected[`${name}_${item?.name}`] || []}
                                    placeholder={`Select ${item?.title?.toLowerCase()}`}
                                    selected={type === "display" ? display[`${name}_${item?.name}`] : form[`${name}_${item?.name}`]}
                                    useCache={false}
                                    multiple={item?.describe?.choose_one === "false"}
                                    filterBy={customFilterBy}
                                    onKeyDown={handleDropdownKeyDown}
                                />
                                {type === "form" && form[`dropErrors`]?.[`${name}_${item?.name}`] && <span className="warningText card-body-paragraph">{form[`dropErrors`]?.[`${name}_${item?.name}`]}</span>}
                            </Form.Group>
                        </Col>
                    }
                    break;
                case "timestamp":
                    return <Col xs={12} md={isEven ? 5 : { span: 5,offset: 1 }} key={`timestamp-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ position: "relative" }}>
                            <Form.Label>{item?.title}</Form.Label>
                            {/* <DatePicker name={item?.name} className="w-100" dateFormat="MMM dd yyyy" placeholderText={'MMM DD YYYY'} selected={form[item?.name]} onChange={date => handleDateInputChange(date, item?.name, item?.title)} maxDate={new Date()} />&nbsp;<CalendarSvg className="iconClass" /> */}
                            <CustomDate selectedDate={form[item?.name]} handleDateChange={date => handleDateInputChange(date, item?.name, item?.title)} />
                            {form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                        </Form.Group>
                    </Col>
                    break;
                case "editor":
                    return <Col xs={12} md={11} key={`editor-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ position: "relative" }}>
                            <Form.Label>{item?.title}</Form.Label>
                            <ReactQuill  theme="snow" value={editor[item?.name]} onChange={(val) => handleEditor(val, item?.name, item?.title)} modules={modules} className="textEditor"
                                formats={formats}
                                placeholder={`Add your content ...`}
                                onFocus={()=>setFocusedEditor(true)} onBlur={()=>setFocusedEditor(false)}
                                onPaste={handlePaste}
                                preserveWhitespace={true}
                            />
                            {type === "form" && editor[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{editor[`errors`]?.[item?.name]}</span>}
                        </Form.Group>
                    </Col>
                    break;
                case "url":
                    return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`url-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{item?.title}</Form.Label>
                            <Form.Control name={item?.name} type="url" placeholder={`Enter ${item?.title?.toLowerCase()}`} value={type === "display" ? display[`${item?.name}`]: form[`${item?.name}`]} onChange={(e) => handleInputChange(e, item?.title)} />
                            {type === "form" && form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                        </Form.Group>
                    </Col>
                    break;
                case "email":
                    return <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`email-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{item?.title}</Form.Label>
                            <Form.Control name={item?.name} type="email" placeholder={`Enter ${item?.title?.toLowerCase()}`} value={type === "display" ? display[`${item?.name}`]: form[`${item?.name}`]} onChange={(e) => handleInputChange(e, item?.title)} />
                            {type === "form" && form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                        </Form.Group>
                    </Col>
                    break;
                case "datetime":
                    let title = name?.split("_").shift() + "_" + item?.name;
                    let cardName = name?.split("_").shift();
                    if (item?.name == "end_time") {
                        return <Col xs={12} md={6} key={`datetime-${indx}`}>
                            <Form.Group className="mb-3" controlId="formBasicEmail" style={{ position: "relative" }}>
                                <Form.Label>Select Period</Form.Label>
                                {/* <DatePicker name={title} className="w-100" dateFormat="MMM dd yyyy" placeholderText={"Select date-range"} selectsRange startDate={form[`${cardName}_start_time`]} endDate={form[`${cardName}_end_time`]} onChange={(updates) => handleGraphDateInputChange(updates, title, item?.title, cardName)} maxDate={new Date()} />&nbsp;<CalendarSvg className="iconClass" /> */}
                                <CustomRangePicker handleDateRangeChange={(updates) => handleGraphDateInputChange(updates, title, item?.title, cardName)} selectedDate={form[`${cardName}_dateRange`]}/>
                                {form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                            </Form.Group>
                        </Col>
                        break;
                    } else {
                        return null;
                        break;
                    }
                case "graph":
                    return <Col xs={12} md={11} key={`graph-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ position: "relative" }}>
                            <Form.Label>{item?.title}</Form.Label>
                            {renderGraph(name)}
                        </Form.Group>
                    </Col>
                    break;
                case "list":
                    return <>
                        <Col xs={12} md={isEven ? 5 : { span: 5, offset: 1 }} key={`list-${item?.name}-${indx}`}>
                            <Form.Group className="mb-2" controlId="formBasicEmail" style={{ position: "relative" }}>
                                <Form.Label>{item?.title}</Form.Label>
                                <ul className="tag-box d-flex m-0">
                                    <Form.Control name={item?.name} type="text" placeholder={!form[`${item?.name}_list`]?.length ? `Add ${item?.title?.toLowerCase()}` : ""}  value={type === "display" ? display[`${item?.name}`]: form[`${item?.name}`] } onChange={(e) => handleAddListInputChange(e, item?.title)} onKeyDown={(e) => addList(e, form[item?.name], item?.name)} />
                                    {form[`${item?.name}_list`]?.length ? form[`${item?.name}_list`]?.map((listItem, indx) => {
                                        return (
                                            <li className="tags d-inline-flex" key={`listitem-${indx}`}>{listItem}<span className="removeTag" onClick={() => removeFromList(item?.name, indx)}>x</span></li>
                                        )
                                    }) : null}
                                </ul>
                                {form[`errors`] && form[`errors`]?.[`${item?.name}_list`] && <span className="warningText card-body-paragraph">{form[`errors`]?.[`${item?.name}_list`]}</span>}
                            </Form.Group>
                        </Col>
                    </>
                    break;
                case "table":
                    return <Col xs={11} md={11} key={`graph-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ position: "relative" }}>
                            <Form.Label>{item?.title}</Form.Label>
                            {renderTable(item)}
                        </Form.Group>
                    </Col>
                    break;
                case "file":
                    if(form[`${name}_deck_attached`]?.[0] === "Yes") {
                        return <Col xs={11} md={11} key={`file-${indx}`}>
                        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ position: "relative" }}>
                            <Form.Label>{item?.title}</Form.Label>
                            {renderFile(item, name)}
                            {!file && (!isCallSummariesEditing || (isCallSummariesEditing && !form[`company_call_summaries_deck_file_urls`]?.length)) && form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name]}</span>}
                        </Form.Group>
                    </Col>
                    } else {
                       return null
                    }
                    
                    break;
            }
        })
    } 


    const browseFiles = () => {
        // Note that the ref is set async,
        // so it might be null at some point 
        if (dropZoneRefs.current) {
            dropZoneRefs.current.open()
        }
      };

    const deleteDoc = (e,key) => {
        e.preventDefault();
        let updatedObj = {...file}
        delete updatedObj[key]
        if(Object.keys(updatedObj).length) {
            setFile(updatedObj)
        } else {
            setFile(null)
        }
      };

      const deleteDocInedit = (e,key, name) => {
        e.preventDefault()
        let newform ={...form}
        let filtered =newform[`${name}_deck_file_urls`]?.filter(i => {
            return i !==key
        })
        setForm({
            ...form,
            [`${name}_deck_file_urls`]: filtered
        })
      };

      const openFile = (url) => {
        window.open(url);
      }
    const renderFile = (item, name) => {
        return (
            <>
                {!file ? (
                    <Dropzone ref={dropZoneRefs} noClick noKeyboard multiple={item?.describe?.multiple === "true"} onDrop={(e)=>handleDrop(e)}>
                    {({getRootProps, getInputProps,}) => {
                      return (
                        <div
                        style={{
                            textAlign: "center",
                            alignItems: "center",
                        }}
                        className="drop-zone"
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <div style={{ marginRight: "8px" }}>
                            <svg
                                width="39"
                                height="54"
                                viewBox="0 0 39 54"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"

                            >
                                <path
                                    d="M22.75 14.3438V0H2.4375C1.08672 0 0 1.12852 0 2.53125V51.4688C0 52.8715 1.08672 54 2.4375 54H36.5625C37.9133 54 39 52.8715 39 51.4688V16.875H25.1875C23.8469 16.875 22.75 15.7359 22.75 14.3438ZM29.3698 37.1261H22.75V45.5636C22.75 46.4959 22.0228 47.2511 21.125 47.2511H17.875C16.9772 47.2511 16.25 46.4959 16.25 45.5636V37.1261H9.63016C8.17984 37.1261 7.4557 35.3025 8.48555 34.2404L18.2782 24.1471C18.9536 23.4499 20.0444 23.4499 20.7198 24.1471L30.5124 34.2404C31.5433 35.3025 30.8202 37.1261 29.3698 37.1261ZM38.2891 11.0742L28.3461 0.738281C27.8891 0.263672 27.2695 0 26.6195 0H26V13.5H39V12.8566C39 12.1922 38.7461 11.5488 38.2891 11.0742Z"
                                    fill="#111"
                                />
                            </svg>
                        </div>

                        <div className=" text-center subTitle">
                            Drag & drop your documents here
                            <h4>OR</h4>
                        </div>

                        <div class="text-center">
                            <Button variant="secondary" onClick={browseFiles}>
                                Browse Files
                            </Button>
                        </div>
                    </div>
                      );
                    }}
                  </Dropzone>
                ) : (
                    <div className="" >
                            {Object.keys(file).map(function (key, idx) {
                                return <div >
                                    <Card
                                className="p-3"
                                style={{
                                  padding: "5px",
                                  height: "60px",
                                  marginTop: "8px",
                                  marginBottom: "6px"
                                }}
                              >
                                <div className="d-flex justify-content-between">
                                  <div className="singleLineTxt">
                                    <DocumentSvg />
                                    <span style={{marginLeft: "8px"}}>{file?.[key]?.name}</span>
                                  </div>
                                  <div className="">
                                    <button
                                      onClick={(e)=>deleteDoc(e, key)}
                                      className="iconbox"
                                      style={{
                                        border: "none",
                                        border: "1px solid grey",
                                        borderRadius: "5px",
                                        width:"40px",
                                        height:"35px"
                                      }}
                                    >
                                      <DeleteSvg />
                                    </button>
                                  </div>
                                </div>
                              </Card>
                              </div>
                            })}
                    </div>
                )}
                {isCallSummariesEditing && form[`${name}_deck_file_urls`]?.length ? form[`${name}_deck_file_urls`]?.map(i => {
                    let finaname = i?.split("/").pop()
                    return (
                        <div className="mt1 mb1" >
                            <Card
                                className="p-3"
                                style={{
                                    padding: "5px",
                                    height: "60px",
                                    marginTop: "8px",
                                    marginBottom: "6px"
                                }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div className="singleLineTxt">
                                        <DocumentSvg onClick={()=>openFile(i)}/>
                                        <span style={{marginLeft: "4px"}}>{finaname}</span>
                                    </div>
                                    <div className="">
                                        <button
                                            className="iconbox"
                                            onClick={(e)=>deleteDocInedit(e, i, name)}
                                            style={{
                                                border: "none",
                                                border: "1px solid grey",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <DeleteSvg />
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                }): null}
            </>
        )
    }
    const renderApiModal = () => {
        return (
            <Modal
                show={apiModal?.show}
                onHide={()=>setApiModal({...apiModal, show: false})}
                dialogClassName="custom-modal"
                aria-labelledby=""
                size="lg"
            >
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title className="main-title">
                        Preview
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    {apiModal?.isLoading ?<Loader scale={30} message={"Loading ..."} customStyle={"child"} />: apiModal?.hasError ? <div className="text-center warningText">{apiModal?.errorMessage}</div>  : Object.keys(apiModal?.modalObject)?.map((itemKey, idx)=>{
                         return <div className="m-0" key={`cardItems-${idx}`}>
                         {itemKey === "domain" ?<div className=""> <div className="primaryGrey mb-2">Company Name</div> <div className="lightGrey mb-2">{apiModal?.modalObject[`domain`][0]?.display_name}</div> </div>: null}
                         { itemKey !== "domain" && itemKey !== "call_date" && itemKey !== "deck_files" && typeof apiModal?.modalObject[itemKey] === "string" ?<>
                             <div className="primaryGrey mb-2">{removeUnderScoreAndCapitalize(itemKey)}</div>
                             {/* <div className="lightGrey mb-2">{itemKey=== "content" && apiModal?.modalObject[itemKey] ? apiModal?.modalObject[itemKey]:apiModal?.modalObject[itemKey] ? apiModal?.modalObject[itemKey] : "-"}</div> */}
                             <div className=" mb-2 content">{itemKey=== "content" && apiModal?.modalObject[itemKey] ? <span dangerouslySetInnerHTML={{ __html: apiModal?.modalObject[itemKey] }}></span> : apiModal?.modalObject[itemKey] ? <span className="lightGrey">{apiModal?.modalObject[itemKey]}</span>  : "-"}</div>
                         </>: itemKey !== "call_date" && itemKey !== "domain" && itemKey !== "deck_files" && Array.isArray(apiModal?.modalObject[itemKey]) && apiModal?.modalObject[itemKey]?.length ? <>
                             <div className="primaryGrey mb-2">{removeUnderScoreAndCapitalize(itemKey)}</div>
                             <div className="lightGrey mb-2">{ apiModal?.modalObject[itemKey].map((i, idx)=> {return (<span>{idx > 0 ? `,` : null} {i} </span> )})}</div>
                         </>  : null}
                        {itemKey == "deck_files" && (apiModal?.modalObject[itemKey] && Object.keys(apiModal?.modalObject[itemKey])?.length || apiModal?.editedFile?.length)? <>
                        <div className="primaryGrey mb-2">Attached File:</div>
                        <div className="lightGrey mb-2">{apiModal?.modalObject[itemKey] && Object.keys(apiModal?.modalObject[itemKey])?.length ? Object.keys(apiModal?.modalObject[itemKey]).map((i, idx)=> {return (<span>{idx > 0 ? `,` : null} {apiModal?.modalObject[itemKey][i]?.name} </span> )}) : null} {apiModal?.editedFile?.length ? apiModal?.editedFile?.map((it, idx)=> {return (<span>{((apiModal?.modalObject[itemKey] && Object.keys(apiModal?.modalObject[itemKey])?.length) || idx > 0 )? `,` : null} {it?.filename} </span> )}) : null}</div>
                        </> : null}
                         {itemKey === "call_date" ? <>
                             <div className="primaryGrey mb-2">Date:</div>
                             <div className="lightGrey mb-2">{apiModal?.modalObject[`call_date`] ? moment(apiModal?.modalObject[`call_date`]).format("MMM DD YYYY") : "-"}</div>
                         </> : null}
                     </div>
                    })}
                </Modal.Body>
                <ModalFooter>
                    <Button variant="primary" onClick={(e)=>handleMoreApi(e, apiModal?.api) } disabled={apiModal?.isBtnDisable}>
                        Continue
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    const handleMoreApi = (e, url) => {
        e.preventDefault();
        setApiModal({
            ...apiModal,
            isLoading: true,
            isBtnDisable: true,
        })
        if (isCallSummariesEditing) {
            let url1 = getAddress() + "/file-metrics/company-call-summaries"
            dispatch(deleteMoreFn(url1, currentUuid)).then(res => {
                if (res.status === "success") {
                    postCallsummaries(url)
                } else {
                    setApiModal({
                        ...apiModal,
                        show: false,
                        isLoading: false
                    })
                    setData({
                        ...data,
                        isError: false,
                        errorMessage: "",
                        loading: false,
                        message: "Error Saving Data",
                        openModal: true,
                        isSuccess: false
                    })
                }
            })
        } else {
            postCallsummaries(url)
        }
        
    }
    const postCallsummaries = (url) => {
        let payload = new FormData()
        if (apiModal?.modalObject[`deck_attached`][0] === "Yes") {
            if(file && Object.keys(file).length) {
                Object.keys(file).forEach(selFile => {
                    payload.append("deck_files", file[selFile]);
                });
            }
            if (apiModal?.editedFile && apiModal?.editedFile?.length) {
                apiModal?.editedFile.forEach(selFile => {
                    payload.append("deck_files", selFile?.file, selFile?.filename);
                });
            }
        }
        payload.append("content", apiModal?.modalObject[`content`]);
        payload.append("domain", apiModal?.modalObject[`domain`][0]?.server_value);
        payload.append("call_date", moment(apiModal?.modalObject[`call_date`]).format());
        payload.append("deck_attached", apiModal?.modalObject[`deck_attached`][0]);
       
        dispatch(addMoreFn(url, payload)).then((resp) => {
            if (resp.status === "success") {
                let errors = { ...form[`errors`] }
                errors[apiModal?.cardName] = ""
                setApiModal({
                    ...apiModal,
                    show: false,
                    isLoading: false,
                    isBtnDisable: false,
                })
                setForm({
                    ...form,
                    [`errors`]: errors
                })

                isCallSummariesEditing = false,
                currentUuid = ""
            } else {
                setApiModal({
                    ...apiModal,
                    show: false,
                    isLoading: false,
                    isBtnDisable: false,
                })
                setData({
                    ...data,
                    isError: false,
                    errorMessage: "",
                    loading: false,
                    message: "Error Saving Data",
                    openModal: true,
                    isSuccess: false
                })
            }
        });
    }

    const renderGraph = (name) => {
        let optionName = name?.split("_")?.shift()
        return (
            <Chart options={graphOptions[optionName] || {}} highcharts={Highcharts} />
        )
    }
    const renderTable = (item) => {
        {/*This commented code may be required*/}
        // return (<>
        //     <div className="custom-table-firm mb-3 ">

        //         {!isTableLoading && <ul className="table-header position-sticky" style={{ outline: "solid", top: 0 }}>
        //             {renderHeader(item?.fields)}
        //         </ul>}
        //         <ul className="table-body">
        //             {renderBody(item?.fields)}
        //         </ul>
        //     </div>
        //     </>
        // )
        return<>
            {isTableLoading ? 
                <LoaderTable height="100px"/> : tableData?.length>0 ? 
                    <SubmissionTableView fields={item?.fields} tableData={tableData} handleListModal={handleListModal}/> 
                        :<SubmissionTableView fields={[]} tableData={[]} />}
        </> 
    }
    const renderHeader = (item) => {
         return item?.map(headerItem => {
                return (
                    <>
                        {(headerItem?.column_name !== "content" && headerItem?.column_name !== "year") ? <li className="text-center text-capitalize">{headerItem?.title}</li> : null}
                    </>
                )
            })
    }
    const renderBody = (item) => {
        return <> {isTableLoading ? <LoaderTable height="100px"/> : tableData?.length>0  ? tableData?.map(tableItem => {
            return (
                <li onClick={() => handleListModal(tableItem, item)} style={{ cursor: "pointer"}}>
                    {Object.keys(tableItem).map((key, indx) => {
                        let val;
                        if (item[indx]?.column_name === "quarter") {
                            val = tableItem[`quarter`] + " " + tableItem[`year`]
                        } else if (item[indx]?.column_name !== "year") {
                            val = item[indx]?.column_name && item[indx]?.column_name === "created_at" ? moment(
                                tableItem[`${item[indx]?.column_name}`]
                            ).format("MMM DD YYYY") : item[indx]?.column_name && item[indx]?.column_name !== "created_at" ? tableItem[`${item[indx]?.column_name}`] : ""
                        }
                        val = String(val)
                        return (<>
                            {val && (item[indx]?.column_name !== "content" && item[indx]?.column_name !== "year") ? <div className="text-center text-capitalize singleLineTxt">{val}</div> : null}
                        </>
                        )
                    })}
                </li>
            )
        }) : <li className="d-block text-center" style={{ background: "none" }}>No data available</li>} </>
    }
    const handleListModal = (tableItem, item) => {
        setTableModal({
            ...modal,
            show: true,
            tableObject: tableItem,
            itemObject: item
        })
    }
    
    const renderModal = () => {
        let unsorted = { ...modal?.tableObject };
        let sorted = {};

        if (unsorted?.hasOwnProperty("quarter") && unsorted?.hasOwnProperty("year") && unsorted?.hasOwnProperty("company_name") && unsorted?.hasOwnProperty("content")) {
            sorted = {
                company_name: unsorted[`company_name`],
                quarter: unsorted[`quarter`],
                content: unsorted[`content`],
                ...unsorted
            }
        } else {
            sorted = {
                ...unsorted
            }
        }

        return (
            <Modal
                show={modal?.show}
                onHide={() => setTableModal({ ...modal, show: false })}
                dialogClassName="custom-modal"
                aria-labelledby=""
                size="lg"
            >
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title className="main-title">
                        Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    {Object.keys(sorted).map((key, indx) => {
                        let val;
                        if (key === "quarter") {
                            val = sorted[`quarter`] + " " + sorted[`year`]
                        } else if (key !== "year") {
                            val = key && key === "created_at" ? moment(
                                sorted[`${key}`]
                            ).format("MMM DD YYYY") : key && key !== "created_at" ? sorted[`${key}`] : ""
                        }

                        val = String(val)
                        return (<>
                            {val ? <div className="">
                                {val && val?.includes("<p>") ? <div className="mb-2"><span dangerouslySetInnerHTML={{ __html: val }}></span></div> : (key !== "year") ? <div className="mb-2">{val}</div> : null}
                            </div> : null}
                        </>
                        )
                    })}
                </Modal.Body>
            </Modal>
        )
    }

    const renderConfirmationModal = () => {
        return (
            <Modal
                show={confirmDialog}
                onHide={() => setConfirmDialog(false)}
                dialogClassName="custom-modal"
                aria-labelledby=""
                size="lg"
            >
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title className="main-title">
                    Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    Do you want to proceed with existing data ?
                </Modal.Body>
                <ModalFooter>
                    <Button variant="secondary" onClick={handleCancel}>
                        Reset Data
                    </Button>
                    <Button variant="primary" onClick={handleProceed}>
                        Proceed
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    const renderDeleteConfirmation = () => {
        return (
            <Modal
                show={deleteConfirmation?.show}
                onHide={() => setDeleteConfirmation({...deleteConfirmation, show: false})}
                dialogClassName="custom-modal"
                aria-labelledby=""
                size="lg"
            >
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title className="main-title">
                    Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    {deleteConfirmation?.isLoading ? <Loader scale={30} message={""} customStyle={"child"} /> : `Do you want to delete existing data ?`}
                </Modal.Body>
                <ModalFooter>
                    <Button variant="primary" onClick={handleDeleteProceed} disabled={deleteConfirmation?.isBtnDisable}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    const handleContinue = () => {
        if (data.isSuccess) {
            resetData()
        }
        setData({ ...data, openModal: false, isSuccess: null, message: "" })
    }
    const resetData = () => {
        let newArr = { ...form }
        let newEditor = { ...editor }
        for (let key in form) {
            newArr[key] = Array.isArray(form[key]) ? [] : ""
            newEditor[key] = Array.isArray(form[key]) ? [] : ""
        }
        setForm(newArr)
        setEditor(newEditor)
        setDateFields({
            from: moment(new Date().setHours(0, 0, 0, 0)),
            to: moment(new Date().setHours(23, 59, 59, 0))
        })

    }

    const handleDraftModal = () => {
        setDraftModal({
            ...draftModal,
            show: true,
            modalData: draftList,
        })
        amplitude.logEvent('Associate Weekly Metrics Drafts Clicked');
    }

    function handleDraftEdit(draft_id){
        dispatch(getDraftDetail(axiosDraftDetail, {draft_id:draft_id}))
        setDraftModal({
            ...draftModal,
            show: false,
        })
        amplitude.logEvent('Associate Weekly Metrics Drafts Edit Clicked');
    }

    return (
        <>  
            {deleteConfirmation?.show && renderDeleteConfirmation()}
            {confirmDialog && renderConfirmationModal()}
            {apiModal?.show && renderApiModal()}
            {modal?.show && renderModal()}
            {draftModal?.show &&  <DraftSlide onClose={() => setDraftModal({ ...draftModal, show: false })} show={draftModal?.show} modalContent={draftModal?.modalData} editDraft={handleDraftEdit}/> }
        
            {data?.openModal && <SubmitCard showToast={data?.openModal} onHide={() => handleContinue()} isSuccess={data?.isSuccess} message={data?.message} />}
            <Row className="m-0 m-b-30">
                {data.loading ? <Col xs={12}> <LoaderSubmission height /></Col> : data?.isError ? <CommonError errorMessage={data?.errorMessage} isBlurredRequired={true} /> :
                    <Form autoComplete="off" className="submission-template" >
                        <Row className="mb-4">
                            <Col lg={12}>
                                { data?.template ?
                                    <div className="top-header-box " >
                                    <Col xs={12}>
                                        <span className="d-flex align-items-center justify-content-between">
                                            <span>
                                                {<span className="m-r-10">{data?.template}</span>}
                                            </span>
                                            <span>
                                                {draftList?.length > 0 && data?.template === "Associate Weekly Metrics"  && (<div>
                                                    <Button variant="default" onClick={handleDraftModal} >{draftList?.length}  {draftList?.length > 1 ? "Saved Drafts" : "Saved Draft"}</Button>
                                                </div>)}
                                            </span>
                                        </span>
                                    </Col>
                                </div> :
                                <Nofilter message={'No template available!'}/>
                                }
                            </Col>
                        </Row>
                        {<div>
                        {matricTemplate?.request_schema?.hasOwnProperty('from') && matricTemplate?.request_schema?.hasOwnProperty('to') &&
                            <Row className="" key={`date-`}>
                                <Col md={12}  className="m-b-40">
                                    <Card>
                                        <Card.Body className="p-10" style={{ paddingBottom: "0px" }}>
                                            <Row className="m-0">
                                                <Col xs={12} md={5} >
                                                    <Form.Group className="mb-3 m-r-10 w-100 position-relative" controlId="formBasicEmail">
                                                        <Form.Label>From:</Form.Label>
                                                        <CustomDate selectedDate={dateFields?.from} handleDateChange={date => handleDate(date, "from")} />
                                                        {dateFields.errors && dateFields?.errors["from"] && <span className="warningText card-body-paragraph">{dateFields.errors["from"]}</span>}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={{ span: 5, offset: 1 }}>
                                                    <Form.Group className="mb-3 m-r-10 w-100 position-relative" controlId="formBasicEmail">
                                                        <Form.Label>To:</Form.Label>
                                                        <CustomDate selectedDate={dateFields?.to} handleDateChange={date => handleDate(date, "to")} />
                                                        {dateFields.errors && dateFields?.errors["to"] && <span className="warningText card-body-paragraph">{dateFields.errors["to"]}</span>}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        
                        {matricTemplate.hasOwnProperty("display") ? renderCard(matricTemplate?.display, "display") : null}
                        {renderCard(matricTemplate?.request_schema, "form")}
                        {data?.template && (<Col xs={12} md={12}  className="d-flex justify-content-end mt-2" >
                            {data?.template !== "Associate Weekly Metrics Graph" &&<span>{data?.template === "Associate Weekly Metrics" && (<Button variant="default" onClick={(e)=>handleSubmit(e, "saveDraft")} style={{marginRight: "8px"}}>Save as draft</Button>)}<Button variant="primary" onClick={(e)=>handleSubmit(e, "submit")} >Submit</Button></span> }
                        </Col>)}
                        </div>}
                    </Form>
                }
            </Row>
        </>
    );
};

export default Submissions;
