import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Row, Col, Form, Dropdown, Tabs, Tab } from "react-bootstrap";
import { getAddress } from "../../utils";
import axios from "axios";
import moment from "moment";
import * as actionTypes from "../../store/actionTypes";
import * as amplitude from '@amplitude/analytics-browser';
import {
  getTpStatusDiscoveryOptions,
  getDiscoveryCompanies,
  getDiscoveryTpOwners,
  getDiscoveryFiltersMetaData,
  getLoggedInUser,
  getQuipTypes,
  getAllDataSource,
  getListsAndBucketsOptions
} from "../../store/actions";
import {
  axiosCompanyPageTpStatusOptions,
  axiosGetWorkboardPeople,
  axiosDiscoveryFiltersData,
  axiosLLMFilters,
  axiosUserInfoInstance,
  axiosQuipTypes,
  axiosGetExploreDataSources,
  axiosListsAndBucketsOptions
} from "../../config/appConfig/axiosInstance";
import PlainTootip from "../../commonComponents/InfoTooltip/PlainTooltip";
import { setDiscoveryFiltersPayload, getLLMFiltersData, setAppliedFilters } from "../../store/actions/discoveryAction";
import DiscoveryCards from "./DiscoveryCards/DiscoveryCards";
import CommonError from "../../commonComponents/CommonError/CommonError";
import {
  employeeInsightConstants,
  infoMessage,
  totalFundingInfoIcon
} from "./discoveryConstant";
import TagsInSlider from "./TagsInSlider/TagsInSlider";
import LocationFilter from "./LocationFilter/LocationFilter";
import FounderLocation from "./FounderLocation/FounderLocation";
import FounderMetro from "./FounderMetro/FounderMetro";
import EmployeeCountry from "./EmployeeCountry/EmployeeCountry";
import InputFields from "./InputFields/InputFields";
import SearchHistorySlider from "./SearchHistorySlider/SearchHistorySlider";
import RankingSlider from "./RankingSlider/RankingSlider";
import CompanyLists from "./CompanyLists/CompanyLists";
import { RangePickerWithCustomOptions } from "../../commonComponents/Datepicker/RangePickerWithCustomOptions";
import { ReactComponent as SearchIcon } from "../../assets/images/icon_svg/search_icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icon_svg/X.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/icon_svg/eye-icon.svg";
import { ReactComponent as FiltersIcon } from '../../assets/images/icon_svg/filters_logo.svg';
import { ReactComponent as SortByIcon } from '../../assets/images/icon_svg/sort-by.svg';
import { ReactComponent as PromptIcon } from '../../assets/images/icon_svg/prompt-icon.svg';
import { ReactComponent as TelescopeSearchIcon } from '../../assets/images/icon_svg/telescope-search.svg';
import { ReactComponent as StarsIcon } from '../../assets/images/icon_svg/stars-icon.svg';
import { ReactComponent as MoonIcon } from '../../assets/images/icon_svg/moon-icon.svg';
import { ReactComponent as DustbinIcon } from '../../assets/images/icon_svg/dustbin-icon.svg';
import { ReactComponent as CollapseIcon } from '../../assets/images/icon_svg/collapse-icon.svg';
import { ReactComponent as ExpandIcon } from '../../assets/images/icon_svg/expand-icon.svg';
import { ReactComponent as AffinityIcon} from '../../assets/images/icon_svg/lists-icon.svg';
import { ReactComponent as CrossRemoveIcon} from '../../assets/images/icon_svg/cross-icon.svg';
import { ReactComponent as DropdownIcon} from '../../assets/images/icon_svg/dropdown-icon.svg';
import { ReactComponent as DatasourcesIcon} from '../../assets/images/icon_svg/datasources-icon.svg';
import { ReactComponent as ListsPrompt} from '../../assets/images/icon_svg/lists-prompt.svg';
import { ReactComponent as ArrowDownIcon} from '../../assets/images/icon_svg/blue-down-arrow.svg';
import { ReactComponent as DatasourcePrompt} from '../../assets/images/icon_svg/datasource-prompt.svg';
import { ReactComponent as RedCrossIcon} from '../../assets/images/icon_svg/red-cross.svg';
import earthBackgroundIcon from '../../assets/images/icon_svg/earth-background.svg';
import { ReactComponent as AlertIcon} from '../../assets/images/icon_svg/alert.svg';
import promptAnime from "../../assets/images/prompt-animation.gif";
import earthLoader from "../../assets/images/discovery-loader.gif";
import filtersAnime from "../../assets/images/filters-anime.gif";
import imgLoader from "../../assets/images/spinner.gif"; 

import "./discovery.scss";
import Slider from "./Slider/Slider";

const column_order = [
  "tp_owners",
  "tp_status",
  "growth",
  "growth_tags",
  "newsletter",
  "year_founded",
  "employee_insights",
  "investment_data",
  "visit_history",
  "communication_with_telescope",
  "founder_location_search",
  "headquarter_search",
  "status"
];

const newsletters_filters = [
  { server_value: "newsletter_sender", displayName: "Quip Type" },
  { server_value: "from_to_date", displayName: "Date Appeared" },
]

let cancelToken;
let llmInputFilterClicked = false;
let isInitialCall = false;
let removedClicked = false;

const result = column_order.map((col) => {
  let displayName = col
    .replace(/_/g, " ")
    .replace(/\b\w/g, (l) => l.toUpperCase());

  if (col === "tp_owners" || col === "tp_status") {
    displayName = displayName.replace("Tp", "TP");
  }

  if (col === "communication_with_telescope") {
    displayName = displayName.replace("With", "with");
  }

  if (col === "founder_location_search") {
    displayName = "Founder Metro Area";
  }

  if (col === "headquarter_search") {
    displayName = "Headquarter Search";
  }

  return {
    display_name: displayName,
    server_value: col,
    infoMessage: infoMessage?.[`${col}`] ? infoMessage[col] : ""
  };
});

const fieldsWithSearchBox = ["tp_owners", "tp_status", "status"];
const preDaysTags = ["total_funding_usd"];
const employeeInsightsTags = ["employee_count"];
const growthTags = [
  { value: 'growth_6m', displayName: 'Growth 6m' },
  { value: 'growth_1y', displayName: 'Growth 1y' },
  { value: 'growth_2y', displayName: 'Growth 2y' }
];

let isTabchangedclicked = false;
let hasOwnerData = false;

const Discovery = () => {

  const dispatch = useDispatch();
  const filtersRef = useRef(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const toggleBtnRef = useRef(null);
  const listButtonRef = useRef(null);
  const tabsRef = useRef(null);
  const isFilterElementClickedRef = useRef(null)
  const [
    tpStatusOptions,
    tpOwners,
    countries,
    states,
    cities,
    status,
    llmFilterData,
    growth_tags,
    discoveryCompaniesResults, 
    searchFilterApplied,
    userInfo,
    quipTypes,
    selectedListsData,
    founder_metro_areas,
    allDataSources,
    filteredData,
    listOptions
  ] = useSelector(({ discoveryReducer, dashBoardReducer, commonReducer }) => [
    discoveryReducer.discoveryTpStatusOptions.data,
    discoveryReducer.tpOwners,
    discoveryReducer.metadata.countries,
    discoveryReducer.metadata.states,
    discoveryReducer.metadata.cities,
    discoveryReducer.metadata.statuses,
    discoveryReducer?.llmFilterData,
    discoveryReducer.metadata.growth_tags,
    discoveryReducer.discoveryCompanies,
    discoveryReducer.searchFilterApplied,
    dashBoardReducer?.userInfo,
    discoveryReducer?.quipTypes,
    discoveryReducer?.selectedListsData,
    discoveryReducer.metadata.founder_metro_areas,
    discoveryReducer.allDataSources,
    discoveryReducer.discoveryCompanies,
    commonReducer.commonListsBucketsOptions.lists
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [dropdownsearchTerms, setDropdownSearchTerms] = useState({});
  const [checkboxObject, setCheckboxObject] = useState({});
  const [checkboxObjectVisiblity, setCheckboxObjectVisiblity] = useState({});
  const [tpStatusOperator, setTpStatusOperator] = useState("is");
  const [tpStatusSince, setTpStatusSince] = useState("");
  const [sliderObject, setSliderObject] = useState({});
  const [preTags, setPreTags] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState('');
  const [openItems, setOpenItems] = useState([]);
  const [accordionObject, setAccordionObject] = useState({});
  const [currentSelection, setCurrentSelection] = useState("");
  const [llmFounderLocation, setLLmFounderLocation] = useState({});
  const [llmEmployeeCountry, setLLMEmployeeCountry] = useState({});
  const [populateLocation, setPopulateLocation] = useState([]);
  const [selectedGrowthsData, setSelectedGrowthsData] = useState([]);
  const [hqLocation, setHqLocation] = useState({});
  const [selectedTotalFundingData, setSelectedTotalFundingData] = useState({ trueFalse: 'false' });
  const [filterSortState, setFilterSortState] = useState({
    sortCriteria: "",
    filterTpStatus: null,
    filterTpOwner: null,
  });
  const [filteredLocationObject, setFilteredLocationObject] = useState({
    keys: "",
    value: "",
  });
  const [yearFounded, setYearFounded] = useState({
    greaterThanValue: '',
    lesserThanValue: ''
  });

  const [selectedFounderLocation, setSelectedFounderLocation] = useState({
    data: []
  });
  const [selectedEmployeeCountry, setSelectedEmployeeCountry] = useState({
    data: []
  });
  const [countryEmployeeCount, setCountryEmployeeCount] = useState({});
  const [metaDataLoading, setMetaDataLoading] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const [communicationFilter, setCommunicationFilter] = useState({
    option: "",
    reachedOut: false,
    notReachedOutValue: "",
    timeUnit: "days",
    days_of_email_sent: 0,
  });
  const [visitHistoryFilter, setVisitHistoryFilter] = useState({
    visitHistoryValue: "",
    timeUnit: "days",
    days_of_email_sent: 0,
  });
  const [llmFilter, setLLMFilters] = useState({
    enteredValue: "",
    searchTerm: ""
  })
  const [historySlider, setHistorySlider] = useState(false)
  const [searchHistory, setSearchHistory] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [hasResults, setHasResults] = useState(true);
  const [appliedFilterString, setAppliedFilterString] = useState("");
  const [growthsData, setGrowthsData] = useState([]);
  const [selectedGrowthsTag, setSelectedGrowthsTag] = useState([]);
  const [isActiveBtn, setIsActiveBtn] = useState("orion");
  const [listDropdownVisible, setListDropdownVisible] = useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [activeFilter, setActiveFilter] = useState("tp_owners");
  const [saasFilter, setSaasFilter] = useState({
    isSaasFilterApplied: false,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedQuipsType, setSelectedQuipsType] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(0); 
  const [sliderContent, setSliderContent] = useState("");
  const [rightSideContent, setRightSideContent] = useState("filters");
  const [focusedCardIndex, setFocusedCardIndex] = useState(0);
  const [form, setForm] = useState({
    startDate: "",
    endDate: "",
    dates: [],
  })
  const [newsletterForm, setNewsletterForm] = useState({
    startDate: "",
    endDate: "",
    dates: [],
    isNewsletterFilterApplied: false,
    newsLetterCheckbox: [],
  })
  const [newsletterSearchTerm, setNewsletterSearchTerm] = useState("");
  const [rankingSlider, setRankingSlider] = useState(false);
  const [selectedListOptions, setSelectedListOptions] = useState([]);
  const [visibleTabs, setVisibleTabs] = useState([]);
  const [dropdownTabs, setDropdownTabs] = useState([]);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tpStatusTagged, setTpStatusTagged] = useState(false);
  const [tpOwnerTagged, setTpOwnerTagged] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const isLoadingRef = useRef(isLoading);
  const rightSideContentRef = useRef(rightSideContent);
  const appliedFiltersCountRef = useRef(0);
  const hasResultsRef = useRef(hasResults);
  const prevSelectedEmployeeCountry = useRef(null);
  const prevCountryEmployeeCount = useRef(null);
  const [filtersFromURL, setFiltersFromURL] = useState(null);
  const [showEnterKeyPrompt, setShowEnterKeyPrompt] = useState(false);

  useEffect(() => {
    setMetaDataLoading({
      ...metaDataLoading,
      isLoading: true,
    });
    if (!countries?.length || !states?.length || !cities?.length || !status?.length || !founder_metro_areas?.length || !growth_tags?.length || !quipTypes?.length) {
      dispatch(getDiscoveryFiltersMetaData(axiosDiscoveryFiltersData)).then(
        (res) => {
          if (res.status === "success") {
            setMetaDataLoading({
              ...metaDataLoading,
              isLoading: false,
              errorMessage: "",
            });
            amplitude.logEvent(`Discovery Loaded`);
          } else {
            setMetaDataLoading({
              ...metaDataLoading,
              isLoading: false,
              errorMessage: "No metadata available!",
            });
          }
        }
      );
    }
    if (!tpStatusOptions?.length) dispatch(getTpStatusDiscoveryOptions(axiosCompanyPageTpStatusOptions));
    if (!tpOwners?.length) dispatch(getDiscoveryTpOwners(axiosGetWorkboardPeople));
    dispatch(getQuipTypes(axiosQuipTypes));
    dispatch(getAllDataSource(axiosGetExploreDataSources))
    if(!userInfo) dispatch(getLoggedInUser(axiosUserInfoInstance));
    if (!listOptions?.length) {
      dispatch(getListsAndBucketsOptions(axiosListsAndBucketsOptions));
    }
  }, []);

  useEffect(() => {
    if (allDataSources?.results?.length > 0) {
      const defaultSource = allDataSources.results?.find(ds => ds?.datasource_type === "orion");
      if (defaultSource) {
        setSelectedDataSource(defaultSource);
      }
    }
  }, [allDataSources]);

  useEffect(() => {
    const updateTabs = () => {
      if (!tabsRef.current) return;
  
      const containerWidth = tabsRef.current.clientWidth;
      let usedWidth = 0;
      let visible = [];
      let dropdown = [];
  
      selectedListOptions.forEach((tab) => {
        // Create a temporary element to measure the tab width
        const tempTab = document.createElement("div");
        tempTab.style.position = "absolute";
        tempTab.style.visibility = "hidden";
        tempTab.style.whiteSpace = "nowrap";
        tempTab.className = "tab-title";
        tempTab.innerText = tab.name;
        document.body.appendChild(tempTab);
  
        const tabWidth = tempTab.offsetWidth + 40; // Include padding/margins
        document.body.removeChild(tempTab);
  
        if (usedWidth + tabWidth <= containerWidth * 0.9) { // Leave some space for dropdown
          visible.push(tab);
          usedWidth += tabWidth;
        } else {
          dropdown.push(tab);
        }
      });
  
      // Ensure last tab is fully visible, else push to dropdown
      if (visible.length > 0) {
        const lastTab = visible[visible.length - 1];
        const tempLastTab = document.createElement("div");
        tempLastTab.style.position = "absolute";
        tempLastTab.style.visibility = "hidden";
        tempLastTab.style.whiteSpace = "nowrap";
        tempLastTab.className = "tab-title";
        tempLastTab.innerText = lastTab.name;
        document.body.appendChild(tempLastTab);
  
        const lastTabWidth = tempLastTab.offsetWidth + 40;
        document.body.removeChild(tempLastTab);
  
        if (usedWidth - lastTabWidth + lastTabWidth > containerWidth) {
          dropdown.unshift(visible.pop());
        }
      }
  
      setVisibleTabs(visible);
      setDropdownTabs(dropdown);
      setIsOverflowing(dropdown.length > 0);
    };
  
    updateTabs();
    window.addEventListener("resize", updateTabs);
    return () => window.removeEventListener("resize", updateTabs);
  }, [selectedListOptions]);
  
  const handleDropdownSelect = (tabId) => {
    const selectedTab = dropdownTabs.find((tab) => tab.workboard_id === tabId);
    if (!selectedTab) return;
  
    // Remove from dropdown and add to visible list
    const updatedDropdownTabs = dropdownTabs.filter((tab) => tab.workboard_id !== tabId);
    let updatedVisibleTabs = [selectedTab, ...visibleTabs];
  
    // Check if the updated visible list exceeds the container width
    let containerWidth = tabsRef.current.offsetWidth;
    let usedWidth = 0;
    let visibleTabsAfterReorder = [];
    let remainingTabs = [];
  
    updatedVisibleTabs.forEach((tab) => {
      const tempTab = document.createElement("div");
      tempTab.style.position = "absolute";
      tempTab.style.visibility = "hidden";
      tempTab.style.whiteSpace = "nowrap";
      tempTab.className = "tab-title";
      tempTab.innerText = tab.name;
      document.body.appendChild(tempTab);
  
      const tabWidth = tempTab.offsetWidth + 40;
      document.body.removeChild(tempTab);
  
      if (usedWidth + tabWidth < containerWidth * 0.9) {
        visibleTabsAfterReorder.push(tab);
        usedWidth += tabWidth;
      } else {
        remainingTabs.push(tab);
      }
    });
    setVisibleTabs(visibleTabsAfterReorder);
    setDropdownTabs([...updatedDropdownTabs, ...remainingTabs]);
    handleTabChange(tabId)
  };
  
  useEffect(() => {
    const filters = loadFiltersFromURL();
    const ownerProcessed = tpOwners?.some((owner) => owner?.email_address?.toLowerCase() === userInfo?.email?.toLowerCase() && owner?.processed);

    if (Object.keys(filters).length > 0 && !ownerProcessed) {
      setFiltersFromURL(filters);
    } else if (userInfo && userInfo?.email) {
      if (!ownerProcessed) {
        const ownerOption = tpOwners?.find((owner) => owner?.email_address?.toLowerCase() === userInfo?.email?.toLowerCase());
        if (ownerOption) {
          ownerOption.processed = true;
          hasOwnerData = true;
          handleCheckboxChange(ownerOption, true, "tp_owners", []);
        }
      }
    }
  }, [userInfo, tpOwners]);
  
  useEffect(() => {
    if (column_order?.length) {
      let finalObject = {};
      column_order?.forEach((i, inx) => {
        finalObject[i] = {
          name: i,
          index: inx,
          isAccordionOpen: false,
        };
      });
      setAccordionObject(finalObject);
    }
  }, [column_order]);

  useEffect(() => {
    if (fieldsWithSearchBox?.length) {
      let finalObject = {};
      let visbilityObject = {};
      let searchObject = {};
      fieldsWithSearchBox?.map((i) => {
        if (i === "tp_status") {
          finalObject[i] = [];
        } else {
          finalObject[i] = [];
        }
        visbilityObject[i] = {
          count: 20,
          visible: false,
          searchCount: 0,
          isSearching: false,
        };
        searchObject[i] = "";
      });
      setCheckboxObject(finalObject);
      setCheckboxObjectVisiblity(visbilityObject);
      setDropdownSearchTerms(searchObject);
    }
  }, [fieldsWithSearchBox]);

  useEffect(() => {
    if (preDaysTags?.length) {
      let finalObject = {};
      preDaysTags?.map((i) => {
        finalObject[i] = [];
      });
      setPreTags(finalObject);
    }
  }, [preDaysTags]);

  useEffect(() => {
    if (growth_tags?.length) {
      let formattedGrowth = growth_tags?.map(i => {
        let displayName = i?.tag_name?.replace(/_/g, ' ')?.replace(/\b\w/g, (l) => l.toUpperCase());
        return {
          displayName: displayName,
          value: i.tag_name,
          count: i.tag_count,
          description: i.tag_description
        }
      })
      setGrowthsData(formattedGrowth)
    }
  }, [growth_tags])

  useEffect(() => {
    if (column_order?.length) {
      const filteredColumnOrder = column_order.filter(
        (item) =>
          !fieldsWithSearchBox.includes(item) || !preDaysTags.includes(item)
      );

      const finalObject = {};
      filteredColumnOrder?.forEach(
        (i) =>
        (finalObject[i] = {
          greaterThanThumbPosition: null,
          lesserThanThumbPosition: null,
          symbol:
            i === "employee_count"
              ? ""
              : i === "sales_engineering_percentage" ||
                i === "seniority_percentage"
                ? "%"
                : "days",
          maxValue:
            i === "sales_engineering_percentage" ||
              i === "seniority_percentage"
              ? 100
              : ["employee_count"].includes(i)
                ? 10000
                : 100,
        })
      );
      employeeInsightsTags.forEach((tag) => {
        finalObject[tag] = {
          greaterThanThumbPosition: null,
          lesserThanThumbPosition: null,
          symbol: tag === "employee_count" ? "" : "%",
          maxValue: tag === "employee_count" ? 10000 : 100,
        };
      });

      // Exclude year_founded from finalObject
      const sliderObjectKeys = Object.keys(finalObject).filter(key => key !== 'year_founded');
      const newSliderObject = {};
      sliderObjectKeys.forEach(key => {
        newSliderObject[key] = finalObject[key];
      });

      setSliderObject(newSliderObject);
    }
  }, [column_order]);

  useEffect(() => {
    if (llmFilter?.enteredValue && selectedDataSource === null) {
      setIsLoading(true);
      handleResetAll();
      dispatch(getLLMFiltersData(axiosLLMFilters, { filter_search: llmFilter?.enteredValue }))
    } else {
      setIsLoading(false);
    }
  }, [llmFilter?.enteredValue]);

  useEffect(() => {
    if (llmFilterData && Object.keys(llmFilterData)?.length && !isEmptyObject()) {
      populateFilterFn();
    } else {
      setIsLoading(false);
      setHasResults(false);
    }
  }, [llmFilterData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.RESET_LLM_FILTERS
      })
      handleResetAll();
      setLLMFilters({
        enteredValue: "",
        searchTerm: ""
      })
    }
  }, []);

  const isEmptyObject = () => {
    let hasEmpty = Object.entries(llmFilterData).every(([key, value]) => {
      if (key === 'description') {
        return true;
      }
      if (key === 'uuid') {
        return true;
      } else if (Array.isArray(value)) {
        return value.length === 0;
      } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      } else {
        return value === "n/a" || value === null || value === 0 || value === "";
      }
    });
    return hasEmpty;
  }

  const debounce = (delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        handleSubmit();
      }, delay);
    };
  };

  const debouncedHandleSubmit = debounce(handleSubmit, 300);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if(!isTabchangedclicked) {
        updateAppliedFiltersCount();
        handleSubmit();
      }
    }, 300);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    checkboxObject,
    sliderObject,
    preTags,
    selectedGrowthsData,
    selectedGrowthsTag,
    selectedTotalFundingData,
    hqLocation,
    yearFounded,
    tpStatusSince,
    selectedFounderLocation,
    communicationFilter.reachedOut,
    communicationFilter.option,
    communicationFilter.days_of_email_sent,
    communicationFilter.notReachedOutValue,
    visitHistoryFilter.visitHistoryValue,
    visitHistoryFilter.days_of_email_sent,
    saasFilter?.isSaasFilterApplied,
    selectedQuipsType,
    form,
    newsletterForm,
    tpStatusTagged,
    tpOwnerTagged,
    selectedDataSource,
    selectedListOptions
  ]);

  useEffect(() => {
    if (!isTabchangedclicked) {
      const hasValue = checkLength();
      const isDataRemoved = () => {
        const prevCountryLength = prevSelectedEmployeeCountry.current?.data?.length || 0;
        const newCountryLength = selectedEmployeeCountry.data?.length || 0;
        const prevTagCount = prevCountryEmployeeCount.current?.employee_count?.filter(tag => tag.value && typeof tag.value === "string" && tag.value.trim() !== "").length || 0;
        const newTagCount = countryEmployeeCount?.employee_count?.filter(tag => tag.value && typeof tag.value === "string" && tag.value.trim() !== "").length || 0;
        const bothHadData = prevCountryLength > 0 && prevTagCount > 0;
        const countryRemoved = prevCountryLength > newCountryLength;
        const tagRemoved = prevTagCount > newTagCount;
        return bothHadData && (countryRemoved || tagRemoved);
      };
      if (isDataRemoved() && hasValue) {
        handleSubmit();
      }
      if (
        selectedEmployeeCountry.data?.length > 0 && countryEmployeeCount?.employee_count?.some(tag => tag.value && typeof tag.value === "string" && tag.value.trim() !== "")
      ) {
        handleSubmit();
      }
      if (!hasValue) {
        setHasResults(false);
        setFiltersApplied(false);
        dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: [] });
      }
      prevSelectedEmployeeCountry.current = selectedEmployeeCountry;
      prevCountryEmployeeCount.current = countryEmployeeCount;
    }
  }, [selectedEmployeeCountry, countryEmployeeCount]);
  
  useEffect(() => {
    if (checkboxObject["tp_status"]?.length && !isTabchangedclicked) debouncedHandleSubmit();
  }, [tpStatusOperator]);

  const handleSearchBoxChange = (field, value, array) => {
    setDropdownSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [field]: value,
    }));

    if (value) {
      setCheckboxObjectVisiblity((prevCheckboxObject) => {
        // Copy the previous state to avoid mutating it directly
        const updatedObject = { ...prevCheckboxObject };
        // Check if serverValue exists in the checkboxObject
        if (updatedObject.hasOwnProperty(field)) {
          updatedObject[field]["isSearching"] = true;
          updatedObject[field]["searchCount"] =
            field === "tp_owners"
              ? array?.filter((item) =>
                item?.full_name?.toLowerCase()?.includes(value?.toLowerCase())
              )?.length
              : array?.filter((item) =>
                item?.toLowerCase()?.includes(value?.toLowerCase())
              )?.length;
        }
        return updatedObject;
      });
    } else {
      setCheckboxObjectVisiblity((prevCheckboxObject) => {
        // Copy the previous state to avoid mutating it directly
        const updatedObject = { ...prevCheckboxObject };
        // Check if serverValue exists in the checkboxObject
        if (updatedObject.hasOwnProperty(field)) {
          updatedObject[field]["isSearching"] = false;
          updatedObject[field]["searchCount"] = 0;
        }
        return updatedObject;
      });
    }
  };

  const toggleFilters = (event) => {
    isTabchangedclicked = false;
    setRightSideContent("filters");
  };

  const getDaysAgo = (createdAt) => {
    const createdDate = moment(createdAt);
    const today = moment(); 
    const diffDays = today.diff(createdDate, 'days');
    return diffDays === 0 ? "Today" : `${diffDays} days ago`;
  };

  useEffect(() => {
    const handleClick = (event) => {
      isTabchangedclicked = false;
      removedClicked = true;
    };
    const childElement = document.querySelector('.company-details-slider-container');
    if (childElement) {
      childElement?.addEventListener("click", handleClick);
    }
 
    return () => {
      if (childElement) {
        childElement.removeEventListener("click", handleClick);
      }
    };
  }, [activeTab]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const updateAppliedFiltersCount = () => {
    let appliedCount = 0;
    filterDisplayNames(searchTerm)?.forEach((filter) => {
      if (checkIfFilterApplied(filter.server_value)) {
        appliedCount++;
      }
    });
    newsletters_filters?.forEach((filter) => {
      if (checkIfFilterApplied(filter.server_value)) {
        appliedCount++;
      }
    }); 
    appliedFiltersCountRef.current = appliedCount;
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "Enter" && event.target.id !== 'searchTerm') {

      if (!selectedListOptions?.length && rightSideContentRef.current === "allLists") {
        return;
      } 

      if (rightSideContentRef.current === "slider") {
        return;
      }

      if (isActiveBtn === "list" && hasResults) {
        if(filteredData?.results?.length) {
          setSliderContent(filteredData?.results?.[focusedCardIndex]?.company_domain || "");
        }
        setRightSideContent("slider");
        return;
      } else if (isActiveBtn === "list" && !hasResults) {
        setRightSideContent("");
        return;
      }

      if ((isLoadingRef.current || (appliedFiltersCountRef.current === 0 && selectedDataSource?.datasource !== 'orion' && !hasResultsRef.current) || !hasResultsRef.current)
      ) {
        setRightSideContent("");
      } else {
        if(filteredData?.results?.length) {
          setSliderContent(filteredData?.results?.[focusedCardIndex]?.company_domain || "");
        }
        setRightSideContent("slider");
      }
    }
  };

  useEffect(() => {
    const handleKeyDownWrapper = (event) => handleKeyDown(event);

    document.addEventListener("keydown", handleKeyDownWrapper);
    return () => {
      document.removeEventListener("keydown", handleKeyDownWrapper);
    };
  }, [selectedListsData, activeTab, isActiveBtn, selectedListOptions, hasResults, focusedCardIndex, selectedDataSource]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        if ((isActiveBtn === "list" && !selectedListOptions?.length && rightSideContent === "allLists") || isLoading) {
          return;
        }
        if (hasResultsRef.current) {
          if(filteredData?.results?.length) {
            setSliderContent(filteredData?.results?.[focusedCardIndex]?.company_domain || ""); 
          }
          setRightSideContent("slider");
        } else {
          setRightSideContent("");
        }
      }
    };
    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isActiveBtn, selectedListOptions, rightSideContent, isLoading]); 
  
  useEffect(() => {
    isLoadingRef.current = isLoading;
    rightSideContentRef.current = rightSideContent;
    hasResultsRef.current = hasResults;
  }, [isLoading, rightSideContent, hasResults]);

  const handleSelectedDomain = (domain) => {
    setSliderContent(domain);
  };

  const processSliderData = (field, item, setSliderObject) => {
    let itemArr = item.value.split(":");
    let newState = {
      greaterThanValue: "",
      lessThanValue: "",
      selectedTags: []
    };

    if (itemArr[0].includes("GREATER_THAN_OR_EQUAL_TO")) {
      newState.greaterThanValue = itemArr[1];
      newState.selectedTags.push({
        value: itemArr[1],
        operator: "greaterThan"
      });
    }
    if (itemArr[0].includes("LESS_THAN_OR_EQUAL_TO")) {
      newState.lessThanValue = itemArr[1];
      newState.selectedTags.push({
        value: itemArr[1],
        operator: "lessThan"
      });
    }

    setSliderObject(prevState => {
      let combinedSelectedTags = [
        ...prevState[field]?.selectedTags?.filter(tag => {
          return !newState.selectedTags.some(newTag => newTag.operator === tag.operator);
        }) || [],
        ...newState.selectedTags
      ];
      const updatedFieldData = {
        ...prevState[field],
        greaterThanValue: newState.greaterThanValue || prevState[field]?.greaterThanValue || "",
        lessThanValue: newState.lessThanValue || prevState[field]?.lessThanValue || "",
        selectedTags: combinedSelectedTags
      };
      return {
        ...prevState,
        [field]: updatedFieldData
      };
    });
  };

  const populateFilterFn = () => {
    let updatedAccordionObject = { ...accordionObject };

    if (Object.keys(llmFilterData)?.length) {
      Object.keys(llmFilterData)?.forEach(i => {
        if (i === "tp_owner" && llmFilterData[i]?.length) {
          let selectedTP_Owners = tpOwners?.filter(tpOwner => llmFilterData[i].includes(tpOwner.email_address));
          setCheckboxObject(prevState => ({
            ...prevState,
            tp_owners: selectedTP_Owners.map(owner => owner.email_address)
          }));
          if (updatedAccordionObject["tp_owners"]) {
            updatedAccordionObject["tp_owners"].isAccordionOpen = true;
          }
        }
        if (i === "tp_status_value" && llmFilterData[i]?.length) {
          setCheckboxObject(prevState => ({
            ...prevState,
            tp_status: llmFilterData[i]
          }));
          if (llmFilterData["tp_status_operator"]) {
            setTpStatusOperator(llmFilterData["tp_status_operator"].toLowerCase());
          }
          if (llmFilterData["tp_status_since"]) {
            setTpStatusSince(llmFilterData["tp_status_since"]);
          }
          if (updatedAccordionObject["tp_status"]) {
            updatedAccordionObject["tp_status"].isAccordionOpen = true;
          }
        }

        if (i === "status" && llmFilterData[i]?.length) {
          setCheckboxObject(prevState => ({
            ...prevState,
            status: llmFilterData[i]
          }));
          if (updatedAccordionObject["status"]) {
            updatedAccordionObject["status"].isAccordionOpen = true;
          }
        }

        if (i === "founder_metro_area" && llmFilterData[i]?.length) {
          setLLmFounderLocation({
            val: llmFilterData[i]
          });
          setCurrentSelection("founder_location_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="founder_location_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "hq_city" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'cities', val: llmFilterData[i].map(word => word[0].toUpperCase() + word.slice(1)) }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="headquarter_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "hq_state" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'states', val: llmFilterData[i].map(word => word[0].toUpperCase() + word.slice(1)) }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="headquarter_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "hq_country" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'countries', val: [llmFilterData[i][0].toUpperCase() + llmFilterData[i].slice(1)] }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
          const founderFilterElement = document.querySelector(
            '.filter-name[data-filter="headquarter_search"]'
          );
          if (founderFilterElement) {
            founderFilterElement.click();
          }
        }

        if (i === "query_filters" && llmFilterData[i]?.length) {
          llmFilterData["query_filters"]?.forEach(item => {
            if (item?.field === "year_founded") {
              let itemArr = item?.value?.split(":");
              let newState = {};

              if (itemArr[0]?.includes("GREATER")) {
                newState.greaterThanValue = itemArr[1];
              }
              if (itemArr[0]?.includes("LESS")) {
                newState.lesserThanValue = itemArr[1];
              }

              setYearFounded(prevState => ({
                ...prevState,
                ...newState
              }));
              if (updatedAccordionObject["year_founded"]) {
                updatedAccordionObject["year_founded"].isAccordionOpen = true;
              }
            }

            if (item?.field.includes("growth")) {
              const growthTag = growthTags.find(tag => item.field.includes(tag.value));
              if (growthTag) {
                let itemArr = item.value.split(":");
                let newState = {
                  displayName: growthTag.displayName,
                  value: growthTag.value,
                  greaterThanValue: "",
                  lessThanValue: ""
                };

                if (itemArr[0].includes("GREATER")) {
                  newState.greaterThanValue = itemArr[1];
                }
                if (itemArr[0].includes("LESS")) {
                  newState.lessThanValue = itemArr[1];
                }

                setSelectedGrowthsData(prevState => {
                  const existingIndex = prevState.findIndex(growth => growth.value === growthTag.value);
                  if (existingIndex !== -1) {
                    const updatedGrowth = {
                      ...prevState[existingIndex],
                      greaterThanValue: newState.greaterThanValue || prevState[existingIndex].greaterThanValue,
                      lessThanValue: newState.lessThanValue || prevState[existingIndex].lessThanValue
                    };
                    return [
                      ...prevState.slice(0, existingIndex),
                      updatedGrowth,
                      ...prevState.slice(existingIndex + 1)
                    ];
                  } else {
                    return [
                      ...prevState,
                      newState
                    ];
                  }
                });
                if (updatedAccordionObject["growth"]) {
                  updatedAccordionObject["growth"].isAccordionOpen = true;
                }
              }
            }

            if (item?.field === "employee_count") {
              processSliderData("employee_count", item, setSliderObject);
              if (updatedAccordionObject["employee_insights"]) {
                updatedAccordionObject["employee_insights"].isAccordionOpen = true;
              }
            }

            if (item?.field === "total_funding_usd") {
              processSliderData("investment_data", item, setSliderObject);
              if (updatedAccordionObject["investment_data"]) {
                updatedAccordionObject["investment_data"].isAccordionOpen = true;
              }
            }
          });
        }

        if (i === "days_of_email_sent" && llmFilterData[i] !== null) {
          setCommunicationFilter(prevState => ({
            ...prevState,
            days_of_email_sent: llmFilterData[i],
            option: "reached_out",
            reachedOut: true,
            notReachedOutValue: llmFilterData[i],
            timeUnit: "days"
          }));
          if (updatedAccordionObject["communication_with_telescope"]) {
            updatedAccordionObject["communication_with_telescope"].isAccordionOpen = true;
          }
        }

        if (i === "has_email_sent" && (llmFilterData[i] === true || llmFilterData[i] === false)) {
          if (llmFilterData[i] === true) {
            setCommunicationFilter(prevState => ({
              ...prevState,
              option: "reached_out",
              reachedOut: true,
              notReachedOutValue: llmFilterData["days_of_email_sent"] || "",
              days_of_email_sent: llmFilterData["days_of_email_sent"] || 0,
              timeUnit: "days"
            }));
          } else if (llmFilterData[i] === false) {
            setCommunicationFilter(prevState => ({
              ...prevState,
              option: "not_reached_out",
              reachedOut: false,
              notReachedOutValue: llmFilterData["days_of_email_sent"] || "",
              days_of_email_sent: llmFilterData["days_of_email_sent"] || 0,
              timeUnit: "days"
            }));
          }
          if (updatedAccordionObject["communication_with_telescope"]) {
            updatedAccordionObject["communication_with_telescope"].isAccordionOpen = true;
          }
        }
      });
    }

    const founderLocationFields = ["founder_city", "founder_state", "founder_country"];
    const headquarterLocationFields = ["hq_city", "hq_state", "hq_country"];
    if (founderLocationFields.every(field => (llmFilterData[field]?.length === 0))) {
      setCurrentSelection("");
    } else if (headquarterLocationFields.every(field => (llmFilterData[field]?.length === 0))) {
      setCurrentSelection("");
    }

    setAccordionObject(updatedAccordionObject);
  };

  const getFiltersPayload = () => {
    let params = "", filteredObject = {};
    // check check box
    if (checkboxObject["tp_owners"]?.length) {
      params =
        `${params && params + "&"}` +
        checkboxObject["tp_owners"]
          .map((value) => `tp_owner_email=${value}`)
          .join("&");

        filteredObject[`TP Owner`] = checkboxObject["tp_owners"]
    }
    if(tpOwnerTagged) {
      params += `${params && "&"}tp_owner_not_tagged=true`;
      if (!filteredObject['No Owner']) {
        filteredObject['No Owner'] = [];
      }
      filteredObject[`No Owner`].push("True");
    }

    if(tpStatusTagged) {
      params += `${params && "&"}tp_status_not_tagged=true`;
      if (!filteredObject['No Status']) {
        filteredObject['No Status'] = [];
      }
      filteredObject[`No Status`].push("True");
    }

    if (checkboxObject["tp_status"]?.length) {
      params =
        `${params && params + "&"}` + `tp_status_operator=${tpStatusOperator}`;
      params =
        `${params && params + "&"}` +
        checkboxObject["tp_status"]
          ?.map((value) => `tp_status_value=${value?.toLowerCase()}`)
          .join("&");

      filteredObject[`TP Status`] = checkboxObject["tp_status"]
      if (!filteredObject['TP Status Operator']) {
        filteredObject['TP Status Operator'] = [];
      }
      filteredObject[`TP Status Operator`].push(tpStatusOperator);
      if (tpStatusOperator === "was" && tpStatusSince) {
        params += `${params && "&"}tp_status_since=${tpStatusSince}`;
      }
    }

    if (hqLocation["countries"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["countries"]?.selected
          ?.map((value) => `hq_country=${value}`)
          .join("&");
      filteredObject["Headquarter Countries"] = hqLocation["countries"].selected;
    }
    if (hqLocation["states"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["states"]?.selected
          ?.map((value) => `hq_state=${value}`)
          .join("&");
        filteredObject["Headquarter States"] = hqLocation["states"].selected;
    }
    if (hqLocation["cities"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["cities"]?.selected
          ?.map((value) => `hq_city=${value}`)
          .join("&");
      filteredObject["Headquarter Cities"] = hqLocation["cities"].selected;
    }

    if (checkboxObject["status"]?.length) {
      params =
        `${params && params + "&"}` +
        checkboxObject["status"]?.map((value) => `status=${value}`).join("&");
        filteredObject[`Status`] = checkboxObject["status"]
    }
    const employeeCount = sliderObject["employee_count"];
    if (employeeCount) {
      const hasValidTags = employeeCount.selectedTags?.some(tag => tag.value !== undefined && String(tag.value)?.trim() !== "");
      if (hasValidTags) {
        if (!filteredObject['Employee Insights']) {
          filteredObject['Employee Insights'] = [];
        }
        employeeCount.selectedTags?.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value?.trim() !== "") {
            params += `${params ? "&" : ""}employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Employee Insights'].push(`>= ${tag.value}`);
          } else if (tag?.operator === "lessThan" && tag?.value.trim() !== "") {
            params += `${params ? "&" : ""}employee_count=LESS_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Employee Insights'].push(`<= ${tag.value}`);
          } else if (tag?.minCount !== undefined && tag?.maxCount !== undefined) {
            params += `${params ? "&" : ""}employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.minCount}`;
            params += `&employee_count=LESS_THAN_OR_EQUAL_TO:${tag.maxCount}`;
            filteredObject['Employee Insights'].push(`${tag.minCount} - ${tag.maxCount}`);
          } else if (tag?.value === "below_20") {
            params += `${params ? "&" : ""}employee_count=LESS_THAN_OR_EQUAL_TO:20`;
            filteredObject['Employee Insights'].push(`<= 20`);
          } else if (tag?.value === "above_1000") {
            params += `${params ? "&" : ""}employee_count=GREATER_THAN_OR_EQUAL_TO:1000`;
            filteredObject['Employee Insights'].push(`>= 1000`);
          }
        });
      }
    }

    const selectedCountry = selectedEmployeeCountry?.data || [];
    const hasValidCountryCountTags = countryEmployeeCount.employee_count?.some(tag => tag.value !== undefined && String(tag.value)?.trim() !== "");
    if (hasValidCountryCountTags && selectedCountry?.length) {
      params += `${params && "&"}country_name_employee_count=${selectedCountry[0]}`;
      if (!filteredObject["Employee Count of Country"]) {
        filteredObject["Employee Count of Country"] = [];
      }
      filteredObject["Employee Count of Country"].push(selectedCountry[0]);
  
      if (!filteredObject["Country Employee Count"]) {
        filteredObject["Country Employee Count"] = [];
      }
      
      countryEmployeeCount?.employee_count?.forEach((tag) => {
        if (tag?.operator === "greaterThan" && tag?.value?.trim() !== "") {
          params += `${params ? "&" : ""}country_employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.value}`;
          filteredObject["Country Employee Count"].push(`>= ${tag.value}`);
        } else if (tag?.operator === "lessThan" && tag?.value.trim() !== "") {
          params += `${params ? "&" : ""}country_employee_count=LESS_THAN_OR_EQUAL_TO:${tag.value}`;
          filteredObject["Country Employee Count"].push(`<= ${tag.value}`);
        } else if (tag?.minCount !== undefined && tag?.maxCount !== undefined) {
          params += `${params ? "&" : ""}country_employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.minCount}`;
          params += `&country_employee_count=LESS_THAN_OR_EQUAL_TO:${tag.maxCount}`;
          filteredObject["Country Employee Count"].push(`${tag.minCount} - ${tag.maxCount}`);
        } else if (tag?.value === "below_20") {
          params += `${params ? "&" : ""}country_employee_count=LESS_THAN_OR_EQUAL_TO:20`;
          filteredObject["Country Employee Count"].push(`<= 20`);
        } else if (tag?.value === "above_1000") {
          params += `${params ? "&" : ""}country_employee_count=GREATER_THAN_OR_EQUAL_TO:1000`;
          filteredObject["Country Employee Count"].push(`>= 1000`);
        }
      });
    }

    const investmentData = sliderObject["investment_data"];
    if (investmentData) {
      if (investmentData.selectedTags?.length) {
        if (!filteredObject['Investment Data']) {
          filteredObject['Investment Data'] = [];
        }
        investmentData.selectedTags.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value) {
            params += `${params ? "&" : ""}total_funding_usd=GREATER_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Investment Data'].push(`>= ${tag.value}`);
          } else if (tag?.operator === "lessThan" && tag?.value) {
            params += `${params ? "&" : ""}total_funding_usd=LESS_THAN_OR_EQUAL_TO:${tag.value}`;
            filteredObject['Investment Data'].push(`<= ${tag.value}`);
          }
        });
        if (selectedTotalFundingData.trueFalse) {
          params += `${params && "&"}total_funding_usd_no_data=${selectedTotalFundingData.trueFalse}`;
        }
      }
    }
    // Growth
    selectedGrowthsData.forEach((selectedTag) => {
      const growthPeriod = selectedTag.value.split('_')[1];
      let growthFilter = '';

      if (selectedTag.greaterThanValue) {
        params += `${params && "&"}` + `${selectedTag.value}=GREATER_THAN_OR_EQUAL_TO:${selectedTag.greaterThanValue}`;
        growthFilter = `${growthPeriod}: >= ${selectedTag.greaterThanValue}%`;
        if (!filteredObject['Growth']) {
          filteredObject['Growth'] = [];
        }
        filteredObject['Growth'].push(growthFilter);
      }
      if (selectedTag.lessThanValue) {
        params += `${params && "&"}` + `${selectedTag.value}=LESS_THAN_OR_EQUAL_TO:${selectedTag.lessThanValue}`;
        growthFilter = `${growthPeriod}: <= ${selectedTag.lessThanValue}%`;
        if (!filteredObject['Growth']) {
          filteredObject['Growth'] = [];
        }
        filteredObject['Growth'].push(growthFilter);
      }
    });
    if (selectedGrowthsTag?.length) {
      params = `${params && params + "&"}` + selectedGrowthsTag?.map(i => `growth_tag=${i?.value}`).join('&');
      if (!filteredObject['Growth Tags']) {
        filteredObject['Growth Tags'] = [];
      }
      selectedGrowthsTag.forEach(tag => {
        if (tag?.displayName) {
          filteredObject['Growth Tags'].push(tag.displayName);
        }
      });
    }
    // Year Founded
    if (yearFounded.greaterThanValue || yearFounded.lesserThanValue) {
      if (!filteredObject['Year Founded']) {
        filteredObject['Year Founded'] = [];
      }
      if (yearFounded.greaterThanValue) {
        params +=
          `${params && "&"}` +
          `year_founded=GREATER_THAN_OR_EQUAL_TO:${yearFounded.greaterThanValue}`;
        filteredObject['Year Founded'].push(`>= ${yearFounded.greaterThanValue}`);
      }
      if (yearFounded.lesserThanValue) {
        params +=
          `${params && "&"}` +
          `year_founded=LESS_THAN_OR_EQUAL_TO:${yearFounded.lesserThanValue}`;
        filteredObject['Year Founded'].push(`<= ${yearFounded.lesserThanValue}`);
      }
    }
    // Founder Location
    if ( selectedFounderLocation?.data?.length > 0) {
      if (!filteredObject["Founder Metro Area"]) {
        filteredObject["Founder Metro Area"] = [];
      }
        params += `${params && "&"}` + selectedFounderLocation.data.map(obj => `founder_metro_area=${obj?.founder_metro_value}`).join("&");
        selectedFounderLocation?.data?.forEach(area=> {
          if (area?.founder_metro_display) {
            filteredObject['Founder Metro Area'].push(area.founder_metro_display);
          }
        });
      
      
    }
    // Is SaaS Filter
    if (saasFilter?.isSaasFilterApplied) {
      if (!filteredObject["Filter by Saas"]) {
        filteredObject["Filter by Saas"] = [];
      }
      params += `${params && "&"}is_saas=${true}`;
      filteredObject["Filter by Saas"].push(`True`);
    }
    // Histor Visited Filter
    if (visitHistoryFilter.days_of_email_sent > 0) {
      params += `${params && "&"}days_of_website_history=${visitHistoryFilter.days_of_email_sent}`;
      if (!filteredObject["Visit History"]) {
        filteredObject["Visit History"] = [];
      }
      filteredObject["Visit History"].push(`${visitHistoryFilter.days_of_email_sent} days ago`);
    }

    // Communication Filter
    if (communicationFilter.option === "reached_out" && communicationFilter.reachedOut) {
      if (!filteredObject["Communication with Telescope"]) {
        filteredObject["Communication with Telescope"] = [];
      }
      params += `${params && "&"}has_email_sent=true`;
      filteredObject["Communication with Telescope"].push("Reached Out");
    } else if (communicationFilter.option === "not_reached_out" && !communicationFilter.reachedOut) {
      if (!filteredObject["Communication with Telescope"]) {
        filteredObject["Communication with Telescope"] = [];
      }
      params += `${params && "&"}has_email_sent=false`;
      filteredObject["Communication with Telescope"].push("Not Reached Out");
    }
    if (communicationFilter.days_of_email_sent > 0) {
      params += `${params && "&"}days_of_email_sent=${communicationFilter.days_of_email_sent}`;
      if (!filteredObject["Communication with Telescope"]) {
        filteredObject["Communication with Telescope"] = [];
      }
      filteredObject["Communication with Telescope"].push(`${communicationFilter.days_of_email_sent} days ago`);
    }
    // LLM Filter UUID
    if (llmFilterData && llmFilterData?.uuid) {
      params += `${params && "&"}` + `discovery_llm_search_uuid=${llmFilterData?.uuid}`;
    }

    // List Options
    if (selectedListOptions?.length > 0) {
      if (activeTab) {
        params +=`${params && "&"}`+`workboard_id=${activeTab}`;
      }
    }
    // Newsletter Appeared
    if (newsletterForm?.isNewsletterFilterApplied) {
      params = `${params && params + "&"}is_in_newsletter=${true}`;
      if (!filteredObject["Is In Newsletter"]) {
        filteredObject["Is In Newsletter"] = [];
      }
      filteredObject["Is In Newsletter"].push(`True`);
      if (newsletterForm?.dates?.length && newsletterForm.dates.every(value => value !== "")) {
        params = `${params && params + "&"}newsletter_date_from=${newsletterForm.dates[0].format("YYYY-MM-DD")}&newsletter_date_to=${newsletterForm.dates[1].format("YYYY-MM-DD")}`;
        filteredObject["Newsletter Appeared"] = [`${newsletterForm.dates[0].format("MMM DD, YYYY")} - ${newsletterForm.dates[1].format("MMM DD, YYYY")}`];
      }
      if (newsletterForm.newsLetterCheckbox.length > 0) {
        newsletterForm.newsLetterCheckbox.forEach((type) => {
          params += `&newsletter_type=${type.value}`;
        });
        filteredObject["Newsletter Type"] = newsletterForm.newsLetterCheckbox.map((t) => t.value);
      }
    }
    // Explore Datasource
    if (selectedDataSource !== null && selectedDataSource?.datasource_type !== "orion") {
      params += `${params && "&"}explore_datasource=${selectedDataSource.datasource_type}`;
    }
    return { params, filteredObject };
  };

  const formatFiltersPayload = (payload) => {
    const params = new URLSearchParams(payload);
    const formattedPayload = {};

    params.forEach((value, key) => {
      const formattedKey = key?.replace(/_/g, ' ')?.replace(/(\b\w)/g, char => char?.toUpperCase());

      if (formattedPayload?.hasOwnProperty(formattedKey)) {
        if (!Array?.isArray(formattedPayload[formattedKey])) {
          formattedPayload[formattedKey] = [formattedPayload[formattedKey]];
        }
        formattedPayload[formattedKey]?.push(value);
      } else {
        formattedPayload[formattedKey] = value;
      }
    });

    return formattedPayload;
  };

  const loadFiltersFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    let filters = {};
    
    if (params?.size > 0) {
      setIsLoading(true);
    }
    params.forEach((value, key) => {
      if (!filters[key]) {
        filters[key] = [];
      }
      filters[key].push(value);
    });
  
    return filters;
  };

  const populateFiltersFromURL = (filters) => {
    let populatedFilters = {};
    Object.keys(filters).forEach((key) => {
      if (key === "workboard_id") {
        setIsActiveBtn('list');
        setRightSideContent('allLists');
        const listObj = listOptions?.filter((obj) => obj?.workboard_id === filters[key][0]) || [];
        setActiveTab(filters[key][0]);
        setSelectedListOptions(listObj);
      }
      if (key === "tp_owner_email") {
        const tpOwnersFiltered = tpOwners?.filter((owner) => filters[key].includes(owner.email_address))?.map((owner) => owner.email_address) || [];
        if (tpOwnersFiltered.length) {
          populatedFilters["tp_owners"] = tpOwnersFiltered;
          setCheckboxObject((prevCheckboxObject) => ({
              ...prevCheckboxObject,
              tp_owners: tpOwnersFiltered,
          }));
        }
      } else if (key === "tp_status_value") {
        if (tpStatusOptions?.length) { 
          const filterValues = filters[key]?.map(val => val?.toUpperCase());
          const tpStatusFiltered = tpStatusOptions?.filter(status => filterValues?.includes(status)) || [];
          if (tpStatusFiltered.length) {
            populatedFilters["tp_status"] = tpStatusFiltered;
            setCheckboxObject((prevCheckboxObject) => ({
                ...prevCheckboxObject,
                tp_status: tpStatusFiltered,
            }));
          }
        }
      } else if (key === "tp_status_operator") {
        setTpStatusOperator(filters[key][0]);
      } else if (key === "tp_status_not_tagged") {
        setTpStatusTagged(filters[key][0])
      } else if (key === "tp_owner_not_tagged") {
        setTpOwnerTagged(filters[key][0])
      } else if (key === "status") {
        const statusFiltered = status?.filter((s) => filters[key]?.includes(s?.toLowerCase())) || [];
        if (statusFiltered.length) {
          populatedFilters["status"] = statusFiltered;
          setCheckboxObject((prevCheckboxObject) => ({
              ...prevCheckboxObject,
              status: statusFiltered,
          }));
        }
      } else if (key === 'growth_tag') {
        const growthTagsFiltered = growthsData?.filter((tag) => filters[key].includes(tag.value)) || [];
        if (growthTagsFiltered.length) {
          populatedFilters["growth_tags"] = growthTagsFiltered;
          setSelectedGrowthsTag((prevGrowthTags) => [
            ...prevGrowthTags,
            ...growthTagsFiltered,
          ]);
        }
      } else if (key === "year_founded") {
        const greaterThan = filters[key]?.find((val) => val?.includes("GREATER_THAN_OR_EQUAL_TO:")) || "";
        const lesserThan = filters[key]?.find((val) => val?.includes("LESS_THAN_OR_EQUAL_TO:")) || "";
        setYearFounded({
          greaterThanValue: greaterThan?.replace("GREATER_THAN_OR_EQUAL_TO:", "")?.trim(),
          lesserThanValue: lesserThan?.replace("LESS_THAN_OR_EQUAL_TO:", "")?.trim(),
        });
      } else if (key?.startsWith("growth_")) {
        const greaterThan = filters[key]?.find((val) => val?.includes("GREATER_THAN_OR_EQUAL_TO:")) || "";
        const lesserThan = filters[key]?.find((val) => val?.includes("LESS_THAN_OR_EQUAL_TO:")) || "";
        const formattedGrowthFilter = {
          value: key,
          displayName: key?.replace(/_/g, " ")?.replace(/\b\w/g, (l) => l?.toUpperCase()),
          greaterThanValue: greaterThan?.replace("GREATER_THAN_OR_EQUAL_TO:", "")?.trim(),
          lessThanValue: lesserThan?.replace("LESS_THAN_OR_EQUAL_TO:", "")?.trim(),
        };
        setSelectedGrowthsData((prevSelected) => [
          ...prevSelected,
          formattedGrowthFilter
        ]);
      } else if (key === "is_in_newsletter") {
        setNewsletterForm((prev) => ({
          ...prev,
          isNewsletterFilterApplied: filters[key][0] === "true",
        }));
      } else if (key === "newsletter_date_from" || key === "newsletter_date_to") {
        setNewsletterForm((prev) => ({
          ...prev,
          isNewsletterFilterApplied: true,
          startDate:
            key === "newsletter_date_from"
              ? moment(filters[key][0])
              : prev.startDate,
          endDate:
            key === "newsletter_date_to"
              ? moment(filters[key][0])
              : prev.endDate,
          dates:
            key === "newsletter_date_from"
              ? [moment(filters[key][0]), prev.endDate]
              : [prev.startDate, moment(filters[key][0])],
        }));
      } else if (key === "newsletter_type") {
        const newsletterCheckboxFiltered =
          quipTypes?.filter((type) => filters[key]?.includes(type.value)) || [];
          setNewsletterForm((prev) => ({
          ...prev,
          isNewsletterFilterApplied: true,
          newsLetterCheckbox: newsletterCheckboxFiltered,
        }));
      } else if (key === "total_funding_usd") {
        const selectedTags = filters[key].map((filterValue) => {
          const [operator, value] = filterValue.split(":");
          return {
            value: value.trim(),
            operator: operator === "GREATER_THAN_OR_EQUAL_TO" ? "greaterThan" : "lessThan",
          };
        });
        setSliderObject((prevSliderObject) => ({
          ...prevSliderObject,
          investment_data: {
            ...prevSliderObject.investment_data,
            selectedTags,
          },
        }));
      } else if (key === "total_funding_usd_no_data") {
        const noDataValue = filters[key][0] === "true";
        setSelectedTotalFundingData((prev) => ({
          ...prev,
          trueFalse: noDataValue.toString(), 
        }));
      } else if (key === "days_of_website_history") {
        const daysValue = parseInt(filters[key][0], 10) || 0;
        setVisitHistoryFilter((prevState) => ({
          ...prevState,
          visitHistoryValue: daysValue, 
          timeUnit: "days",
          days_of_email_sent: daysValue,
        }));
      } else if (key === "days_of_email_sent" || key === "has_email_sent") {
        const reachedOut = filters.has_email_sent?.[0] === "true";
        const daysValue = parseInt(filters.days_of_email_sent?.[0], 10) || 0;
        setCommunicationFilter({
          option: reachedOut ? "reached_out" : "not_reached_out",
          reachedOut,
          notReachedOutValue: daysValue,
          timeUnit: "days",
          days_of_email_sent: daysValue,
        });
      } else if (key === "founder_metro_area") {
        const selectedFounderLocations = filters[key];
        const matchingFounderLocations = founder_metro_areas?.filter((location) => selectedFounderLocations.includes(location.founder_metro_value));
        if (matchingFounderLocations?.length) {
          setSelectedFounderLocation({
              data: matchingFounderLocations, 
          });
        }
      } else if (key === "hq_country") {
        setHqLocation(prev => ({
          ...prev,
          countries: {
            ...prev.countries,
            isHidden: true,
            option: countries,
            selected: filters[key]
          },
          states: {
            ...prev.states,
            isHidden: true
          },
          cities: {
            ...prev.cities,
            isHidden: true
          }
        }))
      } else if (key === "hq_state") {
        setHqLocation(prev => ({
          ...prev,
          states: {
            ...prev.states,
            isHidden: true,
            option: states,
            selected: filters[key]
          },
          countries: {
            ...prev.countries,
            isHidden: true
          },
          cities: {
            ...prev.cities,
            isHidden: true
          }
        }))
      } else if (key === "hq_city") {
        setHqLocation(prev => ({
          ...prev,
          cities: {
            ...prev.cities,
            isHidden: true,
            option: cities,
            selected: filters[key]
          },
          countries: {
            ...prev.countries,
            isHidden: true
          },
          states: {
            ...prev.states,
            isHidden: true
          }
        }))
      } else if (key === "employee_count") {
        const selectedTags = filters[key].map((filterValue) => {
          const [operator, value] = filterValue.split(":");
          return {
            value: value.trim(),
            operator: operator === "GREATER_THAN_OR_EQUAL_TO" ? "greaterThan" : "lessThan",
          };
        });
        setSliderObject((prevSliderObject) => ({
          ...prevSliderObject,
          employee_count: {
            ...prevSliderObject.employee_count,
            selectedTags,
          },
        }));
      } else if (key === "country_employee_count") {
        const selectedCountry = filters[key];
        const matchingCountry = countries?.filter((country) => selectedCountry?.includes(country));
        if (matchingCountry?.length) {
          setSelectedEmployeeCountry({
            data: matchingCountry,
          });
        }
        const selectedTags = filters[key]?.map((filterValue) => {
          const [operator, value] = filterValue?.split(":");
          return {
            value: value?.trim(),
            operator: operator === "GREATER_THAN_OR_EQUAL_TO" ? "greaterThan" : "lessThan",
          };
        }) || [];
        setCountryEmployeeCount((prev) => ({
          ...prev,
          employee_count: selectedTags,
        }));
      } else if (key === "country_name_employee_count") {
        const selectedCountries = filters[key];
        if (selectedCountries?.length) {
          setSelectedEmployeeCountry({
            data: selectedCountries,
          });
        }
      } else if (key === "explore_datasource") {
        const selectedSource = allDataSources?.results?.find((source) => source.datasource_type === filters[key][0]);
        if (selectedSource) {
          setSelectedDataSource(selectedSource);
        }
      }
    });
    return populatedFilters;
  };

  useEffect(() => {
    if (filtersFromURL && tpOwners?.length && tpStatusOptions?.length && status?.length && growthsData?.length && listOptions?.length) {
      populateFiltersFromURL(filtersFromURL);
    }
  }, [filtersFromURL, tpOwners, tpStatusOptions, status, growthsData, listOptions]);

  const handleSubmit = async () => {
    const hasValue = checkLength();
    setFiltersApplied(hasValue);
    if (!hasValue) {
      setHasResults(false);
      setShowEnterKeyPrompt(false);
      dispatch({ type: actionTypes.RESET_DISCOVERY_FILTERS, data: {} })
      if(removedClicked) {
        window.history.pushState({}, "", '/explore');
        removedClicked = false;
      }
      return;
    }
    if (!llmInputFilterClicked) {
      dispatch({ type: actionTypes.RESET_LLM_FILTERS })
    }
    llmInputFilterClicked = false;
    const { params: filtersPayload, filteredObject } = await getFiltersPayload(); 
    const newUrl = `?${filtersPayload}`;
  
    window.history.pushState({}, "", newUrl);
    // Storing the Filters Payload for DiscoveryTable component
    dispatch(setDiscoveryFiltersPayload(filtersPayload)); 
  
    // // Construct the URL with the combined filters
    const apiUrlWithCombinedFilters = filtersPayload
      ? `?${filtersPayload}`
      : "";

    const formattedFilters = formatFiltersPayload(filtersPayload);
    amplitude.logEvent(`Discovery Performed`, { ...formattedFilters });
    try {
      setIsLoading(true);
      if (cancelToken) {
        cancelToken.cancel("operation cancelled due to new req")
      }
      const CancelToken = axios.CancelToken;
      cancelToken = CancelToken.source();
      // Make the API call with the constructed URL
      setAppliedFilterString(apiUrlWithCombinedFilters)
      const url = `${getAddress() + `/discovery${apiUrlWithCombinedFilters}`}`;
      const result = await getDiscoveryCompanies(url, cancelToken)(dispatch);

      if (result.status === "success") {
        handleRightSideView("handleSubmit", result?.data?.total_count || result?.data?.results?.length || 0);
        const total_count = result?.data?.total_count;
        dispatch(setAppliedFilters({ filteredObject, total_count }));
        if(selectedListOptions?.length > 0) { 
          dispatch({
            type: actionTypes.SET_SELECTED_LIST_OPTION,
            payload: {
              listId: activeTab,
              data: result?.data,
              filterString: apiUrlWithCombinedFilters,
              filteredObject: filteredObject
            },
          });
        }
        setIsLoading(false);
        setLLMFilters({
          ...llmFilter,
          enteredValue: "",
        })
        if (result?.data?.results?.length > 0) {
          setHasResults(true);
        } else {
          setHasResults(false);
        }
      } else {
        // setIsLoading(false);
        setError(result.errMsg);
        setHasResults(false);
        if (result.status === 'ERR_CANCELED') {
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const handleRightSideView = (type, total_count) => {
    if(isActiveBtn === 'list') {
      if (total_count === 0) {
        return;
      }
      if(rightSideContentRef.current === 'allLists') {
        setRightSideContent("slider")
      }
      if(total_count > 0 && rightSideContentRef.current === "") {
        setRightSideContent("slider")
      }
    } else {
      if(total_count > 0 && (rightSideContentRef.current === "" || rightSideContentRef.current === "allDataSources") || (rightSideContentRef.current === "filters" && hasOwnerData)) {
        setRightSideContent("slider");
        hasOwnerData = false;
      } 
      if(total_count > 0 && selectedDataSource?.datasource_type === "multi_founders" && isInitialCall) {
        setRightSideContent("slider");
        isInitialCall = false;
      }
    }

  }

  const filterDisplayNames = (searchTerm) => {
    return result.filter((item) => {
      return item?.display_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
  };

  const hasNewsletterLength =  () => { 
    const hasValue = (form.dates.length > 0 && form.dates.every(value => value !== "")) || selectedQuipsType?.length > 0;
    return hasValue;
  }

  const checkLength = () => {
    const hasValue =
      Object.entries(checkboxObject).some(([key, val]) => val.length) ||
      sliderObject &&
        Object.values(sliderObject)
          ?.map(
            ({ greaterThanThumbPosition, lesserThanThumbPosition, selectedTags }) => {
              return (
                (selectedTags && selectedTags.some(i => i?.value)) ||
                greaterThanThumbPosition > 0 ||
                lesserThanThumbPosition > 0
              )
            }
          )
          .some(value => value) ||
      Object.entries(preTags).some(
        ([key, val]) =>
          val.length ||
          Object.entries(hqLocation).some(
            ([key, val]) => val["selected"]?.length
          )
      ) || (tpStatusTagged) || (tpOwnerTagged) ||
      (yearFounded.greaterThanValue > 0 || yearFounded.lesserThanValue > 0) ||
      selectedFounderLocation.data?.length > 0 ||
      saasFilter?.isSaasFilterApplied ||
      selectedListOptions?.length > 0 ||
      (communicationFilter.reachedOut || communicationFilter.option !== "") ||
      (communicationFilter.days_of_email_sent > 0) ||
      (visitHistoryFilter.days_of_email_sent > 0) ||
      (newsletterForm.dates?.length > 0 && newsletterForm.dates?.every(value => value !== "")) ||
      (newsletterForm.newsLetterCheckbox?.length > 0) ||
      (selectedEmployeeCountry?.data?.length > 0 && countryEmployeeCount?.employee_count?.some((tag) => tag.value && typeof tag.value === "string" && tag.value.trim() !== "")) ||
      (selectedDataSource !== null && selectedDataSource?.datasource_type !== "orion") ||
      selectedGrowthsData.some(
        (tag) => tag.greaterThanValue || tag.lessThanValue
      ) || selectedGrowthsTag?.length;

    return hasValue;
  };

  const handleCheckboxChange = (value, isChecked, serverValue, arrayVal) => {
    setCheckboxObject((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedCheckboxObject = { ...prevCheckboxObject };

      if (value === "Select All") {
        updatedCheckboxObject[serverValue] = isChecked
          ? serverValue === "tp_owners"
            ? arrayVal.map((o) => o.email_address)
            : arrayVal.map((o) => o)
          : [];
      } else {
        // Check if serverValue exists in the checkboxObject
        if (updatedCheckboxObject.hasOwnProperty(serverValue)) {
          // Update the array based on the condition
          updatedCheckboxObject[serverValue] = isChecked
            ? serverValue === "tp_owners"
              ? [...updatedCheckboxObject[serverValue], value.email_address]
              : [...updatedCheckboxObject[serverValue], value]
            : serverValue === "tp_owners"
              ? updatedCheckboxObject[serverValue].filter(
                (checkedItem) => checkedItem !== value.email_address
              )
              : updatedCheckboxObject[serverValue].filter(
                (checkedItem) => checkedItem !== value
              );
        }
      }

      const isLastCheckboxUncheck =
        updatedCheckboxObject[serverValue]?.length === 0;

      if (isLastCheckboxUncheck) {
        setAccordionObject((prevAccordion) => {
          const updatedAccordion = { ...prevAccordion };
          const keyToUpdate = Object.keys(updatedAccordion).find(
            (key) => updatedAccordion[key].name === serverValue
          );

          if (keyToUpdate) {
            updatedAccordion[keyToUpdate].isAccordionOpen = false;
          }
          return updatedAccordion;
        });
      }

      return updatedCheckboxObject;
    });
  };

  const handleRemoveFilterGroup = (filterValue) => {
    if (filterValue === "headquarter_search") {
      setHqLocation({});
      setFilteredLocationObject({ keys: "all", value: "all" });
    } else if (filterValue === "year_founded") {
      setYearFounded({ greaterThanValue: "", lesserThanValue: "" });
    } else if (filterValue === "communication_with_telescope") {
      setCommunicationFilter({ option: "", days_of_email_sent: 0 });
    } else if (filterValue === "visit_history") {
      setVisitHistoryFilter({
        days_of_email_sent: 0,
        visitHistoryValue: "",
        timeUnit: "days"
      })
    } else if (filterValue === "investment_data") {
      setSliderObject((prevSliderObject) => {
        const updated = { ...prevSliderObject };
        updated["investment_data"].selectedTags = [];
        return updated;
      });
    } else if (filterValue === "employee_insights") {
      setSelectedEmployeeCountry({ data: [] });
      setSliderObject((prevSliderObject) => {
        const updated = { ...prevSliderObject };
        updated["employee_count"].selectedTags = [];
        return updated;
      });
      setCountryEmployeeCount({});
    } else if (filterValue === "growth") {
      setSelectedGrowthsData([]);
    } else if (filterValue === "growth_tags") {
      setSelectedGrowthsTag([]);
    } else if (filterValue === "founder_location_search") {
      setSelectedFounderLocation({ data: [] });
    } else if (filterValue === "newsletter_sender") {
      setSelectedQuipsType([]);
    } else if (filterValue === "from_to_date") {
      setForm({
        startDate: "",
        endDate: "",
        dates: [],
      })  
    } else if (filterValue === "newsletter") {
      setNewsletterForm({
        startDate: "",
        endDate: "",
        dates: [],
        isNewsletterFilterApplied: false,
        newsLetterCheckbox: []
      })  
    } else {
      setCheckboxObject((prevCheckboxObject) => {
        const updated = { ...prevCheckboxObject };
        updated[filterValue] = [];
        return updated;
      }); 

      if (filterValue === "tp_owners" && tpOwnerTagged) {
        setTpOwnerTagged(false);
      }
      if (filterValue === "tp_status" && tpStatusTagged) {
        setTpStatusTagged(false);
      }
    }
  }

  const openHistorySlider = () => {
    setHistorySlider(!historySlider);
  }

  const handleCloseHistorySlider = () => {
    setHistorySlider(false);
  };

  const renderAppliedFilters = () => {
    return (
      <div className="internal-container d-flex mb-2 align-items-center">
          <>
            <span className={isLoading || !(llmFilterData && Object.keys(llmFilterData)?.length > 0 || filtersApplied)  ? "clear-all-button disabled" : "clear-all-button"} onClick={clearSearch}>
              <div className="font-weight-bold clear-top-bar">
                X Clear filters
              </div>
            </span>
            <span className={isLoading || !(llmFilterData && Object.keys(llmFilterData)?.length > 0 || filtersApplied)  ? "view-search-history disabled" : "view-search-history" } onClick={openHistorySlider}>
              <div className="font-weight-bold view-search-button">
                <EyeIcon style={{ marginRight: '6px' }} /> View search history
              </div>
            </span>
          </>
      </div>
    );
  };

  const handleCloseAlert = () => {
    setShowEnterKeyPrompt(false);
  };

  const handleResetAll = () => {
    setFiltersApplied(false);
    // Reset search details filters
    dispatch({ type: actionTypes.RESET_APPLIED_FILTERS })
    // Clear checkboxes
    if (Object.entries(checkboxObject).some(([key, val]) => val.length)) {
      setCheckboxObject((prevCheckboxObject) => {
        const updatedCheckboxObject = { ...prevCheckboxObject };

        for (const key in updatedCheckboxObject) {
          if (updatedCheckboxObject.hasOwnProperty(key)) {
            updatedCheckboxObject[key] = [];
          }
        }
        return updatedCheckboxObject;
      });
      setTpStatusOperator('is');
    }
    if (
      Object.values(sliderObject).some(
        ({ selectedTags }) => selectedTags?.length > 0
      )
    ) {
      setSliderObject((prevSliderObject) => {
        const updatedSliderObject = { ...prevSliderObject };

        for (const key in updatedSliderObject) {
          if (updatedSliderObject.hasOwnProperty(key)) {
            updatedSliderObject[key] = {
              greaterThanValue: "",
              lessThanValue: "",
              selectedTags: [],
              symbol: updatedSliderObject[key].symbol,
              maxValue: updatedSliderObject[key].maxValue,
            };
          }
        }
        return updatedSliderObject;
      });
    }

    if (Object.entries(preTags).some(([key, val]) => val.length)) {
      setPreTags((prevCheckboxObject) => {
        const updatedCheckboxObject = { ...prevCheckboxObject };

        for (const key in updatedCheckboxObject) {
          if (updatedCheckboxObject.hasOwnProperty(key)) {
            updatedCheckboxObject[key] = [];
          }
        }
        return updatedCheckboxObject;
      });
    }

    if (selectedGrowthsData?.length) {
      setSelectedGrowthsData([]);
    }
    if (selectedGrowthsTag?.length) {
      setSelectedGrowthsTag([])
    }
    if (yearFounded?.greaterThanValue > 0 || yearFounded?.lesserThanValue > 0) {
      setYearFounded({
        greaterThanValue: "",
        lesserThanValue: "",
      });
    }
    if (
      Object.entries(hqLocation).some(([key, val]) => val["selected"]?.length)
    ) {
      setFilteredLocationObject({
        keys: "all",
        value: "all",
      });
    }
    if (selectedFounderLocation?.data?.length > 0) {
      setSelectedFounderLocation({
        data: [],
      })
    }
    if (selectedEmployeeCountry?.data?.length > 0) {
      setSelectedEmployeeCountry({
        data: [],
      })
    }
    if (countryEmployeeCount?.employee_count?.length > 0) {
      setCountryEmployeeCount({})
    }
    if (communicationFilter?.option === "reached_out" || communicationFilter?.option === "not_reached_out" || communicationFilter?.days_of_email_sent > 0) {
      setCommunicationFilter({
        option: "",
        reachedOut: false,
        notReachedOutValue: "",
        timeUnit: "days",
        days_of_email_sent: 0,
      })
    }
    if (visitHistoryFilter?.days_of_email_sent > 0) {
      setVisitHistoryFilter({
        days_of_email_sent: 0,
        visitHistoryValue: "",
        timeUnit: "days"
      })
    }
    if (saasFilter?.isSaasFilterApplied) {
      setSaasFilter({
        isSaasFilterApplied: false,
      });
    }
    if (form?.dates?.length) {
      setForm({
        startDate: "",
        endDate: "",
        dates: [],
      })
    }
    if (selectedQuipsType?.length > 0) {
      setSelectedQuipsType([]);
    }
    if (newsletterForm?.isNewsletterFilterApplied || newsletterForm?.dates?.length || newsletterForm?.newsLetterCheckbox?.length) {
      setNewsletterForm({
        ...newsletterForm,
        startDate: "",
        endDate: "",
        dates: [],
        isNewsletterFilterApplied: false,
        newsLetterCheckbox: []
      })
    }
    if (hqLocation?.length > 0) {
      setHqLocation({});
    }
    if (selectedTotalFundingData?.trueFalse === 'true') {
      setSelectedTotalFundingData({ trueFalse: 'false' });
    }
    setOpenItems([]);
    setTpOwnerTagged(false);
    setTpStatusTagged(false);
  };

  const clearSearch = () => {
    setIsLoading(false);
    setLLMFilters({
      searchTerm: "",
    })
    dispatch({
      type: actionTypes.RESET_LLM_FILTERS
    })
    handleResetAll();
    setRightSideContent("filters");
    setSelectedDataSource(null);
    setShowEnterKeyPrompt(false);
  }

  const handleTpStatusOperatorChange = (operator) => {
    setTpStatusTagged(false);
    setTpStatusOperator(operator);
  };

  const handleTPTaggedClick = (type) => {
    if(type === "tp_owners") {
      setTpOwnerTagged(!tpOwnerTagged);
    }

    if(type === "tp_status") {
      if(!tpStatusTagged) {
        setCheckboxObject(prevState => ({
          ...prevState,
          tp_status: []
        }))
      }
      setTpStatusTagged(!tpStatusTagged);
    }
  }

  const renderTopButtons = (item) => {
    switch (item) {
      case "tp_status":
        return (
          <div className="flex-wrap mb-3">
            <span
              className={
                (tpStatusOperator === "is" && !tpStatusTagged)
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTpStatusOperatorChange("is")}
            >
              Currently is
            </span>
            <span
              className={
                (tpStatusOperator === "was" && !tpStatusTagged) 
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTpStatusOperatorChange("was")}
            >
              Status was
            </span>
            <span
              className={
                tpStatusTagged
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTPTaggedClick("tp_status")}
            >
              No Status
            </span>
          </div>
        );
      case "tp_owners":
        return (
          <div className="flex-wrap mb-3">
            <span
              className={
                tpOwnerTagged
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTPTaggedClick("tp_owners")}
            >
              No Owner
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  const handleFounderChange = (founderObj) => {
    setSelectedFounderLocation({
      ...selectedFounderLocation,
      data: founderObj
    })
    setLLmFounderLocation({});
  }

  const handleEmployeeCountryChange = (country) => {
    setSelectedEmployeeCountry({
      ...selectedEmployeeCountry,
      data: country
    })

    setCountryEmployeeCount((prevData) => {
      if (!Object.keys(prevData).length) {
        return {
          employee_count: [
            {
              displayName: "20-50",
              maxCount: "50",
              minCount: "20",
              operator: ">=",
              value: "20_50"
            }
          ]
        };
      }
      return prevData;
    });

    if (!country || country.length === 0) {
      setCountryEmployeeCount({});
    }

    setLLMEmployeeCountry({});
  }

  const handleTpStatusSinceChange = (value) => {
    if (/^\d*$/.test(value)) {
      setTpStatusSince(value);
    }
  };

  const handleSliderTagChange = (selectedTag, fieldName) => {
    setSliderObject((prevSliderObject) => {
      const updatedSliderObject = { ...prevSliderObject };
      // Initialize selectedTags if not already present
      if (!updatedSliderObject[fieldName]) {
        updatedSliderObject[fieldName] = {
          selectedTags: [],
          greaterThanValue: "",
          lessThanValue: "",
        };
      }
      if (!updatedSliderObject[fieldName].selectedTags) {
        updatedSliderObject[fieldName].selectedTags = [];
      }

      let selectedTags = updatedSliderObject[fieldName].selectedTags;
      if (selectedTag.value) {
        const isTagSelected = selectedTags?.some(
          (tag) => tag.value === selectedTag?.value
        );
        if (isTagSelected) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.value !== selectedTag?.value
          );
        } else {
          selectedTags = [selectedTag];
        }

        // Clear numeric inputs if a tag is selected
        updatedSliderObject[fieldName].greaterThanValue = "";
        updatedSliderObject[fieldName].lessThanValue = "";
      } else {
        const hasGreaterThan = selectedTag.greaterThan !== undefined;
        const hasLessThan = selectedTag.lessThan !== undefined;
        // Filter out existing greaterThan and lessThan values
        if (hasGreaterThan) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.operator !== "greaterThan"
          );
        }
        if (hasLessThan) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.operator !== "lessThan"
          );
        }
        if (hasGreaterThan) {
          selectedTags.push({
            value: selectedTag.greaterThan,
            operator: "greaterThan",
          });
        }
        if (hasLessThan) {
          selectedTags.push({
            value: selectedTag.lessThan,
            operator: "lessThan",
          });
        }
        if (hasGreaterThan || hasLessThan) {
          selectedTags = selectedTags?.filter((tag) => !tag.displayName);
        }
      }
      updatedSliderObject[fieldName].selectedTags = selectedTags;

      return updatedSliderObject;
    });
  };

  const handleCountryEmployeeCount = (selectedTag, fieldName) => {
    setCountryEmployeeCount((prevData) => {
      let updatedData = { ...prevData };
  
      if (!updatedData[fieldName]) {
        updatedData[fieldName] = [];
      }
  
      if (selectedTag?.value) {
        const isTagSelected = updatedData[fieldName]?.some(
          (tag) => tag.value === selectedTag.value
        );
        updatedData[fieldName] = isTagSelected
          ? updatedData[fieldName].filter((tag) => tag.value !== selectedTag.value)
          : [selectedTag];
      } else {
        const hasGreaterThan = selectedTag.greaterThan !== undefined;
        const hasLessThan = selectedTag.lessThan !== undefined;
  
        if (hasGreaterThan) {
          updatedData[fieldName] = updatedData[fieldName]?.filter(
            (tag) => tag.operator !== "greaterThan"
          );
          updatedData[fieldName].push({
            value: selectedTag.greaterThan,
            operator: "greaterThan",
          });
        }
        if (hasLessThan) {
          updatedData[fieldName] = updatedData[fieldName]?.filter(
            (tag) => tag.operator !== "lessThan"
          );
          updatedData[fieldName].push({
            value: selectedTag.lessThan,
            operator: "lessThan",
          });
        }
        if (hasGreaterThan || hasLessThan) {
          updatedData[fieldName] = updatedData[fieldName].filter((tag) => !tag.displayName);
        }
      }
      return updatedData;
    });
  };

  const handleTotalFundingClick = (value) => {
    setSelectedTotalFundingData((prevData) => ({
      ...prevData,
      trueFalse: prevData.trueFalse === value ? '' : value
    }));
  };


  const handleShowAll = (item, array) => {
    setCheckboxObjectVisiblity((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedObject = { ...prevCheckboxObject };
      // Check if serverValue exists in the checkboxObject
      if (updatedObject.hasOwnProperty(item)) {
        updatedObject[item]["count"] = array?.length;
      }
      return updatedObject;
    });
  };

  const handlehide = (item) => {
    setCheckboxObjectVisiblity((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedObject = { ...prevCheckboxObject };
      // Check if serverValue exists in the checkboxObject
      if (updatedObject.hasOwnProperty(item)) {
        updatedObject[item]["count"] = 20;
      }
      return updatedObject;
    });
  };

  const getArrayVal = (item) => {
    switch (item.server_value) {
      case "tp_owners":
        return tpOwners ? [...tpOwners] : [];
      case "tp_status":
        return tpStatusOptions ? [...tpStatusOptions] : [];
      case "countries":
        return countries ? [...countries] : [];
      case "states":
        return states ? [...states] : [];
      case "cities":
        return cities ? [...cities] : [];
      case "status":
        return status ? [...status] : [];
      case "newsletter_sender":
        return quipTypes ? [...quipTypes] : [];
      default:
        return [];
    }
  };

  const handleGrowthTagChange = (tag, newValues) => {
    setSelectedGrowthsData((prevSelected) => {
      const tagIndex = prevSelected.findIndex((item) => item.value === tag.value);
      if (tagIndex !== -1) {
        // If the tag is already selected, update the values
        const updatedTag = {
          ...prevSelected[tagIndex],
          greaterThanValue: newValues.greaterThan,
          lessThanValue: newValues.lessThan,
        };
        return [
          ...prevSelected.slice(0, tagIndex),
          updatedTag,
          ...prevSelected.slice(tagIndex + 1),
        ];
      } else {
        // Add the tag if it's not already selected
        return [
          ...prevSelected,
          { value: tag.value, displayName: tag.displayName, greaterThanValue: newValues.greaterThan, lessThanValue: newValues.lessThan },
        ];
      }
    });
  };

  const renderGrowthTags = () => {
    return (
      <>
        {growthTags.map((tag, index) => (
          <div key={index} className="growth-tag-container mt-3">
            <Row>
              <span className="growth-display-name">{tag.displayName}</span>
              <InputFields
                onChange={(newValues) => handleGrowthTagChange(tag, newValues)}
                selectedTag={[
                  { operator: "greaterThan", value: selectedGrowthsData.find(item => item.value === tag.value)?.greaterThanValue || "" },
                  { operator: "lessThan", value: selectedGrowthsData.find(item => item.value === tag.value)?.lessThanValue || "" },
                ]}
                fieldName={tag.value}
              />
            </Row>
          </div>
        ))}
      </>
    );
  };

  const handleGrowthsClick = (i) => {
    setSelectedGrowthsTag((prevSelected) => {
      if (prevSelected.some(item => item.value === i.value)) {
        return prevSelected.filter((item) => item?.value !== i.value);
      } else {
        return [...prevSelected, i];
      }
    });
  }

  const renderGrowthTagsFilter = () => {
    return (
      <div className='d-flex flex-wrap mb-1 mt-1'>
        {growthsData?.length && (
          growthsData?.map((i, ind) => {
            return (
              <div key={ind} className="growth-tag-container">
                <div className="growth-tags-container" onClick={() => handleGrowthsClick(i)}>
                  <div className='tag-count d-flex'>
                    <div className={selectedGrowthsTag?.some(selectedItem => selectedItem.value === i.value) ?
                      "growth-tags active pt-1" : "growth-tags pt-1"}>
                      {i?.displayName}
                      <span className='growth-tool-tip'><PlainTootip content={i.description} name={i?.displayName} height={"12px"} width={"12px"} isActive={selectedGrowthsTag?.some(selectedItem => selectedItem.value === i.value)} /></span>
                    </div>
                    <span className={selectedGrowthsTag?.some(selectedItem => selectedItem.value === i.value) ? 'growth-tag-count pt-1 active': 'growth-tag-count pt-1'}>{i?.count}</span>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  }

  const handleLocation = (form) => {
    setHqLocation(form);
    setFilteredLocationObject({
      keys: "",
      value: "",
    });
    setPopulateLocation([])
  };

  const render_hq_countries = () => {
    return (
      <LocationFilter
        onChange={handleLocation}
        filteredLocation={filteredLocationObject}
        populateLocationData={populateLocation}
        hqLocationSaved={hqLocation}
      />
    );
  };

  const renderTotalFundingTags = () => {
    const investmentData = sliderObject['investment_data'] || {};
    const hasValues = investmentData.selectedTags?.some(tag => tag.value !== '');

    return (
      <div className="d-flex flex-wrap mt-3">
        <Row>
          <span className="total-funding-header">Total Funding</span>
          <InputFields
            onChange={(e) => handleSliderTagChange(e, 'investment_data')}
            selectedTag={sliderObject['investment_data']?.selectedTags || []}
            fieldName='investment_data'
          />
        </Row>
        <div className="total-funding-options">
          <div className="d-flex mb-2">
            <span className="bold-font">Include Funding Data Not Available
              <span className="px-1">
                <PlainTootip
                  content={totalFundingInfoIcon}
                  name="Investment"
                  height={"12px"}
                  width={"12px"}
                />
              </span>
            </span>
          </div>
          <div className="mt-2">
            <Form.Check
              type="checkbox"
              label="Yes"
              id="True"
              name="true"
              checked={selectedTotalFundingData.trueFalse === 'true'}
              onChange={() => handleTotalFundingClick('true')}
              disabled={!hasValues}
            />
            <Form.Check
              type="checkbox"
              label="No"
              id="False"
              name="false"
              // checked={selectedTotalFundingData.trueFalse === 'false'}
              checked={selectedTotalFundingData.trueFalse === 'false'}
              onChange={() => handleTotalFundingClick('false')}
              disabled={!hasValues}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleCommunicationOptionChange = (option) => {
    // Deselect the current option if it's already selected
    const newOption = communicationFilter.option === option ? "" : option;
    setCommunicationFilter({
      ...communicationFilter,
      option: newOption,
      reachedOut: newOption === "reached_out", // true if reached_out, false otherwise
      notReachedOutValue: newOption === "" ? "" : communicationFilter.notReachedOutValue, // Clear days if deselecting option
      days_of_email_sent: newOption === "" ? 0 : communicationFilter.days_of_email_sent, // Clear days if deselecting option
    });
  };

  const handleNotReachedOutChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const maxMonthsValue = 12;
    if (communicationFilter.timeUnit === "months") {
      value = Math.min(value, maxMonthsValue);
    }
    // Calculate days based on timeUnit
    const days = communicationFilter.timeUnit === "months"
      ? value * 30
      : communicationFilter.timeUnit === "years"
        ? value * 365
        : value;

    setCommunicationFilter((prevState) => ({
      ...prevState,
      notReachedOutValue: value,
      days_of_email_sent: days !== 0 ? days : 0,
    }));
  };

  const handleTimeUnitChange = (e) => {
    const newTimeUnit = e.target.value;
    let currentValue = communicationFilter.notReachedOutValue;

    if (newTimeUnit === "months") {
      currentValue = Math.min(currentValue, 12);
    }

    const newDays = newTimeUnit === "months"
      ? currentValue * 30
      : newTimeUnit === "years"
        ? currentValue * 365
        : currentValue;

    setCommunicationFilter((prevState) => ({
      ...prevState,
      timeUnit: newTimeUnit,
      notReachedOutValue: currentValue,
      days_of_email_sent: newDays !== 0 ? newDays : 0,
    }));
  };

  const handleVisitHistoryChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const maxMonthsValue = 12;

    if (visitHistoryFilter.timeUnit === "months") {
      value = Math.min(value, maxMonthsValue);
    }
    // Calculate days based on timeUnit
    const days = visitHistoryFilter.timeUnit === "months"
      ? value * 30
      : visitHistoryFilter.timeUnit === "years"
        ? value * 365
        : value;

    setVisitHistoryFilter((prevState) => ({
      ...prevState,
      visitHistoryValue: value,
      days_of_email_sent: days !== 0 ? days : 0,
    }));
  };

  const handleHistoryTimeUnitChange = (e) => {
    const newTimeUnit = e.target.value;
    let currentValue = visitHistoryFilter.visitHistoryValue;

    if (newTimeUnit === "months") {
      currentValue = Math.min(currentValue, 12);
    }

    const newDays = newTimeUnit === "months"
      ? currentValue * 30
      : newTimeUnit === "years"
        ? currentValue * 365
        : currentValue;

    setVisitHistoryFilter((prevState) => ({
      ...prevState,
      timeUnit: newTimeUnit,
      visitHistoryValue: currentValue,
      days_of_email_sent: newDays !== 0 ? newDays : 0,
    }));
  };

  const renderVisitHistoryFilter = () => {
    const { visitHistoryValue, timeUnit } = visitHistoryFilter;

    return (
      <div className="communication-container mt-3">
        <div className="not-reached-out-options">
          <Row className="mb-2">
            <span className="mb-2">Website visited X days ago</span>
            <Col xs={7}>
              <Form.Control
                type="number"
                value={visitHistoryValue}
                onChange={handleVisitHistoryChange}
                min="0"
                max={timeUnit === "months" ? "12" : ""}
                placeholder="Enter number"
              />
            </Col>
            <Col xs={5} className="communication-dropdown">
              <Form.Select
                as="select"
                value={timeUnit}
                onChange={handleHistoryTimeUnitChange}
              >
                <option value="days">Days</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const renderCommunicationFilter = () => {
    const { option, notReachedOutValue, timeUnit } = communicationFilter;

    return (
      <div className="communication-container mt-3">
        <div className="flex-wrap mb-2">
          <span
            className={
              option === "reached_out"
                ? "selected tp-button btn mb-2"
                : "tp-button btn mb-2"
            }
            onClick={() => handleCommunicationOptionChange("reached_out")}
          >
            <div className="d-flex communication-btn flex-align-center">
              Have we sent an email?
              <div className="px-1">
                <PlainTootip
                  content="Search for companies to whom we have emailed in the past X days"
                  name="Have we sent an email"
                  height={"12px"}
                  width={"12px"}
                  isActive={communicationFilter.option === "reached_out"}
                />
              </div>
            </div>
          </span>
          <span
            className={
              option === "not_reached_out"
                ? "selected tp-button btn"
                : "tp-button btn"
            }
            onClick={() => handleCommunicationOptionChange("not_reached_out")}
          >
            <div className="d-flex communication-btn flex-align-center">
              Have we not sent an email?
              <div className="px-1">
                <PlainTootip
                  content="Search for companies to whom we have not emailed in the past X days"
                  name="Have we not sent an email"
                  height={"12px"}
                  width={"12px"}
                  isActive={communicationFilter.option === "not_reached_out"}
                />
              </div>
            </div>
          </span>
        </div>
        {option === "reached_out" || option === "not_reached_out" ? (
          <div className="not-reached-out-options">
            <Row className="mb-2">
              <span className="mb-2">Action done X days ago</span>
              <Col xs={7}>
                <Form.Control
                  type="number"
                  value={notReachedOutValue}
                  onChange={handleNotReachedOutChange}
                  min="0"
                  max={timeUnit === "months" ? "12" : ""}
                  placeholder="Enter number"
                />
              </Col>
              <Col xs={5} className="communication-dropdown">
                <Form.Select
                  as="select"
                  value={timeUnit}
                  onChange={handleTimeUnitChange}
                >
                  <option value="days">Days</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  };

  // Function to update year founded values
  const handleYearFoundedChange = (fieldName, value) => {
    if (/^\d*$/.test(value)) {

      // Get the current year dynamically
      const currentYear = new Date().getFullYear();

      // Adjust the value if it exceeds the current year
      if ((fieldName === 'lesserThanValue' || fieldName === 'greaterThanValue') && value > currentYear) {
        value = currentYear;
      }

      // Update the state based on the field being updated
      setYearFounded(prevState => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const renderYearFounded = () => {
    return (
      <div className="year-founded-container mt-3">
        <Row className="p-0">
          <span className="">Between</span>
          <Col xs={12} className="d-flex justify-content-center align-items-center mb-2">
            <Form.Group className="mb-0 mr-2">
              <Form.Control
                type="text"
                placeholder="Greater than"
                value={yearFounded.greaterThanValue}
                onChange={(e) => handleYearFoundedChange('greaterThanValue', e.target.value)}
              />
            </Form.Group>
            <span className="mx-2">-</span>
            <Form.Group className="mb-0">
              <Form.Control
                type="text"
                placeholder="Less than"
                value={yearFounded.lesserThanValue}
                onChange={(e) => handleYearFoundedChange('lesserThanValue', e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  const handlellmchange = (value) => {
    setSelectedDataSource(null); 
    setLLMFilters((prevState) => ({
      ...prevState,
      searchTerm: value,
      enteredValue: "",
    }));
    setFocusedIndex(-1);
    setActiveFilter('tp_owners');
  };

  const handlellmKeyDownFn = (e) => {
    if (e.key === "ArrowDown") {
      e.stopPropagation();
      e.preventDefault();
      setFocusedIndex((prevIndex) => {
        const dropdownItems = dropdownRef.current?.querySelectorAll(".autocomplete-item") || [];
        return prevIndex + 1 >= dropdownItems.length ? 0 : prevIndex + 1;
      });
    }
  
    if (e.key === "ArrowUp") {
      e.stopPropagation();
      e.preventDefault();
      setFocusedIndex((prevIndex) => {
        const dropdownItems = dropdownRef.current?.querySelectorAll(".autocomplete-item") || [];
        return prevIndex - 1 < 0 ? dropdownItems.length - 1 : prevIndex - 1;
      });
    }
  
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      setRightSideContent("filters")
      if (showDropdown && focusedIndex !== -1) {
        const focusedItem = dropdownRef.current?.querySelectorAll(".autocomplete-item")[focusedIndex];
        if (focusedItem) {
          handleOptionSelect(focusedItem.textContent);
          e.target.blur();
          return;
        }
      }
      // If no dropdown item is selected, use the input value
      const enteredValue = e.target.value.trim();
      setLLMFilters((prevState) => ({
        ...prevState,
        searchTerm: enteredValue,
        enteredValue: enteredValue,
      }));
      setActiveFilter('tp_owners');
      setShowDropdown(false);
      e.target.blur();
    }
  };

  const handleClearllm = () => {
    setLLMFilters({
      enteredValue: "",
      searchTerm: ""
    })
    setFilterVisible(false);
  }

  const handleIsSaasToggle = (isOn) => {
    isTabchangedclicked = false;
    if (!isOn) {
      setRightSideContent("filters");
    }
    setSaasFilter((prevState) => ({
      ...prevState,
      isSaasFilterApplied: isOn,
    }));
  };

  const renderIsSaasFilter = () => {
    return (
      <div className="saas-filter-container">
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Filter by Saas"
          checked={saasFilter.isSaasFilterApplied}
          onChange={(e) => handleIsSaasToggle(e.target.checked)}
        />
      </div>
    );
  };

  const handleNewsletterSelectedDateRange = (dates) => {
    let start = "", end=""; 
    if(dates) {
        [start, end] = dates;
    }
    setNewsletterForm({
        ...newsletterForm,
        ["dates"]: [start, end],
        startDate: start,
        endDate: end,
    })
  };

  const handleIsNewsletterApplied = (isOn) => {
    isTabchangedclicked = false;
    setNewsletterForm((prevState) => ({
      ...prevState,
      isNewsletterFilterApplied: isOn,
    }));
  };

  const handleNewsletterCheckboxChange = (quipType, isChecked) => {
    setNewsletterForm((prevState) => {
      const updatedCheckboxes = isChecked
        ? [...prevState.newsLetterCheckbox, quipType]
        : prevState.newsLetterCheckbox.filter((q) => q.value !== quipType.value);
      return { ...prevState, newsLetterCheckbox: updatedCheckboxes };
    });
  };

  const handleNewsletterSearch = (searchValue) => {
    setNewsletterSearchTerm(searchValue);
  };

  const renderNewsletterDate = () => {
    const filteredQuipTypes = quipTypes?.filter(quipType =>
      quipType.name?.toLowerCase()?.includes(newsletterSearchTerm?.toLowerCase())
    );

    return (
      <div className="filter-right newsletter-filter">
        <div className="newsletter-filter-switch">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Is in Newsletter"
            checked={newsletterForm.isNewsletterFilterApplied}
            onChange={(e) => handleIsNewsletterApplied(e.target.checked)}
          />
        </div>
        {newsletterForm.isNewsletterFilterApplied && (
          <>
            <div className="filter-item newsletter-date-filter">
              <div className="newsletter-filter-header">Newsletter date</div>
              <label className="from_to_label">From - To</label>
              <RangePickerWithCustomOptions 
                selectedDate={newsletterForm?.dates} 
                handleDateRangeChange={(date) => handleNewsletterSelectedDateRange(date)} 
                className="discovery-date" 
                format={`MMM DD, YYYY`} 
              />
            </div>
            <Form.Group controlId='searchTerm'>
              <Form.Label className='newsletter-search-label'>Newsletter type</Form.Label>
              <Form.Control
                type='text'
                placeholder='Search'
                value={newsletterSearchTerm}
                onChange={e => handleNewsletterSearch(e.target.value)}
                className='mb-3 custom-box'
              />
            </Form.Group>
            {newsletterForm.newsLetterCheckbox?.length > 0 && (
              <div className="selected-filters">
                <div className="selected-filters-header">
                  <b className="selected-filters-heading">Selected newsletter type(s)</b>
                  <span
                    className="uncheck-selected-button"
                    onClick={() => setNewsletterForm((prev) => ({ ...prev, newsLetterCheckbox: [] }))}
                  >
                    Uncheck Selected
                  </span>
                </div>
                {newsletterForm.newsLetterCheckbox?.map((value, index) => (
                  <Form.Check
                    key={value.value}
                    id={`checkbox-${value.value}`}
                    type="checkbox"
                    checked={true}
                    label={value.name}
                    onChange={(e) => handleNewsletterCheckboxChange(value, e.target.checked)}
                  />
                ))}
                <hr className="separator-line" />
              </div>
            )}
            {filteredQuipTypes?.length > 0 && (
              <Form.Check
                type="checkbox"
                label="Select All"
                id="selectAllQuips"
                checked={newsletterForm.newsLetterCheckbox?.length === filteredQuipTypes?.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setNewsletterForm((prev) => ({ ...prev, newsLetterCheckbox: filteredQuipTypes }));
                  } else {
                    setNewsletterForm((prev) => ({ ...prev, newsLetterCheckbox: [] }));
                  }
                }}
              />
            )}
            <Form.Group controlId="newsletterTypesCheckboxes">
              {filteredQuipTypes?.map((quipType) => (
                <Form.Check
                  key={quipType.value}
                  id={`checkbox-${quipType.value}`}
                  type="checkbox"
                  label={quipType.name}
                  checked={newsletterForm.newsLetterCheckbox?.some((q) => q.value === quipType.value)}
                  onChange={(e) => handleNewsletterCheckboxChange(quipType, e.target.checked)}
                />
              ))}
            </Form.Group>
          </>
        )}
      </div>
    );
  };

  const checkIfFilterApplied = (filterValue) => {
    return (
      checkboxObject[filterValue]?.length > 0 || (tpStatusTagged && filterValue === "tp_status") || (tpOwnerTagged && filterValue === "tp_owners") ||
      (filterValue === "headquarter_search" && (hqLocation?.["countries"]?.selected?.length > 0 || hqLocation?.["states"]?.selected?.length > 0 || hqLocation?.["cities"]?.selected?.length > 0)) ||
      (filterValue === "year_founded" && (yearFounded?.greaterThanValue > 0 || yearFounded?.lesserThanValue > 0)) ||
      (filterValue === "communication_with_telescope" && (communicationFilter?.option === "reached_out" || communicationFilter?.option === "not_reached_out" || communicationFilter?.days_of_email_sent > 0)) ||
      (filterValue === "visit_history" && visitHistoryFilter?.days_of_email_sent > 0) ||
      (filterValue === "investment_data" && sliderObject["investment_data"]?.selectedTags?.some((tag) => tag.value && tag.value?.trim() !== "")) ||
      (filterValue === "employee_insights" && sliderObject["employee_count"]?.selectedTags?.some((tag) => tag.value && typeof tag.value === "string" && tag.value?.trim() !== "")) ||
      (filterValue === "growth" && selectedGrowthsData?.some(tag => (tag?.greaterThanValue > 0 || tag?.lessThanValue > 0))) ||
      (filterValue === "growth_tags" && selectedGrowthsTag?.length > 0) ||
      (filterValue === "founder_location_search" && selectedFounderLocation.data?.length > 0) || 
      (filterValue === "newsletter_sender" && selectedQuipsType?.length > 0) ||
      (filterValue === "from_to_date" && (form.dates?.length > 0 && form.dates?.every(value => value !== ""))) ||
      (filterValue === "newsletter" && (newsletterForm.isNewsletterFilterApplied && newsletterForm.dates?.length > 0 && newsletterForm.dates?.every(value => value !== ""))) ||
      (filterValue === "newsletter" && (newsletterForm.isNewsletterFilterApplied && newsletterForm.newsLetterCheckbox?.length > 0)) ||
      (filterValue === "newsletter" && (newsletterForm.isNewsletterFilterApplied)) ||
      (filterValue === "employee_insights" && selectedEmployeeCountry?.data?.length > 0 && countryEmployeeCount?.employee_count?.some((tag) => tag.value && typeof tag.value === "string" && tag.value?.trim() !== ""))
    );
  };

  const countAppliedFilters = () => {
    let appliedCount = 0;
    filterDisplayNames(searchTerm)?.forEach((filter) => {
      if (checkIfFilterApplied(filter.server_value)) {
        appliedCount++;
      }
    });

    return appliedCount;
  };

  const checkNewsLetterFiltersCount = () => {
    let appliedCount = 0;
    newsletters_filters?.forEach((filter) => {
      if (checkIfFilterApplied(filter.server_value)) {
        appliedCount++;
      }
    }); 

    return appliedCount;
  };
  
  const handleSelectedDateRange = (dates) => {
        let start = "", end=""; 
        if(dates) {
            [start, end] = dates;
        }
        setForm({
            ...form,
            ["dates"]: [start, end],
            startDate: start,
            endDate: end,
        })
  };

  const renderFilterContent = (filter) => {
    const serverValue = filter.server_value;
    const selectedValues = checkboxObject[serverValue] || [];
    const allOptions = filter.options || [];
    const arrayVal = getArrayVal(filter);

    return (
      <Form>
        <Row className='discovery-container'>
          <div className='set-filter'>
            <div className='filter-container'>
              <div className='filters-list'>
                {filterDisplayNames(searchTerm).map((item, index) => {
                  const arrayVal = getArrayVal(item);
                  return (
                    <div key={index} className={`filter-item ${activeFilter === item.server_value ? 'active' : ''}`}>
                      {activeFilter === item.server_value && (
                        <div className='filter-dropdown'>
                          {fieldsWithSearchBox.includes(item.server_value) &&
                          dropdownsearchTerms &&
                          Object.keys(dropdownsearchTerms).length && (
                            <Form.Group controlId={`accordionSearch${index}`} className='field-item'>
                              <Form.Group controlId='searchTerm'>
                                <Form.Control
                                  type='text'
                                  placeholder='Search'
                                  value={
                                    dropdownsearchTerms[item.server_value] || ''
                                  }
                                  onChange={e =>
                                    handleSearchBoxChange(
                                      item.server_value,
                                      e.target.value,
                                      arrayVal
                                    )
                                  }
                                  className='mb-3 custom-box'
                                />
                              </Form.Group>
                              {selectedValues.length > 0 && (
                                <div className="selected-filters">
                                  <div className="selected-filters-header">
                                    <b className="selected-filters-heading">Selected Filters</b>
                                    <span className="uncheck-selected-button" onClick={() => handleRemoveFilterGroup(item.server_value)}>Uncheck Selected</span>
                                  </div>
                                  {selectedValues.map((value, index) => {
                                    const ownerDetails = arrayVal.find((owner) => owner.email_address === value);
                                    const displayName = ownerDetails ? ownerDetails.full_name : value;
                                    return (
                                      <div key={index} className="filter-item selected">
                                      <Form.Check
                                        type="checkbox"
                                        checked={true}
                                        label={displayName}
                                        onChange={() =>
                                          handleCheckboxChange(ownerDetails || value, false, serverValue, allOptions)
                                        }
                                      />
                                    </div>
                                    )
                                  })}
                                  <hr className="separator-line" />
                                </div>
                              )}
                              <Form.Group controlId={`${item.server_value}Dropdown${index}`}>
                                {renderTopButtons(item.server_value)}
                                {tpOwners.length === 0 ? ( 
                                    <div className="loader-container">
                                      <img src={imgLoader} alt="Loading..." className="loader-img" />
                                    </div>
                                  ) : ( <>
                                    {arrayVal && arrayVal?.length > 0 && (
                                    <>
                                      <Form.Check
                                        type='checkbox'
                                        label='Select All'
                                        id={`selectAllTpOwners${index}`}
                                        disabled={tpStatusTagged && item.server_value === 'tp_status'}
                                        onChange={e =>
                                          handleCheckboxChange(
                                            'Select All',
                                            e.target.checked,
                                            item.server_value,
                                            arrayVal
                                          )
                                        }
                                        checked={
                                          checkboxObject[`${item.server_value}`]
                                            ?.length === (arrayVal || []).length
                                        }
                                      />
                                      {arrayVal
                                        ?.filter(val => {
                                          if (item.server_value === 'tp_owners') {
                                            return val.full_name
                                              .toLowerCase()
                                              .includes(
                                                (
                                                  dropdownsearchTerms[
                                                  `${item.server_value}`
                                                  ] || ''
                                                ).toLowerCase()
                                              )
                                          } else {
                                            return val
                                              .toLowerCase()
                                              .includes(
                                                (
                                                  dropdownsearchTerms[
                                                  `${item.server_value}`
                                                  ] || ''
                                                ).toLowerCase()
                                              )
                                          }
                                        })
                                        ?.slice(
                                          0,
                                          checkboxObjectVisiblity[item.server_value]
                                            ?.count
                                        )
                                        ?.map((value, valIndex) => {
                                          let checked =
                                            item.server_value === 'tp_owners'
                                              ? value.email_address
                                              : value
                                                ? value
                                                : ''
                                          let name =
                                            item.server_value === 'tp_owners'
                                              ? value.full_name
                                              : value
                                                ? value
                                                : ''
                                          return (
                                            <div
                                              key={valIndex}
                                              className='d-flex align-items-center cursor-pointer'
                                            >
                                              <Form.Check
                                                type='checkbox'
                                                id={`${item.server_value}${index}_${valIndex}`}
                                                label={name}
                                                disabled={tpStatusTagged && item.server_value === 'tp_status'}
                                                checked={checkboxObject[
                                                  `${item.server_value}`
                                                ]?.includes(checked)}
                                                onChange={e =>
                                                  handleCheckboxChange(
                                                    value,
                                                    e.target.checked,
                                                    item.server_value
                                                  )
                                                }
                                                className='cursor-pointer'
                                              />
                                            </div>
                                          )
                                        })}
                                    </>
                                  )}
                                  </> 
                                )}
                                {item.server_value === 'tp_status' && (
                                  <div className='flex-wrap mb-3'>
                                    {tpStatusOperator === 'was' && (
                                      <div className='since-days'>
                                        <span className='mb-2'>
                                          Since X days ago
                                        </span>
                                        <Form.Group className='mt-2'>
                                          <Form.Control
                                            type='text'
                                            placeholder='10'
                                            value={tpStatusSince}
                                            onChange={e =>
                                              handleTpStatusSinceChange(
                                                e.target.value
                                              )
                                            }
                                            disabled={
                                              tpStatusOperator !== 'was' ||
                                              !checkboxObject['tp_status'].length
                                            }
                                          />
                                        </Form.Group>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {arrayVal.length >
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.count &&
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.isSearching &&
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.searchCount > 20 && (
                                    <span
                                      className='custom-btn-show-more flex-wrap px-3'
                                      size='sm'
                                      onClick={() =>
                                        handleShowAll(item.server_value, arrayVal)
                                      }
                                    >
                                      {`Show All (${checkboxObjectVisiblity[item.server_value]
                                        ?.searchCount - 20
                                        })`}
                                    </span>
                                  )}
                                {arrayVal.length >
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.count &&
                                  !checkboxObjectVisiblity[item.server_value]
                                    ?.isSearching && (
                                    <span
                                      className='custom-btn-show-more flex-wrap px-3'
                                      size='sm'
                                      onClick={() =>
                                        handleShowAll(item.server_value, arrayVal)
                                      }
                                    >
                                      {`Show All (${arrayVal.length - 20})`}
                                    </span>
                                  )}
                                {arrayVal.length ==
                                  checkboxObjectVisiblity[item.server_value]
                                    ?.count && (
                                    <span
                                      className='custom-btn-show-more flex-wrap px-3'
                                      size='sm'
                                      onClick={() => handlehide(item.server_value)}
                                    >
                                      {`Hide`}
                                    </span>
                                  )}
                              </Form.Group>
                            </Form.Group>
                          )}
                          {!fieldsWithSearchBox.includes(item.server_value) &&
                            item.server_value !== 'headquarter_search' &&
                            item.server_value !== 'growth' &&
                            item.server_value !== 'growth_tags' &&
                            item.server_value !== 'newsletter' &&
                            item.server_value !== 'visit_history' &&
                            item.server_value !== 'communication_with_telescope' &&
                            item.server_value !== 'investment_data' &&
                            item.server_value !== 'total_funding_usd' &&
                            item.server_value !== 'employee_insights' &&
                            item.server_value !== 'founder_location_search' &&
                            item.server_value !== 'year_founded' && (
                              <Form.Group
                                controlId={`numericValue${index}`}
                                className='field-item'
                              >
                                <TagsInSlider
                                  onChange={e =>
                                    handleSliderTagChange(e, item?.server_value)
                                  }
                                  selectedTag={
                                    sliderObject[item.server_value]?.selectedTags ||
                                    []
                                  }
                                  type={
                                    item?.server_value === 'employee_count'
                                      ? 'numeric'
                                      : 'percentage'
                                  }
                                />
                              </Form.Group>
                            )}
                            {!fieldsWithSearchBox.includes(item.server_value) &&
                              item.server_value !== 'growth' &&
                              item.server_value !== 'growth_tags' &&
                              item.server_value !== 'newsletter' &&
                              item.server_value !== 'visit_history' &&
                              item.server_value !== 'communication_with_telescope' &&
                              item.server_value !== 'investment_data' &&
                              item.server_value !== 'total_funding_usd' &&
                              item.server_value !== 'founder_location_search' &&
                              item.server_value !== 'headquarter_search' &&
                              item.server_value !== 'year_founded' && (
                              <>
                                <div>
                                  {employeeInsightsTags.map(
                                    (employeeInsightTag, index) => {
                                      const sliderData =
                                        sliderObject[employeeInsightTag]
                                      const insightDisplayName =
                                        employeeInsightConstants?.find(
                                          item =>
                                            item.server_value === employeeInsightTag
                                        )
                                      return (
                                        <div key={index}>
                                          <div className='f-s-15 employeeInsight mt-3'>
                                            <span>
                                              {insightDisplayName
                                                ? insightDisplayName.displayName
                                                : employeeInsightTag}
                                            </span>
                                          </div>
                                          <Form.Group
                                            controlId={`numericValue${index}`}
                                            className='field-item'
                                          >
                                            <TagsInSlider onChange={e => handleSliderTagChange(e, employeeInsightTag)} selectedTag={sliderData?.selectedTags || []} type={employeeInsightTag === 'employee_count' ? 'numeric' : 'percentage' }
                                            />
                                          </Form.Group>
                                        </div>
                                      )
                                    }
                                  )}
                                </div>
                                <hr className="separator-line" />
                                <div className='employee-country-location'>
                                  <EmployeeCountry
                                    onChange={obj => handleEmployeeCountryChange(obj)}
                                    itemToAdd={llmEmployeeCountry}
                                    isAlreayDataPresent={selectedEmployeeCountry}
                                  />
                                  <div>
                                    {employeeInsightsTags.map(
                                      (employeeInsightTag, index) => {
                                        const insightDisplayName =
                                          employeeInsightConstants?.find(
                                            item =>
                                              item.server_value === employeeInsightTag
                                          )
                                        return (
                                          <div key={index}>
                                            <div className='f-s-15 employeeInsight mt-3'>
                                              <span>
                                                {insightDisplayName
                                                  ? insightDisplayName.displayName
                                                  : employeeInsightTag}
                                              </span>
                                            </div>
                                            <Form.Group
                                              controlId={`numericValue${index}`}
                                              className='field-item'
                                            >
                                              <TagsInSlider
                                                onChange={(e) => handleCountryEmployeeCount(e, employeeInsightTag)}
                                                selectedTag={countryEmployeeCount[employeeInsightTag] || []}
                                                type={
                                                  employeeInsightTag === 'employee_count' ? 'numeric' : 'percentage'
                                                }
                                              />
                                            </Form.Group>
                                          </div>
                                        )
                                      }
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {item.server_value === 'visit_history' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderVisitHistoryFilter()}
                              </Form.Group>
                            )}
                            {item.server_value === 'communication_with_telescope' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderCommunicationFilter()}
                              </Form.Group>
                            )}
                            {item.server_value === 'growth' && (
                              <Form.Group controlId={`tags${index}`} className='field-item'>
                                {renderGrowthTags()}
                              </Form.Group>
                            )}
                            {item.server_value === "growth_tags" && (
                              <Form.Group controlId={`tags${index}`} className="field-item">
                                {renderGrowthTagsFilter()}
                              </Form.Group>
                            )}
                            {item.server_value === 'investment_data' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderTotalFundingTags()}
                              </Form.Group>
                            )}
                            {item.server_value === 'founder_location_search' && (
                              <Form.Group
                                controlId={`founder-location${index}`}
                                className='field-item '
                              >
                                <div className='d-flex flex-wrap mb-1'>
                                  <b>Founder Metro Area</b>
                                </div>
                                <div className='founder-location'>
                                  <FounderMetro
                                    onChange={obj => handleFounderChange(obj)}
                                    itemToAdd={llmFounderLocation}
                                    isAlreayDataPresent={selectedFounderLocation}
                                  />
                                </div>
                              </Form.Group>
                            )}
                            {item.server_value === 'headquarter_search' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {render_hq_countries()}
                              </Form.Group>
                            )}
                            {item.server_value === 'year_founded' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderYearFounded()}
                              </Form.Group>
                            )}
                             {item.server_value === 'newsletter' && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className='field-item'
                              >
                                {renderNewsletterDate()}
                              </Form.Group>
                            )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
      </Row>
    </Form>
  )};

  const handleSortChange = (criteria) => {
    setFilterSortState((prev) => ({ ...prev, sortCriteria: criteria }));
  };

  const renderFilters = () => {
    const sortedFilters = filterDisplayNames(searchTerm).sort((a, b) => {
      const isFilterAppliedA = checkIfFilterApplied(a.server_value);
      const isFilterAppliedB = checkIfFilterApplied(b.server_value);
  
      if (isFilterAppliedA && !isFilterAppliedB) return -1;
      if (!isFilterAppliedA && isFilterAppliedB) return 1;
  
      return 0;
    });
  
    return (
      <div className="discovery-filters-container">
        <div className="discovery-filters">
          <div className={(selectedListOptions?.length>0 && isActiveBtn === "list")  ? "discovery-filter-main list-cards" : showEnterKeyPrompt ? "discovery-filter-main prompt-displayed" : "discovery-filter-main"}>
            <div className="filter-names">
              {sortedFilters.map((filter) => {
                const isFilterApplied = checkIfFilterApplied(filter.server_value);
                const selectedValues = filter?.server_value === 'newsletter' ? newsletterForm?.newsLetterCheckbox : checkboxObject[filter.server_value] || [];
                const selectedCount = selectedValues.length;
    
                return (
                  <div
                    key={filter.server_value}
                    data-filter={filter.server_value}
                    className={`filter-name ${activeFilter === filter.server_value ? "active" : ""}`}
                    onClick={() => setActiveFilter(filter.server_value)}
                  >
                    {filter.display_name}
                    {selectedCount > 0 && (
                      <span className="selected-count">{selectedCount}</span>
                    )}
                    {isFilterApplied && (
                      <>
                        <span
                          className={isLoading ? "remove-filter disabled" : "remove-filter"}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent parent click handler
                            handleRemoveFilterGroup(filter.server_value);
                          }}
                        >
                          <DustbinIcon className="remove-filter-icon" />
                        </span>
                        <span className="filter-dot" />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="filter-content">
              <div className="filters-red-cross" onClick={handleCloseFilters}>
                <RedCrossIcon className='red-cross-icon' />
              </div>
              {filterDisplayNames(searchTerm)
                .filter((filter) => filter.server_value === activeFilter)
                .map((filter) => (
                  <div key={filter.server_value}>{renderFilterContent(filter)}</div>
                ))}
            </div>
          </div>
          <div className="filter-footer d-flex">
            <div className="filter-footer-content">
              <i className="enter-btn-text">
                Once you have selected press <i className="enter-button">“Enter”</i> to close the filter.
              </i>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleCloseFilters = () => {
    if (hasResults && !isLoading) {
      setRightSideContent("slider");
      if(filteredData?.results?.length) {
        setSliderContent(filteredData?.results?.[focusedCardIndex]?.company_domain || "");
      } else {
        setRightSideContent("filters")
      }
    } else {
      setRightSideContent("");
    }
    if (showEnterKeyPrompt) {
      setShowEnterKeyPrompt(false);
    }
  }

  const handleDataSourceClose = () => {
    if (hasResults) {
      setRightSideContent("slider")
      if(filteredData?.results?.length) {
        setSliderContent(filteredData?.results?.[focusedCardIndex]?.company_domain || "");
      } else {
        setRightSideContent("filters")
      }
    } else {
      setRightSideContent("filters")
    }
  };

  const renderAllDataSources = () => {
    return (
      <div className="discovery-data-sources-container">
        <div className="discovery-data-sources">
          <div className="discovery-data-sources-header">
            <span className="discovery-data-sources-title">All Datasources</span>
            <span className="discovery-data-sources-close" onClick={handleDataSourceClose}><RedCrossIcon className='red-cross-icon' /></span>
          </div>
  
          <div className="discovery-data-sources-list">
            {allDataSources.length === 0 ? ( 
              <div className="loader-container">
                <img src={imgLoader} alt="Loading..." className="loader-img" />
              </div>
            ) : ( 
            <>
              {allDataSources?.results?.map((dataSource, index) => (
                <div
                  key={index}
                  className={`discovery-data-source ${llmFilter?.searchTerm?.trim() === dataSource.datasource ? "active-source" : ""}`}
                  onClick={() => handleOptionSelect(dataSource.datasource, "allData")}
                >
                  <div className="datasource-title">{dataSource.datasource}</div>
                  <div className="datasource-meta">
                    <span className="created-by">Created by: <span className="created-by-data">{dataSource.scoper}</span></span>
                    <span className="last-updated">Last updated: <span className="last-updated-data">{getDaysAgo(dataSource.created_at)}</span></span>
                  </div>
                </div>
              ))}
            </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAllLists = () => {
    return (
      <div className="discovery-data-sources-container">
        <div className="discovery-data-sources discovery-all-lists">
          <div className="discovery-data-sources-header">
            <span className="discovery-data-sources-title">All Lists</span>
            {selectedListOptions?.length > 0 ? (<span className="discovery-data-sources-close" onClick={handleDataSourceClose}><RedCrossIcon className='red-cross-icon' /></span>) : ''}
          </div>
          <div className="discovery-all-lists">
            <CompanyLists 
              position={listButtonPosition} 
              className={'render-all-lists'}
              onClose={() => setListDropdownVisible(false)} 
              selectedOptions={selectedListOptions} 
              loadingListData={isLoading}
              callbackFn={(data) =>  {handleResetAll();  setSelectedListOptions(data); setActiveTab(data[0].workboard_id); isTabchangedclicked = false;}}
            />
          </div>
        </div>
      </div>
    );
  }

  const renderRankingSlider = () => {
    return (
      <div className="discovery-data-sources-container">
        <div className="discovery-data-sources discovery-all-lists">
          <div className="discovery-data-sources-header">
            <span className="discovery-data-sources-title">How are the companies ranked?</span>
            <span className="discovery-data-sources-close" onClick={handleDataSourceClose}><RedCrossIcon className='red-cross-icon' /></span>
          </div>
          <div className="discovery-ranking-slider-content">
            <RankingSlider/>
          </div>
        </div>
      </div>
    );
  }
  
  const handleFocus = () => {
    setShowDropdown(true);
    setFocusedIndex(0); // Ensure the first item is focused when the dropdown appears
  };
  
  const handleOptionSelect = (val) => { 
    isTabchangedclicked = false;
    const selected = allDataSources?.results?.find(item => item.datasource === val);
    setSelectedDataSource(selected); 
    dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: [] });
    setSliderContent('');
    isInitialCall = true;
    setLLMFilters({
      searchTerm: "",   
      enteredValue: "",
    });
    setRightSideContent("filters");
    setShowDropdown(false);
    setFocusedIndex(-1);
    setActiveFilter('tp_owners');
    handleResetAll();
    if (val === 'Orion') {
      window.history.pushState({}, '', '/explore');
    }
  };

  const handleBlur = (e) => {
  };

  const handleSearchHistoryCallback = (type, val) => {
    isTabchangedclicked = false;
    const updateSliderObject = (field, numericValue, isGreaterThan) => {
      setSliderObject((prevState) => {
        const updatedState = { ...prevState };
        const fieldData = { ...updatedState[field] };
  
        // Remove matching range conditions
        fieldData.selectedTags = fieldData.selectedTags.filter((tag) => {
          if (isGreaterThan && tag.operator === "greaterThan" && tag.value === numericValue) {
            return false;
          }
          if (!isGreaterThan && tag.operator === "lessThan" && tag.value === numericValue) {
            return false;
          }
          return true;
        });
  
        // Update greaterThan or lessThan values
        if (isGreaterThan && fieldData.greaterThanValue === numericValue) {
          fieldData.greaterThanValue = "";
        } else if (!isGreaterThan && fieldData.lessThanValue === numericValue) {
          fieldData.lessThanValue = "";
        }
  
        updatedState[field] = fieldData;
        return updatedState;
      });
    };
  
    const updateGrowthData = (val) => {
      const [period, condition] = val.split(": ");
      const value = `growth_${period}`;
      const isGreaterThan = condition.includes(">=");
      const numericValue = condition.replace(">=", "").replace("<=", "").replace("%", "").trim();
  
      setSelectedGrowthsData((prevState) =>
        prevState.map((item) => {
          if (item.value === value) {
            const updatedItem = { ...item };
            if (isGreaterThan && updatedItem.greaterThanValue === numericValue) {
              delete updatedItem.greaterThanValue;
            } else if (!isGreaterThan && updatedItem.lessThanValue === numericValue) {
              delete updatedItem.lessThanValue;
            }
            return updatedItem;
          }
          return item;
        })
      );
    };
  
    
    if (type === "TP Owner") {
      const value = tpOwners?.find((owner) => owner.email_address === val);
      handleCheckboxChange(value, false, "tp_owners", []);
    } else if (type === "TP Status") {
      handleCheckboxChange(val, false, "tp_status", []);
    } else if (type === "TP Status Operator") {
      setTpStatusOperator("is");
    } else if (type === "Status") {
      handleCheckboxChange(val, false, "status", []);
    } else if (type === "Growth") {
      updateGrowthData(val);
    } else if (type === "Growth Tags") {
      const value = selectedGrowthsTag.filter((item) => item.displayName?.trim() !== val?.trim());
      setSelectedGrowthsTag(value);
    } else if (type === "Year Founded") {

      const isGreaterThan = val.includes(">=");
      const numericValue = val.replace(">=", "").replace("<=", "").trim();
  
      setYearFounded((prevState) => {
        const updatedYear = { ...prevState };
  
        if (isGreaterThan && updatedYear.greaterThanValue === numericValue) {
          updatedYear.greaterThanValue = "";
        } else if (!isGreaterThan && updatedYear.lesserThanValue === numericValue) {
          updatedYear.lesserThanValue = "";
        }
        return updatedYear;
      });
    } else if (type === "Employee Insights") {
      const isRange = val.includes(">=") || val.includes("<=");
      if (isRange) {
        const isGreaterThan = val.includes(">=");
        const numericValue = val.replace(">=", "").replace("<=", "").trim();
        updateSliderObject("employee_count", numericValue, isGreaterThan);
      } else {
        setSliderObject((prevState) => {
          const updatedState = { ...prevState };
          const employeeCount = { ...updatedState.employee_count };
  
          employeeCount.selectedTags = employeeCount.selectedTags.filter(
            (tag) =>
              tag.displayName.trim().replace(/\s/g, "") !==
              val.trim().replace(/\s/g, "")
          );
  
          updatedState.employee_count = employeeCount;
          return updatedState;
        });
      }
    } else if (type === 'Employee Count of Country') {
      setSelectedEmployeeCountry((prevState) => ({
        ...prevState,
        data: prevState.data ? prevState.data.filter((country) => country !== val) : [],
      }));
      setCountryEmployeeCount({});
    } else if (type === 'Country Employee Count') {
      const isRange = val?.includes(">=") || val?.includes("<=");
      if (isRange) {
        const isGreaterThan = val?.includes(">=");
        const numericValue = val?.replace(">=", "")?.replace("<=", "")?.trim();
        setCountryEmployeeCount(prevState => {
          const updatedState = { ...prevState };
          let employeeCount = [...updatedState.employee_count];
          employeeCount = employeeCount.map(tag => {
            if (isGreaterThan && tag.operator === "greaterThan" && tag.value === numericValue) {
              return { ...tag, value: "" };
            }
            if (!isGreaterThan && tag.operator === "lessThan" && tag.value === numericValue) {
              return { ...tag, value: "" };
            }
            return tag;
          });
          updatedState.employee_count = employeeCount;
          return updatedState;
        });
      } else {
        setCountryEmployeeCount(prevState => ({
          ...prevState,
          employee_count: prevState.employee_count
            ? prevState.employee_count?.filter(
                tag => tag.displayName?.trim()?.replace(/\s/g, '') !== val?.trim()?.replace(/\s/g, '')
              )
            : []
        }));
      }
    } else if (type === "Investment Data") {
      const isRange = val.includes(">=") || val.includes("<=");
      if (isRange) {
        const isGreaterThan = val.includes(">=");
        const numericValue = val.replace(">=", "").replace("<=", "").trim();
        updateSliderObject("investment_data", numericValue, isGreaterThan);
      }
    } else if (type === "Visit History") {
      setVisitHistoryFilter((prevState) => ({
        ...prevState,
        days_of_email_sent: "",
        visitHistoryValue: ""
      }));
    } else if (type === "Communication with Telescope") {
      if (val === "Reached Out" || val === "Not Reached Out") {
        setCommunicationFilter((prevState) => ({
          ...prevState,
          option: "",
          reachedOut: false,
          notReachedOutValue: "",
          days_of_email_sent: 0,
        }));
      } else {
        setCommunicationFilter((prevState) => ({
          ...prevState,
          days_of_email_sent: "",
          notReachedOutValue: ""
        }));
      }
    } else if (type === "Founder Metro Area") {
      setSelectedFounderLocation((prevState) => ({
        ...prevState,
        data: prevState.data.filter(
          (item) => item.founder_metro_display !== val
        ),
      }));
    } else  if (type === "Headquarter States") {
      setHqLocation((prevState) => {
        const updatedStates = prevState.states.selected.filter(state => state !== val);
        return {
          ...prevState,
          states: {
            ...prevState.states,
            selected: updatedStates
          }
        };
      });
    } else if (type === "Headquarter Countries") {
      setHqLocation((prevState) => {
        const updatedCountries = prevState.countries.selected.filter(country => country !== val);
        return {
          ...prevState,
          countries: {
            ...prevState.countries,
            selected: updatedCountries
          }
        };
      });
    } else if (type === "Headquarter Cities") {
      setHqLocation((prevState) => {
        const updatedCities = prevState.cities.selected.filter(city => city !== val);
        return {
          ...prevState,
          cities: {
            ...prevState.cities,
            selected: updatedCities
          }
        };
      });
    } else if (type === "Filter by Saas") {
      setSaasFilter((prevState) => ({
        ...prevState,
        isSaasFilterApplied: false,
      }));
    } else if( type === "No Status" ) {
      setTpStatusTagged(false)
    }else if( type === "No Owner" ) {
      setTpOwnerTagged(false)
    } else if (type === "Date Appeared") {
      setForm({
        startDate: "",
        endDate: "",
        dates: [],
      });
    } else if (type === "Quip Type") {
      setSelectedQuipsType((prevState) => {
        const updatedQuipTypes = prevState.filter(
          (quip) => quip.name !== val 
        );
        return [...updatedQuipTypes];
      });
    }  else if (type === 'List') {
      setSelectedListOptions((prev) => prev.filter((item) => item.name !== val));
      setActiveTab(selectedListOptions[0].workboard_id);
    } else if (type === "Newsletter Appeared") {
      setNewsletterForm({
        ...newsletterForm,
        startDate: "",
        endDate: "",
        dates: [],
      });
      if (!newsletterForm.newsLetterCheckbox?.length) {
        setNewsletterForm((prevState) => {
          return {
            ...prevState,
            isNewsletterFilterApplied: false
          };
        });
      }
    } else if (type === "Newsletter Type") {
      setNewsletterForm((prevState) => {
        const updatedNewsletterType = prevState.newsLetterCheckbox.filter(type => type.value !== val);
        return {
          ...prevState,
          newsLetterCheckbox: updatedNewsletterType
        };
      });
      if (newsletterForm.newsLetterCheckbox.length === 1 && !newsletterForm.dates?.length) {
        setNewsletterForm((prevState) => {
          return {
            ...prevState,
            isNewsletterFilterApplied: false
          };
        });
      }
    } else if (type === "Is In Newsletter") {
      setNewsletterForm((prevState) => {
        return {
          ...prevState,
          startDate: "",
          endDate: "",
          dates: [],
          isNewsletterFilterApplied: false,
          newsLetterCheckbox: []
        };
      });
    }
  };

  const handleRankingSlider = () => { 
    setRankingSlider(true);
    setRightSideContent("rankingSlider")
  }

  const handleListClick = () => {
    if (isActiveBtn !== "list") {
      dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: [] });
      window.history.pushState({}, "", '/explore');
      setSelectedDataSource(null);
      handleResetAll();
      setIsActiveBtn("list");
      setListDropdownVisible(true);
      setRightSideContent("allLists");
      setLLMFilters({...llmFilter, searchTerm: ""});
      setActiveFilter("tp_owners");
      setSliderContent("");
      setHasResults(false);
    } 
  };
  
  const handleOrionClick = () => {
    if (isActiveBtn !== "orion") {
      window.history.pushState({}, "", '/explore');
      setSelectedDataSource(null);
      handleResetAll();
      setIsActiveBtn("orion");
      setLLMFilters({...llmFilter, searchTerm: ""});
      setActiveTab("");
      setSelectedListOptions([])
      setVisibleTabs([]);
      setDropdownTabs([]);
      setListDropdownVisible(false);
      setRightSideContent("allDataSources");
      dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: [] });
      dispatch({ type: actionTypes.RESET_SELECTED_LIST_OPTION });
      setSliderContent("");
      setHasResults(false);
    }
  };

  const handleTabChange = async (selectedTab) => {
    isTabchangedclicked = true;
    prevSelectedEmployeeCountry.current = null;
    prevCountryEmployeeCount.current = null;
    await handleResetAll();
    setSliderContent("");
    setActiveTab(selectedTab);
    populateTabsData(selectedTab);
    setTimeout(() => {
      document.activeElement.blur();
    }, 100);
  };

  const populateTabsData = (selectedTab) => {
    if(selectedListsData && Object.keys(selectedListsData).length > 0) {
      let selectedList = selectedListsData[selectedTab];
      dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: selectedList?.data});
      setAppliedFilterString(selectedList?.filterstring);
      let total_count = selectedList?.data?.total_count;
      let filteredObject = selectedList?.filterObject;
      dispatch(setAppliedFilters({filteredObject, total_count }));

      if(total_count > 0) {
        setRightSideContent("slider");
        if(selectedList?.data?.results?.length) {
          const index = 0;
          setSliderContent(selectedList?.data?.results?.[index]?.company_domain || "");
        } else {
          setRightSideContent("filters");
        }
      } else {
        setRightSideContent("filters");
      }
    
      setFiltersApplied(true);
      if(selectedList?.data?.results?.length > 0) {
        setHasResults(true);
      } else {
        setHasResults(false);
      }

      if(selectedList?.filterObject && Object.keys(selectedList?.filterObject).length > 0) {
        populateListFilters(selectedList?.filterObject);
      }

      if(selectedList?.filterstring ) {
        window.history.pushState({}, "", `${selectedList?.filterstring}`);
      }
    }
  }

  const populateListFilters = (filteredObject) => { 
      if(filteredObject && Object.keys(filteredObject).length > 0) {
        Object.keys(filteredObject)?.forEach(i => {
          if (i === "TP Owner" && filteredObject[i]?.length) {
            let selectedTP_Owners = tpOwners?.filter(tpOwner => filteredObject[i].includes(tpOwner.email_address));
            setCheckboxObject(prevState => ({
              ...prevState,
              tp_owners: selectedTP_Owners.map(owner => owner.email_address)
            }));
          }

          if (i === "No Owner" && filteredObject[i]?.length) {
            setTpOwnerTagged(true);
          }

          if (i === "TP Status" && filteredObject[i]?.length) {
            setCheckboxObject(prevState => ({
              ...prevState,
              tp_status: filteredObject[i]
            }));
          }

          if (i === "TP Status Operator" && filteredObject[i]?.length) {
            setTpStatusOperator(filteredObject[i][0]);
          }

          if (i === "No Status" && filteredObject[i]?.length) {
            setTpStatusTagged(true);
          }

          if(i === "Status" && filteredObject[i]?.length) {
            setCheckboxObject(prevState => ({
              ...prevState,
              status: filteredObject[i]
            }));
          }   
              
          if(i === "Growth Tags" && filteredObject[i]?.length) {
            growthsData.forEach(growthTag => {
              if(filteredObject[i].includes(growthTag.displayName)) {
                handleGrowthsClick(growthTag);
              }
            })
          } 

          if (i === "Year Founded" && filteredObject[i]?.length) {
            const greaterThan = filteredObject[i].find((val) => val.includes(">=")) || "";
            const lesserThan = filteredObject[i].find((val) => val.includes("<=")) || "";
            setYearFounded({
              greaterThanValue: greaterThan.replace(">=", ""),
              lesserThanValue: lesserThan.replace("<=", ""),
            });
          }

          if(i === "Growth" && filteredObject[i]?.length) {
            const groupedConditions = {};
            filteredObject[i].forEach((growth) => {
              const [period, condition] = growth.split(": ");
              if (!groupedConditions[period]) {
                groupedConditions[period] = { greaterThan: '', lessThan: '' };
              }
            
              const numericValue = condition.replace(">=", "").replace("<=", "").replace("%", "").trim();
              if (condition.includes(">=")) {
                groupedConditions[period].greaterThan = numericValue;
              } else if (condition.includes("<=")) {
                groupedConditions[period].lessThan = numericValue;
              }
            });
            
            Object.entries(groupedConditions).forEach(([period, conditions]) => {
              const type = {
                value: `growth_${period}`,
                displayName: `Growth ${period}`,
              };
              handleGrowthTagChange(type, conditions);
            });
          }  

          if (i === 'Employee Insights' && filteredObject[i]?.length) {
            let employeeCount = {
              greaterThanValue: '',
              lessThanValue: '',
              selectedTags: []
            }
            filteredObject[i].forEach(filter => {
              let operator, value
              if (filter.includes('>=')) {
                operator = 'greaterThan'
                value = filter.replace('>=', '').trim()
                employeeCount.greaterThanValue = value
              } else if (filter.includes('<=')) {
                operator = 'lessThan'
                value = filter.replace('<=', '').trim()
                employeeCount.lessThanValue = value
              } else if (filter.includes(' - ')) {
                const [min, max] = filter.split(' - ').map(v => v.trim())
                employeeCount.greaterThanValue = min
                employeeCount.lessThanValue = max
                employeeCount.selectedTags.push(
                  { value: min, operator: 'greaterThan' },
                  { value: max, operator: 'lessThan' }
                )
                return
              }
              if (operator && value) {
                employeeCount.selectedTags.push({ value, operator })
              }
            })
            setSliderObject(prevState => ({
              ...prevState,
              employee_count: employeeCount
            }))
          }
          if (i === 'Employee Count of Country' && filteredObject[i]?.length) {
            setSelectedEmployeeCountry((prevState) => ({
              ...prevState,
              data: filteredObject[i],
            }));
          }

          if (i === 'Country Employee Count' && filteredObject[i]?.length) {
            let employeeCount = [];
            filteredObject[i].forEach(filter => {
              let operator, value;
              if (filter.includes('>=')) {
                operator = 'greaterThan';
                value = filter.replace('>=', '').trim();
              } else if (filter.includes('<=')) {
                operator = 'lessThan';
                value = filter.replace('<=', '').trim();
              } else if (filter.includes(' - ')) {
                const [min, max] = filter.split(' - ').map(v => v.trim());
                employeeCount.push(
                  { value: min, operator: 'greaterThan' },
                  { value: max, operator: 'lessThan' }
                );
                return;
              }
              if (operator && value) {
                employeeCount.push({ value, operator });
              }
            });
            setCountryEmployeeCount(prevState => ({
              ...prevState,
              employee_count: employeeCount
            }));
          }

          if (i === 'Investment Data' && filteredObject[i]?.length) {
            let investmentData = {
              greaterThanValue: '',
              lessThanValue: '',
              selectedTags: []
            }
            filteredObject[i].forEach(filter => {
              let operator, value
              if (filter.includes('>=')) {
                operator = 'greaterThan'
                value = filter.replace('>=', '').trim()
                investmentData.greaterThanValue = value
              } else if (filter.includes('<=')) {
                operator = 'lessThan'
                value = filter.replace('<=', '').trim()
                investmentData.lessThanValue = value
              }
              if (operator && value) {
                investmentData.selectedTags.push({ value, operator })
              }
            })
            setSliderObject(prevState => ({
              ...prevState,
              investment_data: investmentData
            }))
          }

          if(i === "Visit History" && filteredObject[i]?.length) {
            if (i === "Visit History" && filteredObject[i]?.length) {
              const visitDays = filteredObject[i]?.map(entry => entry?.replace(/\D/g, ""));
              handleVisitHistoryChange({ target: { value: visitDays[0] } });
            }
          }

          if (i === "Communication with Telescope" && filteredObject[i]?.length) {
            const communicationValues = filteredObject[i];
            let daysAgo = communicationValues.find(value => /\d/.test(value))?.replace(/\D/g, "");
            let communicationStatus = communicationValues.find(value => value === "Reached Out" || value === "Not Reached Out");
            let option = communicationStatus === "Reached Out" ? "reached_out" : communicationStatus === "Not Reached Out" ? "not_reached_out" : "";
            if (option) {
              handleCommunicationOptionChange(option);
            }
            if (daysAgo) {
              handleNotReachedOutChange({ target: { value: daysAgo } });
            }
          }

          if(i === "Founder Metro Area" && filteredObject[i]?.length) {
            let filtered = founder_metro_areas.filter(founder => filteredObject[i].includes(founder.founder_metro_display));
            setSelectedFounderLocation({
              ...selectedFounderLocation,
              data: filtered
            })
          }
          if(i === "Headquarter Countries" && filteredObject[i]?.length) {
            setHqLocation(prevState => ({
              ...prevState,
              countries: {
                ...prevState.countries,
                selected: filteredObject[i]
              }
            }));
            setPopulateLocation(prevState => [
              ...prevState,
              { type: 'countries', val: filteredObject[i] }
            ]);
          }

          if(i === "Headquarter States" && filteredObject[i]?.length) {
            setPopulateLocation(prevState => [
              ...prevState,
              { type: 'states', val: filteredObject[i] }
            ]);
            setHqLocation(prevState => ({
              ...prevState,
              states: {
                ...prevState.states,
                selected: filteredObject[i]
              }
            }));
          }
          
          if(i === "Headquarter Cities" && filteredObject[i]?.length) {
            setPopulateLocation(prevState => [
              ...prevState,
              { type: 'cities', val: filteredObject[i] }
            ]);
            setHqLocation(prevState => ({
              ...prevState,
              cities: {
                ...prevState.cities,
                selected: filteredObject[i] 
              }
            }));
          }

          if(i === "Filter by Saas" && filteredObject[i]?.length) {
            setSaasFilter(prevState => ({
              ...prevState,
              isSaasFilterApplied: true
            }));
          }

          if (i === "Is In Newsletter" && filteredObject[i]?.length) {
            setNewsletterForm(prevState => ({
                ...prevState,
                isNewsletterFilterApplied: true
            }));
          }

          if (i === "Newsletter Appeared" && filteredObject[i]?.length) {
            const dateRange = filteredObject[i][0].split(" - ");
            if (dateRange.length === 2) {
                setNewsletterForm(prevState => ({
                    ...prevState,
                    dates: [moment(dateRange[0]), moment(dateRange[1])],
                    startDate: moment(dateRange[0]),
                    endDate: moment(dateRange[1])
                }));
            }
          } 

          if (i === 'Newsletter Type' && filteredObject[i]?.length) {
            const filteredQuipTypes = quipTypes?.filter(
              quipType => filteredObject[i]?.includes(quipType.value) 
            )

            setNewsletterForm(prevState => ({
              ...prevState,
              newsLetterCheckbox: [...filteredQuipTypes]
            }))
          }
        });
      }
  }

  const handleRemoveTab = (workboardId, index) => {
    setSelectedListOptions((prev) => prev.filter((item) => item.workboard_id !== workboardId));
    if (activeTab === workboardId && selectedListOptions.length > 1) {
      if (index == 0) {
        setActiveTab(selectedListOptions[1].workboard_id);
        isTabchangedclicked = true;
        handleResetAll();
        populateTabsData(selectedListOptions[1].workboard_id);
      } else {
        setActiveTab(selectedListOptions[0].workboard_id);
        isTabchangedclicked = true;
        handleResetAll();
        populateTabsData(selectedListOptions[0].workboard_id);
      }
    }else if (activeTab === workboardId && selectedListOptions.length === 1) {
      dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: [] });
      dispatch({ type: actionTypes.RESET_SELECTED_LIST_OPTION });
      setRightSideContent("allLists");
      handleResetAll();
      setHasResults(false);
      setFiltersApplied(false);
    }
  };

  const listButtonPosition = listButtonRef.current ? listButtonRef.current.getBoundingClientRect() : { top: 0, left: 0 };

  return (
    <>
      {historySlider && (
        <SearchHistorySlider
          isOpen={historySlider}
          onClose={handleCloseHistorySlider}
          searchHistory={searchHistory}
          searchFilterApplied={searchFilterApplied}
          callbackFn={handleSearchHistoryCallback}
        />
      )}
      <div className="discovery-container">
        <div className="discovery-container-header">
          <div className="explore-header mb-1">
            <span className="title">Explore</span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3 discovery-header-block">
            <div className="discovery-tabs-container d-flex w-100">
              <div className="discovery-tabs">
                <Form.Group className="discovery-tabs-form">
                  <Dropdown onSelect={(eventKey) => {if (!isLoading) {if (eventKey === "orion") {handleOrionClick()} else if (eventKey === "list") {handleListClick()}}}}>
                    <Dropdown.Toggle className="tabs-btn dropdown-btn">
                      {isActiveBtn === "orion" ? <DatasourcesIcon className='datasources-icon' /> : <AffinityIcon className='datasources-icon'/>}
                      {isActiveBtn === "orion" ? " Datasources" : " Lists"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="orion" disabled={isLoading}><DatasourcesIcon className='datasources-icon'/> Datasources</Dropdown.Item>
                      <Dropdown.Item eventKey="list" disabled={isLoading}><AffinityIcon className='datasources-icon'/> Lists</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
              <div className="discovery-tabs-dropdown flex-grow-1">
                {isActiveBtn === "orion" ? (
                  <div className="llm-search">
                    <Form.Group controlId="searchTerm" className="search-group">
                      <div className="input-container">
                        {selectedDataSource && (
                          <span className="selected-badge">
                            {selectedDataSource.datasource}
                          </span>
                        )}
                        <Form.Control
                          type="text"
                          placeholder={selectedDataSource ? "" : "Select a datasource"}
                          value={selectedDataSource ? '' : llmFilter?.searchTerm}
                          onChange={(e) => handlellmchange(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace" && !e.target.value) {
                              handlellmchange("");
                            }
                            handlellmKeyDownFn(e);
                          }}
                          className="top-search-box"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          autoComplete="off"
                          ref={inputRef}
                        />
                      </div>
                      {showDropdown && (
                        <ul className="autocomplete-dropdown" ref={dropdownRef}>
                          {allDataSources?.results?.map((dataSource, index) => (
                            <li
                              key={index}
                              className={`autocomplete-item ${focusedIndex === index ? "focused" : ""}`}
                              style={{ padding: "8px 12px", cursor: isLoading ? "not-allowed" : "pointer", opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? "none" : "auto" }}
                              onClick={() => handleOptionSelect(dataSource.datasource)}
                              tabIndex={index}
                              onKeyDown={(e) => !isLoading && e.key === "Enter" && handleOptionSelect(dataSource.datasource)}
                              onMouseEnter={() => !isLoading && setFocusedIndex(index)}
                            >
                              <div className="datasource-title">{dataSource.datasource}</div>
                              <div className="datasource-meta">
                                <span className="created-by">Created by: <span className="created-by-data">{dataSource.scoper}</span></span>
                                <span className="last-updated">Last updated: <span className="last-updated-data">{getDaysAgo(dataSource.created_at)}</span></span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Form.Group>
                  </div>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle className="tabs-btn w-100 text-start">
                      {selectedListOptions.length > 0 ? <span className="dropdown-title">{selectedListOptions.length} list(s) selected</span> : <>
                        <span className="dropdown-title">Company lists</span>
                      <span className="dropdown-subtext">
                        (Select a list to view companies tagged under it)
                      </span>
                      </>}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      <CompanyLists 
                        position={listButtonPosition} 
                        className={'company-lists-dropdown'} 
                        onClose={() => setListDropdownVisible(false)} 
                        selectedOptions={selectedListOptions} 
                        loadingListData={isLoading}
                        callbackFn={(data) =>  {
                          handleResetAll(); 
                          if(rightSideContent === 'slider') setRightSideContent(""); 
                          setSelectedListOptions(data); setActiveTab(data[0].workboard_id); 
                          isTabchangedclicked = false;
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className='all-questions-btn' onClick={() => {setRightSideContent(isActiveBtn === 'orion' ? 'allDataSources' : 'allLists');setSliderContent('')}}>
              {isActiveBtn === 'orion' ? 'All Datasources' : 'All Lists'}
            </div>
          </div>
          {isActiveBtn === "list" && (
            <div className='mt-2 position-relative' ref={tabsRef}>
              <Tabs activeKey={activeTab} onSelect={handleTabChange} className={`mb-3 ${selectedListOptions.length > 0 ? "companies-ranking-tabs " : ""} ${dropdownTabs?.length ? 'hasDropdown' : ''}`} >
                {visibleTabs.length > 0 ? (
                  visibleTabs.map((list, index) => (
                    <Tab 
                      eventKey={list.workboard_id} 
                      key={list.workboard_id} 
                      title={
                        <span className="tab-title">
                          {list.name}
                          <CrossRemoveIcon className="cross-icon" onClick={(e) => {e.stopPropagation(); handleRemoveTab(list.workboard_id, index);}}/>
                        </span>
                      }>
                    </Tab>
                  ))
                ) : null}
              </Tabs>
             {isOverflowing && dropdownTabs?.length ? (
              <Dropdown onSelect={handleDropdownSelect} className="mt-2 custom-dropdown-list">
                <Dropdown.Toggle id="dropdown-basic" as="div" className="custom-dropdown-toggle">
                  +{dropdownTabs.length} <span className="dropdown-arrow"><ArrowDownIcon/></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="list-tabs-dropdown-menu">
                  <div className="list-tabs-arrow-up"></div>
                  {dropdownTabs.map((tab) => (
                    <Dropdown.Item
                      key={tab.workboard_id}
                      eventKey={tab.workboard_id}
                      className="justify-content-between list-tabs-dropdown-item"
                    >
                      <span className="list-tab-dropdown-name">{tab.name}</span>
                      <span>
                        <CrossRemoveIcon
                          className="cross-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveTab(tab.workboard_id);
                          }}
                        />
                      </span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
            </div>
          )}
        </div>
        <div className='companies-wrapper mb-2'>
          <div>
            <div className='companies-count mb-1 d-flex'>
              {' '}
              <b className='m-r-10'>
                Companies (
                {discoveryCompaniesResults?.total_count
                  ? discoveryCompaniesResults?.total_count
                  : '--'}
                )
              </b>
              {checkLength() || hasNewsletterLength() ? (
                <div className='selected-filters-container mb-2'>
                  <span className='selected-filter-category'>
                    Selected filter category :
                  </span>{' '}
                  {countAppliedFilters() > 0 ? (
                    <span className='selected-filters-count'>
                      {countAppliedFilters()}
                    </span>
                  ) : checkNewsLetterFiltersCount() > 0 ? (
                    <span className='selected-filters-count'>
                      {checkNewsLetterFiltersCount()}
                    </span>
                  ) : (
                    '--'
                  )}
                </div>
              ) : null}
            </div>
            <div className='companies-selected'>
              <div className=''>
                <div className='flex-wrap internal-container'>
                  {renderAppliedFilters()}
                </div>
              </div>
            </div>
          </div>
          {isActiveBtn === 'orion' ||
          (isActiveBtn === 'list' && selectedListOptions?.length > 0) ? (
            <div className=' d-flex align-items-center justify-content-end g-2'>
              <div className='rankingSlider' onClick={handleRankingSlider}>
                How are companies Ranked?
              </div>
                <div className='is-saas-filter'>{renderIsSaasFilter()}</div>
              <div className='filters-container'>
                <span
                  className='filters-toggle-btn'
                  onClick={toggleFilters}
                  ref={toggleBtnRef}
                >
                  <FiltersIcon className='filters-icon' />
                  Filters{' '}
                  {countAppliedFilters() > 0 ? (
                    <span className='selected-filters-count'>
                      {countAppliedFilters()}
                    </span>
                  ) : checkNewsLetterFiltersCount() > 0 ? (
                    <span className='selected-filters-count'>
                      {checkNewsLetterFiltersCount()}
                    </span>
                  ) : null}
                </span>
                {isFiltersVisible && (
                  <div className='discovery-filters-visible' ref={filtersRef}>
                    <div className='arrow-up'></div>
                    {renderFilters()}
                  </div>
                )}
              </div>
              <Dropdown>
                <Dropdown.Toggle className='custom-dropdown-button'>
                  <SortByIcon /> Sort by
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSortChange('Company Name')}>
                    Company Name
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortChange('TP Status')}>
                    TP Status
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortChange('TP Owners')}>
                    TP Owners
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
        </div>
        {showEnterKeyPrompt && (
          <div className='enter-key-prompt'>
            <div className='flex-wrap prompt-internal-container'>
              <div className="alert-text-block">
                <AlertIcon className='alert-icon' />
                <span className='alert-text'>Press <b className="enter-button">"Enter"</b> to select the company and view its details.</span>
              </div>
              <div className="alert-remove-icon">
                <span className="alert-remove-text" onClick={handleCloseAlert}>x</span>
              </div>
            </div>
          </div>
        )}
        <div>
          {(!rightSideContent) ?
            <div className={'prompt-icon-container'}>
              {isLoading ? <div className={"loader-content"}>
                <span className="stars-icon"><StarsIcon /></span>
                <span className="moon-icon"><MoonIcon /></span>
                <span className="telescope-icon"><TelescopeSearchIcon /></span>
                <div className="earth-loader-block"><img className="earth-loader-gif" height='90px' width='380px' src={earthLoader} alt="spinner" /></div>
                <div className="loading-text">
                  Kindly wait, we are filtering companies based on
                  your requirement...
                </div>
              </div> : ((!hasResults && appliedFiltersCountRef.current > 0) || (isActiveBtn === 'list' && !hasResults)  ) ? <div className="no-data-message mt-5">No data to preview for seleted filters. Please modify your filters!</div> :  isActiveBtn === 'orion' && (llmFilter?.searchTerm?.trim() !== '' || rightSideContent === '') ? <PromptIcon className='prompt-icon-display' /> : llmFilter?.searchTerm?.trim() === '' && rightSideContent === 'allDataSources' ? <DatasourcePrompt className='prompt-icon-display' /> : <ListsPrompt className='prompt-icon-display' />}
            </div> : (rightSideContent) ?
              <>
                <div className={(selectedListOptions?.length>0 && isActiveBtn === "list") ? "discovery-cards list-cards" : showEnterKeyPrompt ? "discovery-cards prompt-displayed" : "discovery-cards" }>
                  <div className="m-0 h-100 d-flex">
                    <div className="discovery-left-side" style={{ flex: 1}}>
                      {!filtersApplied && !hasResults && !isLoading ?   
                        <div className='prompt-icon-container'>
                          <img className="earth-background" src={earthBackgroundIcon} alt="spinner" />
                          <img className="prompt-anime" height="90px" width="130px" src={promptAnime} alt="spinner" />
                          {isActiveBtn === 'orion' && (llmFilter?.searchTerm?.trim() !== '' || rightSideContent === 'filters') ? (
                              <p className="prompt-text">
                                Kindly select the filter(s) based on the company(s) you want to view.
                              </p>
                            ) : llmFilter?.searchTerm?.trim() === '' && rightSideContent === 'allDataSources' ? (
                              <p className="prompt-text">
                                Kindly select a datasource to view companies tagged under it.
                              </p>
                            ) : (
                              <p className="prompt-text">
                                Kindly select a list(s) to view companies tagged under it.
                              </p>
                            )
                          }
                        </div> 
                        : isLoading ? <div className={"loader-content"}>
                        <span className="stars-icon"><StarsIcon /></span>
                        <span className="moon-icon"><MoonIcon /></span>
                        <span className="telescope-icon"><TelescopeSearchIcon /></span>
                        <div className="earth-loader-block"><img className="earth-loader-gif" height='90px' width='380px' src={earthLoader} alt="spinner" /></div>
                        <div className="loading-text">
                          Kindly wait, we are filtering companies based on
                          your requirement...
                        </div>
                      </div> : hasResults ? <DiscoveryCards isFilterCollapsed={!filterVisible} appliedFilterString={appliedFilterString} filterSortState={filterSortState} llmFilter={llmFilter.searchTerm} tabChanged={activeTab} handleSelectedDomain={(domain, index) => handleSelectedDomain(domain, index)} rightSideContent={rightSideContent}
                       handleContextChange={() => setRightSideContent("slider")} focusIndexCallback={(index) => setFocusedCardIndex(index)} showEnterPromptCallback={setShowEnterKeyPrompt}/>
                        : <div className="no-data-message mt-5">No data to preview. Please modify your filters!</div>}
                    </div>
                    <div className="company-details-slider-container">
                      <div className="slider-container h-100">
                        {rightSideContent && rightSideContent === 'slider' && sliderContent !== '' ? <Slider companyDomain={sliderContent} callBackFn={() => setSliderContent("")}/> : rightSideContent === 'filters' ? renderFilters() : rightSideContent === 'allDataSources' ? renderAllDataSources() : rightSideContent === 'allLists' ? renderAllLists() : rightSideContent === 'rankingSlider' ? renderRankingSlider() : null}
                      </div>
                    </div>
                  </div>
                </div>
              </> : null}
        </div>
      </div>
    </>
  );
};

export default Discovery;
