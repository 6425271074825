import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './dropdown.scss';
import ArrowRoundIcon from '../../../assets/images/icon_svg/arrow-round.svg';
import ArrowRoundWhite from '../../../assets/images/icon_svg/arrow-round-white.svg';

const Dropdown = ({ label, options, onOptionSelect, selectedOption, onOptionRemove, isTpOwner, isTpStatus, isBucket }) => {
  const [isHovered, setIsHovered] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [userInfo] = useSelector(({ dashBoardReducer }) => [dashBoardReducer.userInfo]);

  useEffect(() => {
    if (isOpen && inputRef.current && dropdownRef.current) {
      const { offsetTop, offsetHeight } = inputRef.current;
      dropdownRef.current.style.top = `${offsetTop + offsetHeight}px`;
      dropdownRef.current.style.left = `${inputRef.current.offsetLeft}px`;
      dropdownRef.current.style.width = `${inputRef.current.offsetWidth}px`;
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isOpen) {
        if (e.key === 'ArrowDown') {
          setFocusedIndex(prev => Math.min(prev + 1, filteredOptions.length - 1));
        } else if (e.key === 'ArrowUp') {
          setFocusedIndex(prev => Math.max(prev - 1, 0));
        } else if (e.key === 'Enter') {
          if (filteredOptions.length > 0) {
            // Handle when an existing option is focused
            if (focusedIndex >= 0 && focusedIndex < filteredOptions.length) {
              handleOptionClick(filteredOptions[focusedIndex]);
            }
          } else {
            if (inputValue.trim() !== '') {
              handleOptionClick(inputValue.trim());
            }
          }
        }
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, focusedIndex, filteredOptions, inputValue]);

  useEffect(() => {
    if (isOpen && focusedIndex !== -1 && dropdownRef.current) {
      const focusedOption = dropdownRef.current.children[focusedIndex];
      if (focusedOption) {
        focusedOption.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      }
    }
  }, [focusedIndex, isOpen]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  };

  const handleInputChange = (e) => setInputValue(e.target.value);

  const handleOptionClick = (option) => {
    onOptionSelect(option);
    setInputValue('');
    setIsOpen(false);
  };

  const handleRemoveClick = (option) => {
    if (onOptionRemove) {
      onOptionRemove(option);
    }
    if (selectedOption.length <= 2) {
      setShowMore(false);
    }
  };

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  const renderCreateOption = () => {
    if (isBucket) {
      return <li onClick={() => handleOptionClick(inputValue)}>Create New Bucket</li>;
    }
    if (!isBucket && !isTpStatus && !isTpOwner) {
      return <li onClick={() => handleOptionClick(inputValue)}>Create New List</li>;
    }
    return null;
  };

  const validOptions = Array?.isArray(options) ? options : [];
  const filteredOptions = validOptions.filter(option => {
    const displayName = typeof option === 'object' ? option.display_name || option.name : option;
    return displayName.toLowerCase().includes(inputValue.toLowerCase());
  });
  const selectedLabels = Array?.isArray(selectedOption) ? selectedOption : [selectedOption];

  const additionalOptions = selectedLabels?.length > 1 ? selectedLabels?.slice(1) : [];

  const email = userInfo?.email;
  const emailPrefix = email?.split('@')[0]?.split('.')[0];
  const ownerOption = filteredOptions?.find((e) => e?.server_value?.toLowerCase() === emailPrefix?.toLowerCase());

  return (
    <div className="dropdown-container" ref={containerRef}>
      {label && <label className="dropdown-label">{label}</label>}
      <div className="multi-select-component">
        <input
          ref={inputRef}
          className={`selected-input ${isOpen ? '' : 'hidden'}`}
          value={inputValue}
          onChange={handleInputChange}
        />
        <span className="add_more_btn" onClick={handleToggleDropdown} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {selectedOption && (isTpStatus || isBucket) ? 
                <img src={isHovered ? ArrowRoundWhite : ArrowRoundIcon}  height="8px" width="8px" className="info-icon"/> : '+'
            }
        </span>
        {isOpen && (
            (filteredOptions.length > 0 || isBucket || (!isBucket && !isTpStatus && !isTpOwner)) && (
                <ul className="autocomplete-list" ref={dropdownRef}>
                {filteredOptions.length > 0 ? (
                  <>
                  {isTpOwner && ownerOption && ownerOption !== undefined && (
                      <>
                        <li onClick={() => handleOptionClick(ownerOption)}>
                          <span className='owner-label'>{ownerOption?.display_name}</span>
                        </li>
                        <div className='other-owners-label'>
                          <span>Other Owners</span>
                        </div>
                      </>
                  )}
                  {filteredOptions.map((option, index) => (
                    <>
                        {((isTpOwner && ownerOption && ownerOption?.server_value !== option?.server_value) || (isTpOwner && ownerOption === undefined)) && (
                          <li key={index} onClick={() => handleOptionClick(option)} className={`${index === focusedIndex ? 'focused highlight' : ''}`}>
                            {typeof option === 'object' ? option.display_name || option.name : option}
                          </li>
                        )}
                        {!isTpOwner && (
                          <li key={index} onClick={() => handleOptionClick(option)} className={`${index === focusedIndex ? 'focused highlight' : ''}`}>
                            {typeof option === 'object' ? option.display_name || option.name : option}
                          </li>
                        )}
                    </>
                  ))}
                </>
                ) : (
                    renderCreateOption()
                )}
                </ul>
            )
        )}
        {selectedLabels && selectedLabels?.length > 0 && !isTpOwner && selectedLabels?.map((label, index) => (
          label && (
            <div key={index} className="selected-wrapper">
              <span className="selected-label">
                {typeof label === 'object' ? label.label : label}
              </span>
              {!isTpStatus ? <a className="selected-close" onClick={() => handleRemoveClick(label)}>x</a> : <span className="selected-close"></span>}
            </div>
          )
        ))}
        {selectedLabels?.length > 0 && isTpOwner && selectedLabels[0] && (
          <div className="selected-wrapper">
            <span className="selected-label">
              {typeof selectedLabels[0] === 'object' ? selectedLabels[0].label : selectedLabels[0]}
            </span>
            <a className="selected-close" onClick={() => handleRemoveClick(selectedLabels[0])}>x</a>
          </div>
        )}
        {selectedLabels?.length > 1 && isTpOwner && !showMore && (
          <div className="show-more-options">
            <span className="more-count" onClick={handleShowMoreClick}>
              +{selectedLabels.length - 1}
            </span>
          </div>
        )}
        {showMore && isTpOwner && (
          <>
            {additionalOptions?.map((option, index) => (
              <div key={index} className="selected-wrapper">
                <span className="selected-label">
                  {typeof option === 'object' ? option.label : option}
                </span>
                <a className="selected-close" onClick={() => handleRemoveClick(option)}>x</a>
              </div>
            ))}
            <div className="show-more-options">
              <span className="hide-options" onClick={handleShowMoreClick}>
                Hide
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
