import axios from "axios";

import createApiInstance from "./createApiInstance";

//axios instance for create email-history
const axiosEmailHistoryInstance = axios.create({
  ...createApiInstance("emailHistory"),
});
//axios instance for create TP-Owner
const axiosTpOwnerInstance = axios.create({
  ...createApiInstance("tPOwner"),
});

//axios instance for create new references
const axiosReferenceListInstance = axios.create({
  ...createApiInstance("references"),
});

//axios instance for create new references-search
const axiosReferenceSearchInstance = axios.create({
  ...createApiInstance("referenceSearch"),
});

//axios instance for create new references-search
const axiosScoperInstance = axios.create({
  ...createApiInstance("scopers"),
});

//axios instance for create new references-search
const axiosFileMatricsTemplateInstance = axios.create({
  ...createApiInstance("fileMatricsTemplate"),
});

//axios instance for create cookie store
const axiosCookieStoreTemplateInstance = axios.create({
  ...createApiInstance("cookieStoreTemplate"),
});

//axios instance for create cookie store
const axiosFileMatricsInstance = axios.create({
  ...createApiInstance("fileMatrics"),
});

//axios instance for create cookie store
const axiosCookieStoreInstance = axios.create({
  ...createApiInstance("cookieStore"),
});

//axios instance for tp status
const axiosTPPipelineInstance = axios.create({
  ...createApiInstance("tpPipeline"),
});

//axios instance for tp status
const axiosTPPipelineNewInstance = axios.create({
  ...createApiInstance("tpPipelineNew"),
});

//axios instance for create spyfu metrics
const axiosSpyfuMetricsInstance = axios.create({
  ...createApiInstance("spyfuMetrics"),
});

//axios instance for spyfu filter
const axiosSpyfuFilterInstance = axios.create({
  ...createApiInstance("spyfuFilter"),
});

//axios instance for create linkedin metrics
const axiosLinkedInMetricsInstance = axios.create({
  ...createApiInstance("linkedinMetrics"),
});

//axios instance for linkedin filter
const axiosLinkedInFilterInstance = axios.create({
  ...createApiInstance("linkedinFilter"),
});

//axios instance for create linkedin metrics
const axiosAlexaMetricsInstance = axios.create({
  ...createApiInstance("alexaMetrics"),
});

//axios instance for linkedin filter
const axiosAlexaFilterInstance = axios.create({
  ...createApiInstance("alexaFilter"),
});

//axios for instance for comapny updates
const axiosCompanyUpdatesInstance = axios.create({
  ...createApiInstance("companyUpdates"),
});

//axios for instance for comapny update type
const axiosCompanyUpdateType = axios.create({
  ...createApiInstance("companyUpdateType"),
});

//axios for instance for favorite
const axiosCompanyProfileFavorite = axios.create({
  ...createApiInstance("favorite"),
});

//axios for instance for fileUploads
const axiosCompanyProfileFileUploads = axios.create({
  ...createApiInstance("fileUploads"),
});

const axiosWorkboardFileUpload = axios.create({
  ...createApiInstance("fileUploads")
})

//axios for instance for documentTypes
const axiosCompanyProfileDocumentType = axios.create({
  ...createApiInstance("documentTypes"),
});

//axios for instance for uploadFile
const axiosCompanyUploadFile = axios.create({
  ...createApiInstance("uploadFile"),
});

//axios instance for getting userInfo
const axiosUserInfoInstance = axios.create({
  baseURL:
    "https://orion-portal.auth.us-east-1.amazoncognito.com/oauth2/userInfo",
});

//axios instance for getting project
const axiosProjectsInstance = axios.create({
  ...createApiInstance("projects"),
});

//axios instance for getting template
const axiosAllTemplatesInstance = axios.create({
  ...createApiInstance("templates"),
});

//axios instance for getting notifications
const axiosNotificationsInstance = axios.create({
  ...createApiInstance("notifications"),
});

//axios instance for getting notifications template
const axiosNotificationTemplateInstance = axios.create({
  ...createApiInstance("notificationTemplate"),
});

//axios instance for getting company search
const axiosCompanySearchInstance = axios.create({
  ...createApiInstance("companySearch"),
});

//axios instance for getting company Alert
const axiosCompanyAlertInstance = axios.create({
  ...createApiInstance("companyAlert"),
});

//axios instance for getting company Alert
const axiosCompanyFavouriteInstance = axios.create({
  ...createApiInstance("companyPage"),
});

//axios instance for getting document meta
const axiosFileSaveMetadata = axios.create({
  ...createApiInstance("documentMeta"),
});

//axios instance for saving company Alert
const axiosCompanySaveAlertsInstance = axios.create({
  ...createApiInstance("alert"),
});

//axios instance for saving company Alert
const axiosAlertCompanyInstance = axios.create({
  ...createApiInstance("alertCompany"),
});

// downstream filter
const axiosDownStreamFilter = axios.create({
  ...createApiInstance("downStreamFilter"),
});
// combined filter
const axiosCombinedFilter = axios.create({
  ...createApiInstance("combinedFilter"),
});

// downstream filter
const axiosLinkedInCities = axios.create({
  ...createApiInstance("cities"),
});
// combined filter
const axiosLinkedInStates = axios.create({
  ...createApiInstance("states"),
});
// ComapanyPage
const axiosCompanyPage =axios.create({
  ...createApiInstance("companyPage")
})

//CompanyPage Meeting cards
const axiosCompanyPageMeeting = axios.create({
  ...createApiInstance("meetingStat")
})
// CompanyProfilePageTP Status history 
const axiosCompanyPageTpStatusHistory = axios.create({
  ...createApiInstance("tpStatus")
})
// CompanyProfilePageTpStatusOptions
const axiosCompanyPageTpStatusOptions = axios.create({
  ...createApiInstance("tpStatusOptions")
})
// CompanyProfilePageTpOwner
const axiosCompanyPipelineTpOwner = axios.create({
  ...createApiInstance("pipelineTpOwner")
})

// CompanyProfilePageTpOwner
const axiosCompanyPipelineTpStatus = axios.create({
  ...createApiInstance("pipelineTpStatus")
})

// CompanyProfilePageTpOwner
const axiosCompanyPipelineTpFilterOPS = axios.create({
  ...createApiInstance("pipelineTpFilterOPS")
})

// CompanyProfilePageTP Status history 
const axiosSourceInstance = axios.create({
  ...createApiInstance("sources")
})
// CompanyProfileProject
const axiosCompanyProject = axios.create({
  ...createApiInstance("companyProject")
})
// CompanyProfileTPOwner
const axiosaddTpOwner = axios.create({
  ...createApiInstance("addTpOwner")
})
// CompanyProfileTPStatus
const axiosAddTpStatus = axios.create({
  ...createApiInstance("addTpStatus")
})

// CompanyProfileTPOwner
const axiosEmailChart = axios.create({
  ...createApiInstance("emailChart")
})
// CompanyProfileTPStatus
const axiosEmailDetails = axios.create({
  ...createApiInstance("emailDetails")
})
// CompanyProfileTPOwner
const axiosDraftList = axios.create({
  ...createApiInstance("draftList")
})
// CompanyProfileTPStatus
const axiosDraftDetail = axios.create({
  ...createApiInstance("draftDetails")
})
// DashboardNewDesign -InsightsCard
const axiosDashboardInsightsCard = axios.create({
  ...createApiInstance("universeInsights")
})

// DashboardNewDesign -InsightsCard
const axiosManagement = axios.create({
  ...createApiInstance("management")
})
// DashboardPipelineManagement
const axiosDashboardPipelineManagement = axios.create({
  ...createApiInstance("pipelineManagement")
})

// DashboardPipelineManagementViewAllLink
const DashboardPipeLineManagementViewAllLink = axios.create({
  ...createApiInstance("pipelineViewAll")
})

const axiosEmployeepopulation = axios.create({
  ...createApiInstance("employeePopulation")
})

const axiosAffinityList = axios.create({
  ...createApiInstance("affinityList")
})

const axiosAddCompanyToList = axios.create({
  ...createApiInstance("addCompanyList")
})

const axiosGetFunction = axios.create({
  ...createApiInstance("getFunction")
})

const axiosGetRefHeader = axios.create({
  ...createApiInstance("getReferenceHeader")
})


const axiosGetIncomplete = axios.create({
  ...createApiInstance("getIncompltete")
})

const axiosConversationSingleReference = axios.create({
  ...createApiInstance("conversationSingleReference")
})

const axiosAllReference = axios.create({
  ...createApiInstance("allreferences")
})

const axiosGetWorkboardCompanies = axios.create({
  ...createApiInstance("getCompaniesWorkboard")
})

const axiosGetWorkboardCategories = axios.create({
  ...createApiInstance("getWorkboardCategories")
})

const axiosGetWorkboardPeople = axios.create({
  ...createApiInstance("getWorkboardPeople")
})

const axiosUpdatePerson= axios.create({
  ...createApiInstance("updatePerson")
})

const axiosCreateWorkbook= axios.create({
  ...createApiInstance("createWorkbook")
})
const axiosMyWorkboards = axios.create({
  ...createApiInstance("myWorkboards")
})
const axiosAddPersonToRef = axios.create({
  ...createApiInstance("addPersonToRef")
})
const axiosDiscoveryCompanies = axios.create({
  ...createApiInstance("getDiscoveryCompanies")
})
const axiosDiscoveryFiltersData = axios.create({
  ...createApiInstance("getDiscoveryFiltersData")
})
const axiosInvestorReminders = axios.create({
  ...createApiInstance("getInvestorReminders")
})
const axiosInvestorReminderStatus = axios.create({
  ...createApiInstance("setInvestorRemindersStatus")
})
const axiosLLMFilters = axios.create({
  ...createApiInstance("setLLMFilter")
})
const axiosDiscoveryWatch = axios.create({
  ...createApiInstance("discoveryWatch")
})
const axiosTpStatusOptions = axios.create({
  ...createApiInstance("tpStatusOptions")
})
const axiosListsAndBucketsOptions = axios.create({
  ...createApiInstance("allListsAndBuckets")
});
const axiosPipelineTpOwner = axios.create({
  ...createApiInstance("pipelineTpOwner")
})
const axiosCompanyLists = axios.create({
  ...createApiInstance("companyLists")
})
const axiosCompanyNoCachedData = axios.create({
  ...createApiInstance("companyNoCachedData")
})
const axiosCompanyCachedData = axios.create({
  ...createApiInstance("companycachedData")
})
const axiosFindFounderData = axios.create({
  ...createApiInstance("findfounderData")
})
const axiosAdvertisingStats = axios.create({
  ...createApiInstance("advertisingStats")
})
const axiosCompanyCompetitors = axios.create({
  ...createApiInstance("companyCompetitors")
})
const axiosAddAdvertisingStats = axios.create({
  ...createApiInstance("addAdvertisingStats")
})
const axiosAddCompanyCompetitors = axios.create({
  ...createApiInstance("addCompanyCompetitors")
})
const axiosFindCompanyFounders = axios.create({
  ...createApiInstance("findCompanyFounders")
})
const axiosAddBucket = axios.create({
  ...createApiInstance("assignBucketToCompany")
})
const axiosAddNewCompanyList = axios.create({
  ...createApiInstance("addNewList")
})
const axiosGetQuipsData = axios.create({
  ...createApiInstance("quipsData")
})
const axiosGetKnowledgeSource = axios.create({
  ...createApiInstance("knowledgeSource")
})
const axiosGetMentionsData = axios.create({
  ...createApiInstance("mentionsData")
}) 
const axiosGetCtoLunchesData = axios.create({
  ...createApiInstance("getLunchesData")
}) 
const axiosGetNotes = axios.create({
  ...createApiInstance("getNotes")
}) 
const axiosGetNotesDetails = axios.create({
  ...createApiInstance("getNotesDetails")
})
const axiosPostAssociateNotes = axios.create({
  ...createApiInstance("postAssociateNote")
})
const axiosDiscoverySearchHistory = axios.create({
  ...createApiInstance("discoverySearchHistory")
})
const axiosQuipTypes = axios.create({
  ...createApiInstance("quipTypes")
})
const axiosWebsiteHistory = axios.create({
  ...createApiInstance("websiteHistory")
})
const axiosGetExploreDataSources = axios.create({
  ...createApiInstance("getExploreDataSources")
})
export {
  axiosCompanyUploadFile,
  axiosCompanySaveAlertsInstance,
  axiosCompanyFavouriteInstance,
  axiosCompanyAlertInstance,
  axiosUserInfoInstance,
  axiosReferenceListInstance,
  axiosCompanyProfileDocumentType,
  axiosCompanyProfileFileUploads,
  axiosWorkboardFileUpload,
  axiosReferenceSearchInstance,
  axiosScoperInstance,
  axiosFileMatricsTemplateInstance,
  axiosCookieStoreTemplateInstance,
  axiosFileMatricsInstance,
  axiosCookieStoreInstance,
  axiosTPPipelineInstance,
  axiosTPPipelineNewInstance,
  axiosSpyfuMetricsInstance,
  axiosSpyfuFilterInstance,
  axiosLinkedInMetricsInstance,
  axiosLinkedInFilterInstance,
  axiosAlexaMetricsInstance,
  axiosAlexaFilterInstance,
  axiosCompanyUpdatesInstance,
  axiosCompanyUpdateType,
  axiosCompanyProfileFavorite,
  axiosEmailHistoryInstance,
  axiosTpOwnerInstance,
  axiosProjectsInstance,
  axiosAllTemplatesInstance,
  axiosNotificationsInstance,
  axiosNotificationTemplateInstance,
  axiosCompanySearchInstance,
  axiosFileSaveMetadata,
  axiosDownStreamFilter,
  axiosCombinedFilter,
  axiosLinkedInCities,
  axiosLinkedInStates,
  axiosCompanyPage,
  axiosCompanyPageMeeting,
  axiosCompanyPageTpStatusHistory,
  axiosCompanyPageTpStatusOptions,
  axiosCompanyPipelineTpOwner,
  axiosCompanyPipelineTpStatus,
  axiosCompanyPipelineTpFilterOPS,
  axiosSourceInstance,
  axiosAlertCompanyInstance,
  axiosCompanyProject,
  axiosaddTpOwner,
  axiosAddTpStatus,
  axiosEmailChart,
  axiosEmailDetails,
  axiosDraftList,
  axiosDraftDetail,
  axiosDashboardInsightsCard,
  axiosManagement,
  axiosDashboardPipelineManagement,
  DashboardPipeLineManagementViewAllLink,
  axiosEmployeepopulation,
  axiosAffinityList,
  axiosAddCompanyToList,
  axiosGetFunction,
  axiosGetRefHeader,
  axiosGetIncomplete,
  axiosConversationSingleReference,
  axiosAllReference,
  axiosGetWorkboardCompanies,
  axiosGetWorkboardCategories,
  axiosGetWorkboardPeople,
  axiosUpdatePerson,
  axiosCreateWorkbook,
  axiosMyWorkboards,
  axiosAddPersonToRef,
  axiosDiscoveryCompanies,
  axiosDiscoveryFiltersData,
  axiosInvestorReminders,
  axiosInvestorReminderStatus,
  axiosLLMFilters,
  axiosDiscoveryWatch,
  axiosTpStatusOptions,
  axiosListsAndBucketsOptions,
  axiosPipelineTpOwner,
  axiosCompanyLists,
  axiosCompanyNoCachedData,
  axiosCompanyCachedData,
  axiosFindFounderData,
  axiosAdvertisingStats,
  axiosCompanyCompetitors,
  axiosAddAdvertisingStats,
  axiosAddCompanyCompetitors,
  axiosFindCompanyFounders,
  axiosAddBucket,
  axiosAddNewCompanyList,
  axiosGetQuipsData,
  axiosGetKnowledgeSource,
  axiosGetMentionsData,
  axiosGetCtoLunchesData,
  axiosGetNotes,
  axiosGetNotesDetails,
  axiosPostAssociateNotes,
  axiosDiscoverySearchHistory,
  axiosQuipTypes,
  axiosWebsiteHistory,
  axiosGetExploreDataSources
};

