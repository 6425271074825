import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";

import { getHQLookup } from "../../../store/actions";
import { getAddress } from "../../../utils";

import "./founderMetro.scss"

const FounderMetro = ({ onChange, itemToAdd, isAlreayDataPresent }) => {
    const asyncTypeaheadRef = useRef(null);

    const [option, setOption] = useState([]);
    const [selectedFounderLocationObject, setSelectedFounderLocationObject] = useState({
        isHidden: true,
        selected: [],
    });

    useEffect(() => {
        if (isAlreayDataPresent) {
            if (!isAlreayDataPresent?.data?.length) {
                setSelectedFounderLocationObject(prevState => ({
                    ...prevState,
                    selected: []
                }));
            } else if (
                !isAlreayDataPresent?.data.every((element) =>
                    selectedFounderLocationObject.selected.includes(element)
                )
            ) {
                setSelectedFounderLocationObject(prevState => ({
                    ...prevState,
                    selected: isAlreayDataPresent?.data
                }));
            }
        }
    }, [isAlreayDataPresent]);

    const [founder_metro_areas] = useSelector(({ discoveryReducer }) => [
        discoveryReducer.metadata.founder_metro_areas,
    ]);
    
   

    useEffect(() => {
        if(Object.keys(itemToAdd)?.length) {
            setSelectedFounderLocationObject((prevState) => ({
                ...prevState,
                selected: prevState.selected.filter(
                  (item) => item.founder_metro_value !== itemToRemove.founder_metro_value
                ),
              }));
        }
    }, [itemToAdd])

    useEffect(() => {
        if(founder_metro_areas?.length) {
            setOption(founder_metro_areas)
        }
    },[founder_metro_areas])


    useEffect(()=> {
        if (!selectedFounderLocationObject?.isHidden) {
            setTimeout(() => {
                asyncTypeaheadRef?.current?.focus();
            }, 0);
        }
    },[selectedFounderLocationObject?.isHidden])

    const handlePlus = () => {
        setSelectedFounderLocationObject({
            ...selectedFounderLocationObject,
            isHidden: false
        });
    };

    const handleBlur = () => {
        setSelectedFounderLocationObject({
            ...selectedFounderLocationObject,
            isHidden: true
        });
    };

    function handleChange(val) {
        const filtered = val.filter(
            (element) =>
                !selectedFounderLocationObject.selected.some(
                    (selected) => selected.founder_metro_value === element.founder_metro_value
                )
        );
        const newSelected = [...selectedFounderLocationObject.selected, ...filtered];
        setSelectedFounderLocationObject((prevState) => ({
            ...prevState,
            selected: newSelected,
            isHidden: true,
        }));
        onChange(newSelected)
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent default behavior
            e.stopPropagation(); // Stop event propagation
        }
    };

    function handleRemove(itemToRemove) {
        setSelectedFounderLocationObject((prevState) => ({
          ...prevState,
          selected: prevState.selected.filter(
            (item) => item.founder_metro_value !== itemToRemove.founder_metro_value
          ),
        }));
        onChange(selectedFounderLocationObject.selected.filter(
            (item) => item.founder_metro_value !== itemToRemove.founder_metro_value
        ))
      }


    const renderComponent = () => {
        return (
            <Form.Group
                className="mb-3 m-r-5 "
                controlId="formBasicEmail"
            >
                <div className="mb-2">Select Area</div>
                <div className="d-flex flex-wrap">
                    <div
                        className="plusBtn d-flex justify-content-center align-items-center"
                      onClick={() => handlePlus()}
                    >
                        {" "}
                        +{" "}
                    </div>
                    {selectedFounderLocationObject?.selected?.length ? selectedFounderLocationObject?.selected?.map((item, index) => (
                            <span
                                key={index}
                                className="selectedItem  d-flex justify-content-between align-items-center"
                            >
                                {item?.founder_metro_display}{" "}
                                <span
                                    className="closeBtn"
                                  onClick={() => handleRemove(item)}
                                >
                                    {" "}
                                    x{" "}
                                </span>
                            </span>
                        ))
                        : null}
                    {!selectedFounderLocationObject?.isHidden ? (
                        <Typeahead
                            ref={asyncTypeaheadRef}
                            labelKey="founder_metro_display"
                            id={`founder-id`}
                            multiple
                            flip={true}
                            clearButton={false}
                            onBlur={() => handleBlur()}
                            onChange={(val) => handleChange(val)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            options={option || []}
                            selected={selectedFounderLocationObject.selected || []}
                            placeholder="Select"
                            className="mb-3 founder-metro-typeahead-dropdown"
                            useCache={false}
                            maxResults={15}
                            positionFixed={true}
                            renderToken={(option, props, idx) => (
                                <Token className={idx > 0 ? "hidden" : "hidden"}>
                                    <></>
                                </Token>
                            )}
                            renderMenuItemChildren={(option, props) => (
                                <div>{option?.founder_metro_display}</div>
                            )}
                        />
                    ) : null}
                </div>
            </Form.Group>
        );
    }
    return (
        <div>
            { renderComponent() }
        </div>
    );
}

export default FounderMetro;