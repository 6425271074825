import React from 'react';
import './rankingSlider.scss';

const RankingSlider = ({ isOpen, onClose }) => {

  return (
    <div className="ranking-slider">
      <div closeButton style={{ marginTop: '10px' }}></div>
      <div className='ranking-slider-body'>
        <div className='ranking-text'>
          <div className='mb-2'>Think about how you might rate restaurants. When you're deciding which restaurant is "best," you probably consider several different factors, like:</div>
          <ul className='mb-3 star-list'>
            <li>Food quality (on a scale of 1-10)</li>
            <li>Price (on a scale of 1-10, where 10 is most affordable)</li>
            <li>Service (on a scale of 1-10)</li> 
            <li>Atmosphere (on a scale of 1-10)</li>
          </ul>
          <div>Now, different people care about these factors differently.</div> 
          <div>Let's say you really care about food quality, somewhat care about price and service, and don't care much about atmosphere.</div> 
          <div className='mb-2'>You might "weight" these factors like this:</div> 

          <ul className='mb-3 star-list'>
            <li>Food quality: 50% importance</li>
            <li>Price: 25% importance</li>
            <li>Service: 20% importance</li>
            <li>Atmosphere: 5% importance</li>
          </ul>
          
          <div className='mb-1'>To get a final score for each restaurant, you would:</div> 

          <div>Multiply each rating by its importance percentage</div>
          <div className='mb-2'>Add all these numbers together</div>

          <div className='mb-2'>For example, let's score "Restaurant A":</div>

          <div>Food quality: 9 × 50% = 4.5</div>
          <div>Price: 7 × 25% = 1.75</div>
          <div>Service: 8 × 20% = 1.6</div>
          <div>Atmosphere: 6 × 5% = 0.3</div>
          <div className='mb-2'>Final Score = 4.5 + 1.75 + 1.6 + 0.3 = 8.15 out of 10</div>

          <div className='mb-2'>This is exactly how the company ranking system works. Instead of restaurant features, we're looking at company features:</div>

          <div>Instead of "food quality," we might look at "growth rate"</div>
          <div>Instead of "price," we might look at "headcount"</div>
          <div>Instead of "service," we might look at "company age"</div>
          <div className='mb-3'>Instead of "atmosphere," we might look at "location"</div>

          <div>Just like how different people value different aspects of restaurants, this system can be adjusted to care more or less about different company features by changing their weights. If growth is super important, it might get 40% of the weight, while location might only get 5%.</div>
          <div className='mb-2'>The beauty of this system is that it takes many different pieces of information about companies and combines them into one simple score that can be used to rank them, just like we can use our weighted restaurant scores to decide where to eat.</div>

          <div className='mb-3'>Our current ranking system uses the following weights:</div>

          <div>Head Count: 20%</div>
          <div>Company Age: 20%</div>
          <div>Funding: 10%</div>
          <div>Growth 6m, 1y, 2Y combinbed: 10%</div>
          <div>Not in Bay Area: 10%</div>
          <div>Is in North America: 10%</div>
          <div>SaaS?: 10%</div>
          <div className='mb-3'>Private Company: 10%</div>


          <div>Where each of those features measures a specific thing. For example, "funding" will have a higher rating if the company received less than 5M in funding. And funding rating will decrease as more funding was received.</div>
        </div>
      </div>
    </div>
  );
};

export default RankingSlider;
