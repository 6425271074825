import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { useDispatch } from 'react-redux'
import * as actionTypes from "../../store/actionTypes";

import 'antd/dist/antd.css';
import "./customDate.scss"
const {RangePicker} = DatePicker;

const RangePickerWithCustomOptions = ({handleDateRangeChange, selectedDate, HandleCustomDates, className, format }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(()=>{
    dispatch({type:actionTypes?.PICKER_OPEN_STATE, data: isOpen})
  },[isOpen])

  const disabledDate = (current) => {
    // Can not select days after today 
    return current && current > moment().endOf('day');
  };
  const onOpenChange = (open) => {
    setIsOpen(open);
  };

  // const handleCustomDatesFn = (number, type) => {
  //   HandleCustomDates(number, type)
  //   setIsOpen(false);
  // }
  
  return (
      <RangePicker 
      open={isOpen}
      className={`w-100 custome-date ${className}` }
      value={selectedDate} 
      onChange={handleDateRangeChange} 
      format={format ? format : `MM/DD/YYYY`} 
      disabledDate={disabledDate}
      onOpenChange={onOpenChange}
      // renderExtraFooter={()=>
      //   <div className='d-flex justify-content-end'>
      //     <span className='anchorStyle' onClick={()=>  handleCustomDatesFn(7, "d")}>Past Week</span>
      //     <span className='anchorStyle' onClick={()=> handleCustomDatesFn(1, "months")}>1 Month</span>
      //     <span className='anchorStyle' onClick={()=> handleCustomDatesFn(3, "months")}>3 Months</span>
      //     <span className='anchorStyle' onClick={()=> handleCustomDatesFn(6, "months")}>6 Months</span>
      //     <span className='anchorStyle'onClick={()=> handleCustomDatesFn(1, "year")}>1 Year</span>
      //   </div>
      // }
      />
  );
};
 export {RangePickerWithCustomOptions}