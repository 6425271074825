import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { employeeCountTags } from "../../../utils/sliderTags";
import "./tagsSlider.scss";

const TagsInSlider = ({ selectedTag, onChange, type, fieldName }) => {
  const [greaterThanValue, setGreaterThanValue] = useState("");
  const [lessThanValue, setLessThanValue] = useState("");

  useEffect(() => {
    if (selectedTag[0]?.hasOwnProperty("displayName")) {
      setGreaterThanValue(selectedTag?.[0].minCount);
      setLessThanValue(selectedTag?.[0].maxCount);
    } else {
      const greaterThanTag = selectedTag.find(
        (tag) => tag.operator === "greaterThan"
      );
      const lessThanTag = selectedTag.find(
        (tag) => tag.operator === "lessThan"
      );

      if (!greaterThanTag) {
        setGreaterThanValue("");
      } else {
        setGreaterThanValue(greaterThanTag.value);
      }

      if (!lessThanTag) {
        setLessThanValue("");
      } else {
        setLessThanValue(lessThanTag.value);
      }
    }
  }, [selectedTag]);

  const handleClick = (tag) => {
    let newGreaterThanValue = "";
    let newLessThanValue = "";

    const isTagSelected =
      selectedTag &&
      selectedTag.some((selected) => selected.value === tag.value);
    if (isTagSelected) {
      setGreaterThanValue("");
      setLessThanValue("");
    } else {
      if (tag.operator === "<=") {
        newLessThanValue = tag.count.toString();
      } else if (tag.minCount !== undefined && tag.maxCount !== undefined) {
        newGreaterThanValue = tag.minCount.toString();
        newLessThanValue = tag.maxCount.toString();
      } else if (tag.count !== undefined) {
        newGreaterThanValue = tag.count.toString();
      }

      // Update the state with the new input values
      setGreaterThanValue(newGreaterThanValue);
      setLessThanValue(newLessThanValue);
    }
    onChange(tag, fieldName);
  };

  const handleNumericChange = (e, operator) => {
    // if (e.key === 'Enter') {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      if (operator === "greaterThan") {
        setGreaterThanValue(value);
        onChange({ greaterThan: value, lessThan: lessThanValue }, fieldName);
      } else if (operator === "lessThan") {
        setLessThanValue(value);
        onChange({ greaterThan: greaterThanValue, lessThan: value }, fieldName);
      }
      // }
    }
  };
  
  return (
    <div className="mb-3 tags-slider-container">
      <Row className="p-0">
        <Col xs={12} className="d-flex justify-content-center">
          <div className="d-flex slider-tags flex-wrap">
            {type === "numeric" &&
              employeeCountTags.map((tag, index) => (
                <span
                  key={index}
                  className={
                    selectedTag &&
                    selectedTag?.some((selected) => selected.value === tag.value)
                      ? "tagsInSlider active"
                      : "tagsInSlider"
                  }
                  onClick={() => handleClick(tag)}
                >
                  {tag.displayName}
                </span>
              ))}
          </div>
        </Col>
      </Row>
      <Row className="p-0 mt-3">
        <span className="mb-2">Between</span>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center mb-2"
        >
          <Form.Group className="mb-0">
            <Form.Control
              type="text"
              placeholder="Greater than"
              value={greaterThanValue}
              onChange={(e) => handleNumericChange(e, "greaterThan")}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') {
              //     e.preventDefault();
              //     handleNumericChange(e, 'greaterThan');
              //   }
              // }}
            />
          </Form.Group>
          <span className="mx-2">-</span>
          <Form.Group className="mb-0">
            <Form.Control
              type="text"
              placeholder="Less than"
              value={lessThanValue}
              onChange={(e) => handleNumericChange(e, "lessThan")}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter') {
              //     e.preventDefault();
              //     handleNumericChange(e, 'lessThan');
              //   }
              // }}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default TagsInSlider;
