  // sliderTags.js
  export const employeeCountTags = [
    {
      displayName: 'Below 20',
      operator: '<=',
      value: 'below_20',
      count: '20',
    },
    {
      displayName: '20-50',
      operator: '>=',
      value: '20_50',
      minCount: '20',
      maxCount: '50',
    },
    {
      displayName: '50-100',
      operator: '>=',
      value: '50_100',
      minCount: '50',
      maxCount: '100',
    },
    {
      displayName: '100-200',
      operator: '>=',
      value: '100_200',
      minCount: '100',
      maxCount: '200',
    },
    {
      displayName: '200-500',
      operator: '>=',
      value: '200_500',
      minCount: '200',
      maxCount: '500',
    },
    {
      displayName: '500-1000',
      operator: '>=',
      value: '500_1000',
      minCount: '500',
      maxCount: '1000',
    },
    {
      displayName: 'Above 1000',
      operator: '>=',
      value: 'above_1000',
      count: '1000',
    },
  ];
  
  export const percentageTags = [
    {
      displayName: 'Under 10%',
      operator: '<=',
      value: 'under_10_percent',
      count: 10,
    },
    {
      displayName: 'Under 50%',
      operator: '<=',
      value: 'under_50_percent',
      count: 50,
    },
    {
      displayName: 'Above 50%',
      operator: '>=',
      value: 'above_50_percent',
      count: 50,
    },
  ];
  