import Highcharts from 'highcharts/highstock';
import Highmaps from 'highcharts/highmaps';
import mapModule from 'highcharts/modules/map';
import accessibilityModule from 'highcharts/modules/accessibility';

// Initialize the Highcharts modules
accessibilityModule(Highcharts);
mapModule(Highcharts);


function formatNumber(number) {
    if (number === "n/a") {
      return "n/a";
    }
    if (number >= 1000000000) {
      return (number / 1000000000)?.toFixed(2) + "B";
    } else if (number >= 1000000) {
      return (number / 1000000)?.toFixed(2) + "M";
    } else if (number >= 1000) {
      return (number / 1000)?.toFixed(2) + "K";
    } else {
      return number?.toString();
    }
  }

// Function to generate a bar graph
export function generateBarGraph(container, data, onBarClick) {
    if (!container) {
        console.error(`Container not found`);
        return;
    }

    let chartHeight = '240px';
    if (data.length <= 2) {
        chartHeight = '160px';
    } else if (data.length <= 4) {
        chartHeight = '200px';
    }

    let selectedBarIndex = 0;

    const chartOptions = {
        chart: {
            type: 'bar',
            height: chartHeight,
            width: 530,
            marginLeft: 150,
            backgroundColor: 'transparent',
        },
        title: { text: '' },
        yAxis: {
            title: { text: null },
            labels: { enabled: false },
            gridLineWidth: 1,
            gridLineColor: '#D7D0FFB2',
            tickAmount: 9,
            lineWidth: 1,
        },
        xAxis: {
            categories: data.map(d => d.name),
            title: { text: null },
            lineWidth: 1,
            gridLineWidth: 0,
            labels: {
                style: {
                    fontFamily: 'Avenir-Regular',
                    fontSize: '14px',
                    color: '#fff',
                },
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        const point = this;
                        return `<div style="display: flex; justify-content: space-between; width: 100%;">
                                    ${point?.point?.count && point?.point?.count !== "n/a" ? `<span style="color: #fff; font-family: 'Avenir-Regular'; font-size: 13px; font-weight: 500; margin-right:4px ">${formatNumber(point?.point?.count) + " "} </span>` : ''}
                                    <span style="color: #fff; font-family: 'Avenir-Regular'; font-size: 13px; font-weight: 500;"> (${point.y}%)</span>
                                </div>`;
                    },
                    align: 'left',
                    verticalAlign: 'middle',
                    x: 8,
                },
                pointWidth: 17,
                groupPadding: 0.2,
                borderRadius: [0, 4, 4, 0],
                showInLegend: false,
                states: {
                    hover: { color: 'rgb(25, 183, 251)' },
                },
                events: {
                    click: function (event) {
                        const selectedBarName = event?.point?.options?.name;
                        onBarClick(selectedBarName);
                        selectedBarIndex = event.point.index;
                        chart.series[0].update({
                            data: data.map((item, index) => ({
                                ...item,
                                color: index === selectedBarIndex ? 'rgb(170,170,170)' : 'rgb(220, 220, 220)',
                            })),
                        });
                    },
                },
            },
        },
        tooltip: false,
        series: [{ data: data.map((item, index) => ({ ...item, color: index === selectedBarIndex ? 'rgb(170,170,170)' : 'rgb(220, 220, 220)' })), colorByPoint: true }],
        credits: { enabled: false },
    };

    const chart = Highcharts.chart(container, chartOptions);
}


// Function to generate a line graph
export function generateLineGraph(container, data) {
    if (!container) {
        console.error("Container not found");
        return;
    }

    function sanitizeData(value) {
        return value > 0 ? Math.round(value) : 0;
    }

    // Process data for series
    const seriesDataCount = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: sanitizeData(entry.raw_count),
    }));

    const seriesDataInflow = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: sanitizeData(entry.raw_inflow),
    }));

    const seriesDataOutflow = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: sanitizeData(entry.raw_outflow),
    }));

    const allValues = [...seriesDataCount, ...seriesDataInflow, ...seriesDataOutflow].map(point => point.y);
    const max = Math.max(...allValues);
    const min = Math.min(...allValues);

    const dateRange = data?.map(entry => new Date(entry.metric_recorded_on).getTime());
    const minDate = Math.min(...dateRange);
    const maxDate = Math.max(...dateRange);
    const now = new Date().getTime();

    const earliestDate = minDate;
    const latestDate = maxDate;
    const hasTwoYearData = (latestDate - earliestDate) >= (2 * 365 * 24 * 3600 * 1000);

    function updateXAxisLabels(chart, rangeType, count) {
        let format = '{value:%Y}';
        let tickInterval = 365 * 24 * 3600 * 1000; // Default: 1 year interval

        if (rangeType === 'month') {
            format = '{value:%b %Y}';
            tickInterval = 30 * 24 * 3600 * 1000;
        } else if (rangeType === 'year') {
            format = '{value:%b %Y}';
            tickInterval = count === 1 ? 6 * 30 * 24 * 3600 * 1000 : 12 * 30 * 24 * 3600 * 1000;
        } else if (rangeType === 'all') {
            tickInterval = undefined;
        }

        chart?.xAxis[0]?.update({
            labels: { format, step: 1, },
            tickInterval,
            endOnTick: false,
            tickPositioner: function () {
                const extremes = this.chart?.xAxis[0]?.getExtremes();
            
                if (extremes.dataMin === null || extremes.dataMax === null) {
                    console.warn("No visible series. Returning default ticks.");
                    return this.tickPositions;
                }
            
                let positions = this.tickPositions?.filter(pos => pos <= maxDate);
                // Ensure the last tick is within range but not forced
                const lastTick = positions[positions?.length - 1];
                // Ensure last tick is maxDate only for 1-year & 2-year ranges
                if ((rangeType === 'year' && (count === 1 || count === 2)) && lastTick !== maxDate) {
                    positions?.push(maxDate);
                }
                // Remove last tick if it exceeds maxDate
                else if (lastTick > maxDate) {
                    positions?.pop();
                }
                return positions;
            }
        });
    }

    const buttons = [
        { type: 'month', count: 1, text: '1M' },
        { type: 'month', count: 6, text: '6M' },
        { type: 'year', count: 1, text: '1Y' },
        { type: 'year', count: 2, text: '2Y' },
        { type: 'all', text: 'All' }
    ];

    let selectedRange = hasTwoYearData 
        ? buttons?.findIndex(b => b.count === 2 && b.type === 'year') 
        : buttons?.findIndex(b => b.type === 'all');
    let resetZoomButton = null;

    const chart = Highcharts.stockChart(container, {
        chart: {
            type: 'spline',
            height: 270,
            backgroundColor: 'transparent',
            zoomType: 'x',
            spacingRight: 25,
            events: {
                selection(event) {
                    if (event.resetSelection) {
                        chart.rangeSelector.clickButton(selectedRange, true);
                        if (resetZoomButton) resetZoomButton.hide();
                    } else {
                        chart.xAxis[0].update({
                            tickInterval: 30 * 24 * 3600 * 1000,
                            labels: { format: '{value:%b %Y}' }
                        });
                        if (!resetZoomButton) {
                            resetZoomButton = chart.renderer.button('Reset Zoom', chart.plotLeft + 180, chart.plotTop - 46)
                                .attr({
                                    zIndex: 5,
                                    fill: 'rgba(255,255,255, 0.2)',
                                    stroke: 'none',
                                    padding: 6,
                                    r: 4,
                                    cursor: 'pointer'
                                })
                                .css({
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    fontWeight: 'light',
                                    background: 'rgba(255, 255, 255, 0.2)',
                                    border: '1px solid #ffffff',
                                    borderRadius: '4px'
                                })
                                .on('click', function () {
                                    chart.zoomOut();
                                    chart.rangeSelector.clickButton(selectedRange, true);
                                    updateXAxisLabels(chart, buttons[selectedRange].type, buttons[selectedRange].count);
                                    resetZoomButton.hide();
                                })
                                .add();
                        } else {
                            resetZoomButton.show();
                        }
                    }
                }
            }
        },
        credits: { enabled: false },
        title: { text: '' },
        xAxis: {
            type: 'datetime',
            labels: { style: { color: '#fff' } },
            min: minDate,
            max: maxDate,
            endOnTick: true,
            showLastLabel: true
        },
        yAxis: {
            title: { text: '', style: { color: '#fff' } },
            labels: { style: { color: '#fff' } },
            min: min,
            max: max,
            opposite: false,
            gridLineWidth: 0.6,
            gridLineColor: '#1B5480',
            endOnTick: true,
            startOnTick: true,
            showLastLabel: true
        },
        legend: {
            enabled: true,
            itemStyle: { color: '#fff', fontWeight: 'normal' },
            itemHoverStyle: { color: '#fff' },
        },
        tooltip: {
            enabled: true,
            useHTML: true,
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            shared: true,
            formatter: function () {
                if (!this.points) return '';

                const date = new Date(this.x);
                const year = date.getFullYear();
                const month = date.toLocaleString('default', { month: 'short' });

                const nameMapping = {
                    'Total Population': 'Total',
                    'Employee Joined': 'Joined',
                    'Employee Left': 'Left'
                };

                let tooltipData = `<div style="font-family: Arial, sans-serif; border-radius: 6px; overflow: hidden; width: 120px;">
                    <div style="background-color: #e5f1f8; color: black; font-weight: bold; text-align: center; padding: 8px; font-size: 13px;">
                        Year - ${year}
                    </div>
                    <div style="background-color: white; color: #808080; padding: 8px; text-align: left; font-size: 13px;">
                        <div style="display: flex; justify-content: space-between; margin-bottom: 6px;"><span>Month:</span> <span>${month}</span></div>`;

                this.points.forEach(point => {
                    const shortName = nameMapping[point.series.name] || point.series.name;
                    tooltipData += `<div style="display: flex; justify-content: space-between; margin-bottom: 6px;">
                        <span>${shortName}:</span> <span>${Math.round(point.y)}</span>
                    </div>`;
                });

                tooltipData += `</div></div>`;
                return tooltipData;
            }
        },
        rangeSelector: {
            selected: selectedRange,
            inputEnabled: false,
            labelStyle: { display: 'none' },
            buttonPosition: { align: 'right', x: 0, y: -11},
            buttonTheme: {
                fill: 'rgba(255,255,255,0.2)',
                stroke: 'none',
                r: '4px',
                style: {
                    color: '#FFFFFF',
                    fontWeight: 'normal',
                    padding: '8px 12px',
                    marginRight: '10px'
                },
                states: {
                    hover: { fill: 'rgba(255, 255, 255, 0.1)', style: { color: '#ffffff' } },
                    select: { fill: '#ffffff', style: { color: '#024C86' } }
                }
            },
            buttons: buttons?.map((button, index) => ({
                type: button.type,
                count: button.count,
                text: button.text,
                events: {
                    click: function () {
                        selectedRange = index;
                        let rangeEnd = maxDate;
                        let rangeStart = new Date(rangeEnd);
        
                        if (button.type === 'month') {
                            rangeEnd = maxDate;
                            rangeStart = new Date(rangeEnd);
                            rangeStart.setMonth(rangeStart.getMonth() - button.count);
                        } else if (button.type === 'year') {
                            rangeStart.setFullYear(rangeStart.getFullYear() - button.count);
                            rangeStart.setMonth(0);
                        } else if (button.type === 'all') {
                            rangeStart = new Date(minDate);
                            rangeEnd = new Date(maxDate).getTime();
                        }
        
                        chart.xAxis[0].setExtremes(rangeStart.getTime(), rangeEnd);
                        updateXAxisLabels(chart, button.type, button.count);
                    }
                }
            }))
        },
        navigator: { enabled: false },
        scrollbar: { enabled: false },
        series: [
            {
                name: 'Total Population',
                type: 'spline',
                data: seriesDataCount,
                visible: true,
                zIndex: 3,
                lineWidth: 1.6
            },
            {
                name: 'Employee Joined',
                type: 'spline',
                data: seriesDataInflow,
                color: "#61D96B",
                visible: true,
                zIndex: 1,
                opacity: 0.85
            },
            {
                name: 'Employee Left',
                type: 'spline',
                data: seriesDataOutflow,
                color: "#EF5353",
                visible: true,
                zIndex: 1,
                opacity: 0.85
            }
        ]
    });

    updateXAxisLabels(chart, buttons[selectedRange].type, buttons[selectedRange].count);
}

