import * as actionTypes from "../actionTypes";
import {getAuthToken} from "../../utils"
import axios from "axios";


export const getScopers = (axiosInstance) => (dispatch) => {
	axiosInstance({
		method: "GET",
		data: {},
		params: {},
		headers: {
			"Authorization": getAuthToken()
		},

	})
		.then((res) => {
			dispatch(setScopers(res));
		})
		.catch((err) => {
			console.log("Response - error -> ", err);
		});
};

const setScopers = (data) => ({
	type: actionTypes.SET_SCOPERS,
	data,
});

export const getCompanySearchResult = (axiosInstance, query) => (dispatch) => {
	return axiosInstance({
		method: "GET",
		data: {},
		params: {...query},
		headers: {
			"Authorization": getAuthToken()
		},

	})
		.then((res) => {
			return {status: "success", data: res?.data}
		})
		.catch((err) => {
			return {status: "failed", data: {}, errorMsg: err?.response?.data?.message || "Something went wrong!"}
		});
};



export const getNoteTemplate = (axiosInstance) => (dispatch) => {
	return axiosInstance({
		method: "GET",
		data: {},
		params: {},
		headers: {
			"Authorization": getAuthToken()
		},

	})
		.then((res) => {
			dispatch(setNoteTemplate(res?.data?.data))
			return {status: "success", }
		})
		.catch((err) => {
			return {status: "failed", data: {}, errorMsg: err?.response?.data?.message || "Something went wrong!"}
		});
};

const setNoteTemplate = (data) => ({
	type: actionTypes.SET_NOTE_TEMPLATE,
	data,
});

export const setSubActionItemSubmissions = (data) => (dispatch) => dispatch({type: actionTypes.SET_SUB_ACTION_ITEM_SUBMISSIONS,data})

export const setWorkboardItem = (data) => (dispatch) => dispatch({type:actionTypes.SET_WORKBOARD_ITEM, data})

// Discovery Slider Actions
export const getPipelineTpStatusOptions = (axiosInstance, data = {}) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { ...data },
	  headers: {
		Authorization: getAuthToken(),
	  }
	})
	  .then((res) => {
		dispatch(setTpStatusOptions(res.data));
		return { status: "success" };
	  })
	  .catch((err) => {
		return { status: "failed" };
	  });
};
  
  const setTpStatusOptions = (data) => ({
	type: actionTypes.COMMON_TP_STATUS_OPTIONS,
	data
  });
  
export const getTpOwnerOptions = (axiosInstance, data = {}) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { ...data },
	  headers: {
		Authorization: getAuthToken(),
	  }
	})
	  .then((res) => {
		dispatch(setTpOwnerOptions(res.data));
		return { status: "success" };
	  })
	  .catch((err) => {
		return { status: "failed" };
	  });
};
  
  const setTpOwnerOptions = (data) => ({
	type: actionTypes.COMMON_TP_OWNER_OPTIONS,
	data
});
  
export const getListsAndBucketsOptions = (axiosInstance, data = {}) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { ...data },
	  headers: {
		Authorization: getAuthToken(),
	  }
	})
	  .then((res) => {
		dispatch(setListsAndBucketsOptions(res.data));
		return { status: "success" };
	  })
	  .catch((err) => {
		return { status: "failed" };
	  });
};
  
const setListsAndBucketsOptions = (data) => ({
	type: actionTypes.COMMON_LISTS_BUCKETS_OPTIONS,
	data
});

export const getCompanyLists = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { domain },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

export const getCompanyNoCachedData = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { domain },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

export const getCompanyCachedData = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { domain },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

export const getFounderData = (axiosInstance, domain, name) => (dispatch) => {
	return axiosInstance({
	  method: "POST", 
	  data: { domain: domain, name: name },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		dispatch(setFounderData(res.data));
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

const setFounderData = (data) => ({
	type: actionTypes.GET_FOUNDER_DATA,
	data,
});
  
export const getAdvertisingStats = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { domain: domain},
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

export const getCompanyCompetitors = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { domain },
	  headers: {
		Authorization: getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

export const getNewAdvertisingStats = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "POST",
	  data: { domain },
	  headers: {
		Authorization: getAuthToken(),
	  },
	})
	.then((res) => {
	  return { status: "success", data: res.data };
	})
	.catch((err) => {
	  return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	});
};

export const getNewCompetitors = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "POST",
	  data: { domain },
	  headers: {
		Authorization: getAuthToken(),
	  },
	})
	.then((res) => {
	  return { status: "success", data: res.data };
	})
	.catch((err) => {
	  return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	});
};
  
export const getNewCompanyFounders = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "POST",
	  data: { domain },
	  headers: {
		Authorization: getAuthToken(),
	  },
	})
	.then((res) => {
	  return { status: "success", data: res.data };
	})
	.catch((err) => {
	  return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	});
};

export const removeCompanyFromList = (axiosInstance) => (dispatch) => {
	return axios.delete(axiosInstance, {
	  headers: { Authorization: getAuthToken() },
	})
	  .then((res) => {
		return { status: "success" };
	  })
	  .catch((err) => {
		return { 
		  status: "failed", 
		  errorMsg: err?.response?.data?.message || "Something went wrong!" 
		};
	  });
};
  
export const assignBucketToCompany = (axiosInstance, data = {}) => (dispatch) => {
	return axiosInstance({
	  method: "POST",
	  data: { ...data },
	  headers: {
		Authorization: getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { 
		  status: "failed", 
		  errorMsg: err?.response?.data?.message || "Something went wrong!" 
		};
	  });
};

export const getQuipsData = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { company_domain: domain },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

export const getKnowledgeSource = (axiosInstance, source_type, source) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { source_type: source_type, source: source },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		const contentType = res.headers['content-type'];
		if (contentType && contentType.includes('text/html')) {
		  return { status: "success", data: res.data };
		}
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};
  

export const getMentionsData = (axiosInstance, domain) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { company_domain: domain },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};

export const getCTOLunchesData = (axiosInstance, msg_id) => (dispatch) => {
	return axiosInstance({
	  method: "GET",
	  params: { msg_id: msg_id },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	.then((res) => {
	return { status: "success", data: res.data };
	})
	.catch((err) => {
	return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	});
};

export const getWebsiteHistory = (axiosInstance, companyDomain) => (dispatch) => {
	return axiosInstance({
	  method: "POST",
	  data: { domain: companyDomain },
	  headers: {
		"Authorization": getAuthToken(),
	  },
	})
	  .then((res) => {
		return { status: "success", data: res.data };
	  })
	  .catch((err) => {
		return { status: "failed", errorMsg: err?.response?.data?.message || "Something went wrong!" };
	  });
};