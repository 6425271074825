import * as actionTypes from '../actionTypes'

const INITIAL_STATE = {
  filterSearchData: [],
  filterLegend: {},
  combinedFilterData:{},
  linkedinCities: [],
  linkedinStates: [],
  routeData: {},
  combinedMetrics: {},
  sources: [],
  tpOwner: [],
  tpOwners: [],
  tpStatus: [],
  tpFilterOps: {},
  appliedColumns: [],
  discoveryTpStatusOptions: {},
  discoveryCompanies: {},
  filteredData: {},
  metadata: {},
  filtersPayload: {},
  llmFilterData: {},
  discoveryWatchStatus: null,
  urlDiscoveryParams: {},
  searchHistory: {},
  searchFilterApplied: [],
  quipTypes: [],
  selectedListsData: {},
  allDataSources: []
}

const discoveryReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_SOURCES:
      return {
        ...state,
        sources: [...action.data],
      }
    case actionTypes.GET_COMBINED_METRICS:
      return {
        ...state,
        combinedMetrics: {...state?.combinedMetrics, ...action.data},
      }
    case actionTypes.SET_FILTER_METRICS:
      return {
        ...state,
        filterSearchData: [...action.data],
      }
    case actionTypes.SET_FILTER_METRICS_LEGENDS:
      return {
        ...state,
        filterLegend: {...action.data},
      }
    case actionTypes.SET_COMBINED_METRICS:
      return {
        ...state,
        combinedFilterData: {...action.data},
      }
    case actionTypes.SET_LINKEDIN_CITIES:
      return {
        ...state,
        linkedinCities: [...action.data],
      }
    case actionTypes.SET_LINKEDIN_STATES:
      return {
        ...state,
        linkedinStates: [...action.data],
      }
    case actionTypes.SET_TP_OWNER:
      return {
        ...state,
        tpOwner: [...action.data],
      }
    // case actionTypes.SET_TP_STATUS:
    //   return {
    //     ...state,
    //     tpStatus: [...action.data],
    //   }
    case actionTypes.SET_TP_FILTER_OPS:
      return {
        ...state,
        tpFilterOps: {...action.data},
      }
    case actionTypes.SET_COMPANY_ROUTE_DATA:
      return {
        ...state,
        routeData: {...action.data},
      }
    case actionTypes.RESET_FILTER:
      return {
        ...INITIAL_STATE
      }
    case actionTypes.SET_APPLIED_COLUMN:
      return {
        ...state,
        appliedColumns: [...action.data],
      }
    case actionTypes.DISCOVERY_TP_STATUS_OPTIONS:
      return {
        ...state,
        discoveryTpStatusOptions: { ...action.data }
      }
    case actionTypes.SET_DISCOVERY_COMPANY: 
      return {
         ...state,
          discoveryCompanies: action.data
      }
    case actionTypes.RESET_DISCOVERY_FILTERS: 
      return {
         ...state,
          discoveryCompanies: {}
      }
      case actionTypes.SET_DISCOVERY_TP_OWNER:
        return {
          ...state,
          tpOwners: action.data,
        };
      case actionTypes.SET_FILTERED_DATA:
        return {
          ...state,
          filteredData: action.payload,
        }; 
      case actionTypes.GET_DISCOVERY_METADATA:
        return {
          ...state,
          metadata: action.data,
        };
      case actionTypes.SET_DISCOVERY_FILTERS_PAYLOAD:
        return {
          ...state,
          filtersPayload: action.payload,
        };
      case actionTypes.SET_LLM_FILTERS:
          return {
            ...state,
            llmFilterData: action.data,
          };
      case actionTypes.RESET_LLM_FILTERS:
        return {
          ...state,
          llmFilterData: {}
        };
      case actionTypes.SET_DISCOVERY_WATCH_STATUS:
        return {
          ...state,
          discoveryWatchStatus: action.data,
        };
      case actionTypes.SET_URL_DISCOVERY_PARAMS:
        return {
          ...state,
          urlDiscoveryParams: action.data,
        };
      case actionTypes.SET_DISCOVERY_SEARCH_HISTORY:
        return {
          ...state,
          searchHistory: action.data,
        };
      case actionTypes.SET_APPLIED_FILTERS:
        return {
          ...state,
          searchFilterApplied: [
            ...state.searchFilterApplied,
            {
              filters: action.payload.filteredObject,
              totalCount: action.payload.total_count,
            },
          ],
        };
      case actionTypes.RESET_APPLIED_FILTERS:
          return {
            ...state,
            searchFilterApplied: [],
          };
      case actionTypes.SET_QUIP_TYPES:
          return {
            ...state,
            quipTypes: action.data,
          }; 
      case actionTypes.SET_SELECTED_LIST_OPTION:
        return {
          ...state,
          selectedListsData: {
            ...state.selectedListsData,
            [action.payload.listId]: {
              data: action.payload.data,
              filterstring: action.payload.filterString,
              filterObject: action.payload.filteredObject
            }
          }
        };
      case actionTypes.RESET_SELECTED_LIST_OPTION:
          return {
            ...state,
            selectedListsData: {},
          }; 
      case actionTypes.SET_ALL_DATA_SOURCES:
        return {
          ...state,
          allDataSources: action.data,
        };
      
    default:
      return {
        ...state,
      }
  }
}

export { discoveryReducer }
