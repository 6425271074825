import * as actionTypes from "../actionTypes";


const INITIAL_STATE = {
  companyupdate: null,
  filterUpdate: {},
  tpPipeline: {},
  filterType: null,
  userInfo: null,
  companyDataLoading: false,
  dasboardInsightsCard: {},
  dashboardPipelineManagement: {},
  dashboardPipelineManagementViewAllLink: {},
  employeePopulation: []
};

const dashBoardReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DETAILS:
      return {
        ...state,
      };
    case actionTypes.SET_TP_PIPELINE:
      return {
        ...state,
        tpPipeline: action.data,
      };
    case actionTypes.SET_LOGGED_IN_USER_INFO:
      return {
        ...state,
        userInfo: { ...action.data },
      };
    case actionTypes.DASHBOARD_INSIGHTS_CARD:
      return {
        ...state,
        dasboardInsightsCard: { ...action.data },
      };

    case actionTypes.DASHBOARD_PIPELINE_MANAGEMENT:
      return {
        ...state,
        dashboardPipelineManagement: { ...action.data },
      };
    case actionTypes.DASHBOARD_PIPELINE_MANAGEMENT_VIEWALL_LINK:
      return {
        ...state,
        dashboardPipelineManagementViewAllLink: { ...action.data },
      };
    case actionTypes.SET_EMPLOYEE_POPULATION:
        return {
          ...state,
          employeePopulation: [...action.data],
        };
    default:
      return state;
  }
};
const companyUpdateReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.COMPANY_UPDATE:
      return {
        ...state,
        companyupdate: { ...action.data },
      };
    case actionTypes.COMPANY_FILTER_UPDATE:
      return {
        ...state,
        filterUpdate: { ...action.data },
      };
    case actionTypes.COMPANY_UPDATE_LOADING:
      return {
        ...state,
        companyDataLoading: action.data,
      };
    case actionTypes.COMPANY_UPDATE_TYPE:
      return {
        ...state,
        filterType: { ...action.data },
      };

    default:
      return {
        ...state,
      };
  }
};

export { dashBoardReducer, companyUpdateReducer };
