import commonApiList from "./baseUrl";
import appConfig from "@appConfig";

// const host = window.location.protocol + "//" + window.location.host;
// const env =
//   host && host.includes("orion-dev") || host.includes("orionui-dev") || host.includes("awseb-awseb-lcd7jZjp2uPi-1890919665.us-east-1.elb.amazonaws.com")
//     ? "STAGING"
//     : host &&
//       (host.includes("orionui-prod") ||
//         host.includes("orion.telescopepartners.com") ||
//         host.includes("orion.telescope.vc") || host.includes("awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com"))
//     ? "PROD"
//     : "DEV";
const env = process.env.npm_config_env === "PROD" ? "PROD" : "DEV"
let SERVER_ADDRESS = (SERVER_ADDRESS = appConfig.api[env].SERVER_ADDRESS);

function createApiInstance(type) {
  const apiList = commonApiList;
  const url = apiList[type];
  const baseURL = SERVER_ADDRESS + url;
  return {
    baseURL,
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
  };
}

export default createApiInstance;
