// Total Funding Tags
export const totalFundingConstans = {
    seedling: {
      server_value: 'seedling',
      // displayName: 'Seedling',
      displayName: 'Under $500K',
      description: 'Companies with funding less than or $500K',
      operator: 'LESS_THAN_OR_EQUAL_TO',
      value: 500000,
    },
    emerging: {
      server_value: 'emerging',
      // displayName: 'Emerging',
      displayName: '$500K - $2M',
      description: 'Companies past the initial seed stage, possibly including early venture rounds $500K - $2M',
      operator: 'RANGE',
      minValue: 500000,
      maxValue: 2000000,
    },
    rising: {
      server_value: 'rising',
      // displayName: 'Rising',
      displayName: '$2M - $10M',
      description: '$2M - $10M',
      operator: 'RANGE',
      minValue: 2000000,
      maxValue: 10000000,
    },
    growth: {
      server_value: 'growth',
      // displayName: 'Growth',
      displayName: '$10M - $50M',
      description: '$10M - $50M',
      operator: 'RANGE',
      minValue: 10000000,
      maxValue: 50000000,
    },
    market_shapers: {
      server_value: 'market_shapers',
      // displayName: 'Market Shapers',
      displayName: '$50M - $200M',
      description: '$50M - $200M',
      operator: 'RANGE',
      minValue: 50000000,
      maxValue: 200000000,
    },
    unicorns: {
      server_value: 'unicorns',
      // displayName: 'Unicorns',
      displayName: '$200M - $1B',
      description: '$200M - $1B',
      operator: 'RANGE',
      minValue: 200000000,
      maxValue: 1000000000,
    },
    titans: {
      server_value: 'titans',
      // displayName: 'Titans',
      displayName: '$1B+',
      description: '$1B+',
      operator: 'GREATER_THAN_OR_EQUAL_TO',
      value: 1000000000,
    },
  };
  
// Email Constants
export const emailConstants = [
    {
      // displayName: 'Freshly Pinged',
      displayName: 'Under 1m',
      server_value: 'freshly-pinged',
      value: 'less_than_month',
      description: 'Less than a month ago',
    },
    {
      // displayName: 'Quarterly',
      displayName: 'Under 3m',
      server_value: 'quarterly',
      value: 'less_than_quarter',
      description: 'Less than 3 months ago',
    },
    {
      // displayName: 'Recent Revelations',
      displayName: 'Between 3m - 6m',
      server_value: 'recent-revelations',
      value: 'less_than_six_month',
      description: '3 - 6 months ago',
    },
    {
      // displayName: 'Mid-Year Milestones',
      displayName: 'Between 6m - 1y',
      server_value: 'mid-year-milestones',
      value: 'less_than_year',
      description: '6 months - 1 year ago',
    },
    {
      // displayName: 'Annual Anchors',
      displayName: 'Between 1y - 1.5y',
      server_value: 'annual-anchors',
      value: 'less_than_two_year',
      description: '1 year - 1.5 years ago',
    },
    {
      // displayName: 'Biennial Beginnings',
      displayName: 'Between 1.5y - 2y',
      server_value: 'biennial-beginnings',
      value: 'greater_than_two_year',
      description: '1.5 years - 2 years ago',
    },
    {
      // displayName: 'Biennial',
      displayName: 'Above 2y',
      server_value: 'biennial',
      value: 'greater_than_two_year',
      description: '2 years and above',
    },
  ];

  //Employee Insight Constants
  export const employeeInsightConstants = [
    {
      displayName: 'Company Size',
      server_value: 'employee_count',
    },
  ];

  export const infoMessage = {
    founder_location_search: "Search for companies based on where the Founder resides. The location is derived from their linkedin profile.",
    founder_location_search: "Search for companies based on where the Founder resides. The location is derived from their linkedin profile.",
    company_description_search: "Search for free text within company descriptions. For ex: you can search for words like generative ai, non profit software etc."
  }

export const totalFundingInfoIcon = "We will show all the companies that match all of the other filters set by you (including the companies that matches your set funding range). But there are companies in Orion that might match your other filters for whom we don't have any funding data captured. Setting this filter as YES, will add those companies to the list as well, so that you can still see them. Setting this as NO, will not show the companies we don't have the funding data in Orion."