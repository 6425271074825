import axios from 'axios';
import * as actionTypes from "../actionTypes";
import {getAuthToken} from "../../utils"

export const getCombinedSource = (url, type) => (dispatch) => {
	return axios.get(`${url}`, {
		headers: {
			"Authorization": getAuthToken()
		},
	})
    .then((res) => {
      let newOb = {}
      newOb[type] = res?.data?.results
      dispatch(setSourceMetrics(newOb));
      return { status: "success" }
    })
		.catch((err) => {
			return {status: "failed", data: {}}
		});
};

const setSourceMetrics = (data,) => ({
    type: actionTypes.GET_COMBINED_METRICS,
    data,
  });

  export const getAllSources = (axiosInstance) => (dispatch) => {
   axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({type: actionTypes.SET_SOURCES, data:res?.data?.data});
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}
export const submitFilterMetrics = (axiosInstance, payload) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    data: { ...payload },
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch(setFilterMetrics(res?.data?.results));
      dispatch(setFilterMetricsLegeds(res?.data?.legend));
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

const setFilterMetrics = (data, type) => ({
  type: actionTypes.SET_FILTER_METRICS,
  data,
});

const setFilterMetricsLegeds = (data, type) => ({
  type: actionTypes.SET_FILTER_METRICS_LEGENDS,
  data,
});

export const getCombinedFilter = (axiosInstance, payload) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {...payload},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch(setCombinedMetrics(res?.data));
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

const setCombinedMetrics = (data, type) => ({
  type: actionTypes.SET_COMBINED_METRICS,
  data,
});

export const getCities = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LINKEDIN_CITIES,
        data: res?.data?.results,
      });
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

export const getStates = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LINKEDIN_STATES,
        data: res?.data?.results,
      });
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

export const setRouteData = (data) => ({
  type: actionTypes.SET_COMPANY_ROUTE_DATA,
  data,
});

export const getTPOwner = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TP_OWNER,
        data: res?.data?.results,
      });
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

export const getTPStatus = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TP_STATUS,
        data: res?.data?.results,
      });
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

//DiscoveryNew Action for Tp Status
export const getTpStatusDiscoveryOptions = (axiosInstance,data={}) => (dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{...data},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    dispatch(setTpStatusOptions(res.data))
    return {status:"success"}
  })
  .catch((err) => {
    return { status: "failed" };
  })
}
const setTpStatusOptions = (data) => ({
  type:actionTypes.DISCOVERY_TP_STATUS_OPTIONS,
  data
})

export const getTPFilterOPS = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TP_FILTER_OPS,
        data: res?.data?.results,
      });
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

export const getDiscoveryCompanies =
(url, cancelToken) => (dispatch) => {
    return axios.get(url, {
      headers: {
        Authorization: getAuthToken()
      },
      cancelToken: cancelToken?.token
  })
    .then((res) => {
      if (res.hasOwnProperty("error")) {
        return { status: "failed", errMsg:res?.error};
      } else if (res.hasOwnProperty("code") && res?.code === 422) {
        return { status: "failed", errMsg:res?.name};
      } else {
        dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: res?.data});
        return { status: "success", data: res?.data};
      }
    })
    .catch((err) => {
      // console.warn("Response - error -> ", err);
      if(err.code === "ERR_CANCELED"){
        return { status: "ERR_CANCELED" };
      } else {
        return { status: "failed" };
      }
    });
};

export const updateDiscoveryCompanies =
(url, cancelToken) => (dispatch) => {
    return axios.get(url, {
      headers: {
        Authorization: getAuthToken()
      },
      cancelToken: cancelToken?.token
  })
    .then((res) => {

      if (res.hasOwnProperty("error")) {
        return { status: "failed", errMsg:res?.error};
      } else if (res.hasOwnProperty("code") && res?.code === 422) {
        return { status: "failed", errMsg:res?.name};
      } else {
        return { status: "success", data: res?.data};
      }
    })
    .catch((err) => {
      // console.warn("Response - error -> ", err);
      if(err.code === "ERR_CANCELED"){
        return { status: "ERR_CANCELED" };
      } else {
        return { status: "failed" };
      }
    });
};

export const getDiscoveryTpOwners = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: { ...params },
    headers: {
      "Authorization": getAuthToken()
    }
  })
    .then((res) => {
      const people = res?.data?.data || [];
      dispatch({ type: actionTypes.SET_DISCOVERY_TP_OWNER, data: people });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const setFilteredData = (data) => ({
  type: actionTypes.SET_FILTERED_DATA,
  payload: data,
});

export const getDiscoveryFiltersMetaData = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: { ...params },
    headers: {
      "Authorization": getAuthToken()
    }
  })
    .then((res) => {
      const metadata = res?.data || [];
      dispatch({ type: actionTypes.GET_DISCOVERY_METADATA, data: metadata });
      return {status: 'success'};
    })
    .catch((err) => {
      console.error(err);
      return {status: 'failed'};
    });
};

export const setDiscoveryFiltersPayload = (filtersPayload) => ({
  type: actionTypes.SET_DISCOVERY_FILTERS_PAYLOAD,
  payload: filtersPayload, 
});


//DiscoveryNew Action for Companies
export const getHQLookup =
(url) => (dispatch) => {
    return axios.get(url, {
      headers: {
        Authorization: getAuthToken()
      }
  })
    .then((res) => {
      if (res.hasOwnProperty("error")) {
        return { status: "failed", errMsg:res?.error};
      } else if (res.hasOwnProperty("code") && res?.code === 422) {
        return { status: "failed", errMsg:res?.name};
      } else {
        // dispatch({ type: actionTypes.SET_DISCOVERY_COMPANY, data: res?.data});
        return { status: "success", data: res?.data};
      }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data);
      if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
        return { status: "failed", errMsg:err?.response?.data?.name};
      } else {
        return { status: "failed" };
      }
    });
};

export const getLLMFiltersData = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: { ...params },
    headers: {
      "Authorization": getAuthToken()
    }
  })
    .then((res) => {
      dispatch({ type: actionTypes.SET_LLM_FILTERS, data: res?.data });
      return {status: 'success'};
    })
    .catch((err) => {
      console.error(err);
      return {status: 'failed'};
    });
};

export const setWatchDiscoveryItem = (axiosInstance, payload) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    data: payload,
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_DISCOVERY_WATCH_STATUS,
        data: res?.data,
      });
      if (res?.data?.hasOwnProperty("error") || res?.data?.result === "failure") {
        return { status: "failed", message: res.data.error };
      } else {
        return { status: "success", message: res.data.message };
      }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" };
    });
};

export const setUrlDiscoveryParams = (uuid, watched) => ({
  type: actionTypes.SET_URL_DISCOVERY_PARAMS,
  data: { uuid, watched },
});

export const setAppliedFilters = (appliedFilters) => ({
  type: actionTypes.SET_APPLIED_FILTERS,
  payload: appliedFilters,
});

export const getQuipTypes = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_QUIP_TYPES,
        data: res?.data?.quip_types,
      });
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

export const getAllDataSource = (axiosInstance) => async (dispatch) => {
  try {
    return axiosInstance({
      method: "GET",
      data: {},
      params: {},
      headers: {
        Authorization: getAuthToken(),
      },
    }).then((res) => {
      dispatch({
        type: actionTypes.SET_ALL_DATA_SOURCES,
        data: res.data,
      });
      return { status: "success" };
    });
  } catch (error) {
    console.warn("Response - error ->", error?.response?.data?.message);
    return {
      status: "failed",
      errorMessage: error?.response?.data?.message || "Something went wrong!",
    };
  }
};