import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import moment from "moment";
import { getAddress } from "../../../utils";
import {updateDiscoveryCompanies, getLoggedInUser} from "../../../store/actions";
import { axiosUserInfoInstance } from "../../../config/appConfig/axiosInstance";
import * as actionTypes from "../../../store/actionTypes";
import { ReactComponent as LinkedInSvg } from "../../../assets/images/icon_svg/linkedin-cards-logo.svg";
import { ReactComponent as AffinitySvg } from "../../../assets/images/icon_svg/affinity_new_logo.svg";
import { ReactComponent as CompanyUrlSvg } from "../../../assets/images/icon_svg/url-cards.svg";
import { ReactComponent as UpArrowIcon } from '../../../assets/images/icon_svg/growthUp.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/images/icon_svg/growthDown.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/icon_svg/growthRight.svg';
import { ReactComponent as SortByIcon } from '../../../assets/images/icon_svg/sort-by.svg';
import { ReactComponent as FoundedYearIcon } from '../../../assets/images/icon_svg/founded_year.svg';
import { ReactComponent as VisitedByIcon } from '../../../assets/images/icon_svg/visited-by-icon.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/icon_svg/clock-blue.svg';
import { ReactComponent as CurrentRankIcon } from '../../../assets/images/icon_svg/current-rank-icon.svg';
import "./discoverycards.scss";
import Slider from "../Slider/Slider";

let cancelToken;
let isApiCalling = false;
let lastScrollTop = 0;

const DiscoveryCards = ({ appliedFilterString, filterSortState, llmFilter, tabChanged, handleSelectedDomain, rightSideContent, handleContextChange, focusIndexCallback, showEnterPromptCallback }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const cardRefs = useRef([]);
  const [filteredData, userInfo] = useSelector(({ discoveryReducer, dashBoardReducer }) => [discoveryReducer.discoveryCompanies, dashBoardReducer.userInfo]);
  const [sliderContent, setSliderContent] = useState("");
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState({});
  const [sortedAndFilteredData, setSortedAndFilteredData] = useState([]);
  const [focusedCardIndex, setFocusedCardIndex] = useState(0);
  const [visibleData, setVisibleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);

  useEffect(() => {
    if(!userInfo) dispatch(getLoggedInUser(axiosUserInfoInstance));
  }, [])

  useEffect(() => {
    if (sliderContent) {
      handleSelectedDomain(sliderContent);
    }
  }, [sliderContent]);

  useEffect(() => {
    focusIndexCallback(focusedCardIndex);
  }, [focusedCardIndex]);

  const formatNumber = (number) => {
    if (number === "n/a") {
      return "n/a";
    }
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(2) + "B";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(2) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + "K";
    } else {
      return number.toString();
    }
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const GrowthIcon = ({ value }) => {
    if (value > 0) return <UpArrowIcon />;
    if (value < 0) return <DownArrowIcon />;
    return <RightArrowIcon />;
  };

  const getDaysAgo = createdAt => {
    const createdDate = moment(createdAt)
    const today = moment()
    const diffDays = today.diff(createdDate, 'days')
    return diffDays === 0 ? 'Today' : `${diffDays} days ago`
  }

  useEffect(() => {
    let data = [...(filteredData?.results || [])];
    if (filterSortState.filterTpStatus) {
      data = data.filter(
        (company) => company.tp_status === filterSortState.filterTpStatus
      );
    }
    if (filterSortState.filterTpOwner) {
      data = data.filter((company) =>
        company.tp_owners?.includes(filterSortState.filterTpOwner)
      );
    }

    data.sort((a, b) => {
      if (filterSortState.sortCriteria === "Company Name") {
        return a.company_name.localeCompare(b.company_name);
      }
      if (filterSortState.sortCriteria === "TP Status") {
        return (a.tp_status || "").localeCompare(b.tp_status || "");
      }
      if (filterSortState.sortCriteria === "TP Owners") {
        return (a.tp_owners?.[0] || "").localeCompare(b.tp_owners?.[0] || "");
      }
      return 0;
    });
    setSortedAndFilteredData(data);
    setVisibleData(data);
    setNextPage(filteredData?.next_page);
    isApiCalling = false;
    setLoading(false);

    if (data.length > 0) {
        setSliderContent(data[0].company_domain);
    } else {
        setSliderContent(null);
    }
  }, [filterSortState, filteredData, tabChanged]);

  useEffect(() => {
    if (tabChanged) {
      setFocusedCardIndex(0);
      
      if (scrollRef.current) {
        scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [tabChanged]);

  const useDebounce = (callback, delay) => {
    const [debounceTimer, setDebounceTimer] = useState(null);

    return (...args) => {
      if (debounceTimer) clearTimeout(debounceTimer);
      setDebounceTimer(setTimeout(() => callback(...args), delay));
    };
  };
  const loadMoreData = async () => {

    if (isApiCalling) return;
    if (!nextPage) return;
    isApiCalling = true;
    setLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("operation cancelled due to new req")
      }
      const CancelToken = axios.CancelToken;
      cancelToken = CancelToken.source();

      const queryString = appliedFilterString;
      const cleanedString = queryString.startsWith("?") ? queryString.substring(1) : queryString;
      // Fetch data from the API
      const url = (llmFilter && llmFilter === "Show all companies mentioned in newsletters") ? `${getAddress() + `/ranked-quip-mentions?page=${nextPage}&${cleanedString}`}` :`${getAddress() + `/discovery?page=${nextPage}&${cleanedString}`}`;
      
      const data = await updateDiscoveryCompanies(url, cancelToken)(dispatch);

      if (data?.data && data?.data?.results?.length) {
        let final = [...sortedAndFilteredData, ...data?.data?.results];
        data.data.results = final;
        // Dispatch new data to the reducer
        dispatch({
          type: actionTypes.SET_DISCOVERY_COMPANY,
          data: data.data,
        });
      }
    } catch (error) {
      isApiCalling = false;
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const loadCompanyDetails = () => {
    if (sortedAndFilteredData[focusedCardIndex] && rightSideContent !== 'filters') {
        setSliderContent(sortedAndFilteredData[focusedCardIndex].company_domain);
        showEnterPromptCallback(false);
    }
  };

  const debouncedLoadMoreData = useDebounce(loadMoreData, 100);
  const debouncedLoadCompanyDetails = useDebounce(loadCompanyDetails, 1500);

  const handleScroll = () => {
    if (!scrollRef.current) return;
    const container = scrollRef.current;
    const containerHeight = container.offsetHeight;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    if (scrollTop > lastScrollTop) {
      if (
        scrollTop > lastScrollTop && // Ensure downward scrolling
        scrollTop + containerHeight >= scrollHeight * 0.85 && // Ensure near the bottom
        !isApiCalling && // Prevent multiple API calls while waiting for response
        nextPage
      ) {
        debouncedLoadMoreData();
      }
    }
    lastScrollTop = scrollTop;
  };

  useEffect(() => {
    const container = scrollRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    };

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, debouncedLoadMoreData]);

  const handleKeyDown = (e) => {
    if (!sortedAndFilteredData.length) return;

    let newIndex = focusedCardIndex;
    const totalCards = sortedAndFilteredData.length;

    if ((e.key === "ArrowDown" || e.key === "ArrowRight") ) {
      newIndex = Math.min(focusedCardIndex + 1, totalCards - 1);
      e.preventDefault();
      showEnterPromptCallback(true);
    } else if ((e.key === "ArrowUp" || e.key === "ArrowLeft") ) {
      newIndex = Math.max(focusedCardIndex - 1, 0);
      e.preventDefault();
      showEnterPromptCallback(true);
    } else if (e.key === "Enter") {
      const focusedCompany = sortedAndFilteredData[focusedCardIndex];
      if (focusedCompany) {
        handleCardClick(focusedCompany.company_domain, focusedCardIndex);
        showEnterPromptCallback(false); 
      }
      return;
    }

    setFocusedCardIndex(newIndex);
    scrollToCard(newIndex);
  };

  const scrollToCard = (index) => {
    const card = cardRefs.current[index];
    if (card) {
      // Get the offset of the card relative to the container
      const containerRect = scrollRef?.current?.getBoundingClientRect();
      const cardRect = card.getBoundingClientRect();
      // Calculate the scroll offset
      const scrollOffset = cardRect.top - containerRect.top - 40;
      // Scroll the container to the desired position
      scrollRef.current.scrollTop += scrollOffset;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [sortedAndFilteredData, focusedCardIndex]);

  useEffect(() => {
    if (sortedAndFilteredData?.length > 0) {
        debouncedLoadCompanyDetails();
    }
  }, [focusedCardIndex]);

  const CompanyGrowth = ({ growth_1m, growth_6m, growth_1y, growth_2y }) => {
    // Check if there's at least one valid growth value to display the section
    const hasValidGrowthData = [growth_1m, growth_6m, growth_1y, growth_2y].some(value => value !== null && value !== 'n/a');

    if (!hasValidGrowthData) return null; // Hide the section if no valid data

    const growthData = [
      { label: '1 Mo.', value: growth_1m },
      { label: '6 Mos.', value: growth_6m },
      { label: '1 Yr.', value: growth_1y },
      { label: '2 Yrs.', value: growth_2y },
    ];

    return (
      <div className="growth-section">
        <div className='growth-sec d-flex flex-wrap mb-2 justify-content-around'>
          {growthData.map(({ label, value }, index) => (
            <div key={index} className='growth-box d-flex'>

              <div className='growth-value d-flex align-items-center justify-content-center'>
                <span className='growth-icon'>
                  <GrowthIcon value={value} />
                </span>
                <span>
                  <b className='growth-val'>
                    {value !== null && value !== 'n/a' ? `${value}%` : 'N/A'}
                  </b>
                </span>
              </div>

              <div className='growth-text'>({label})</div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const handleCardClick = (companyDomain, index) => {
    if (companyDomain === sliderContent) return;
    setSliderContent(companyDomain);
    setFocusedCardIndex(index);
    showEnterPromptCallback(false); 
    if(rightSideContent !== 'slider') handleContextChange();
  };

  const handleLogoClick = (url) => {
    const fullUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://www.${url}`;
    window.open(fullUrl, '_blank');
  };

  const truncateDescription = (description) => {
    if (!description) return '';
    return description?.length > 250 ? `${description?.slice(0, 250)}...` : description;
  };

  const handleDescriptionToggleView = (e, index) => {
    e?.stopPropagation();
    setIsDescriptionExpanded(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const getCompanyViewStatus = (company, userEmail) => {
    if (!company?.scopers_who_viewed || company?.scopers_who_viewed?.length === 0) {
      return { statusClass: "not-visited", visitStatus: "" };
    }
  
    const hasUserViewed = company.scopers_who_viewed?.includes(userEmail);
    const otherViewers = company.scopers_who_viewed.length > 1;
  
    if (hasUserViewed && !otherViewers) {
      return { statusClass: "visited-by-me", visitStatus: "Visited by Me" };
    } else if (hasUserViewed && otherViewers) {
      return { statusClass: "visited-by-others-me", visitStatus: "Visited by Others & Me" };
    } else {
      return { statusClass: "visited-by-scoper", visitStatus: "Visited by Scoper" };
    }
  };

  return (
        <div className="card-contrainer"  style={{ height: '100%' }}>
          <div className={`discovery-company-data`} >
            <div className={`discovery-company-container`} >
              <div className="card-container-wrapper" ref={scrollRef}>
                <div className="d-flex flex-wrap gap-2 " style={{ marginBottom: '75px' }}>
                  {visibleData?.map((company, index) => {
                    const { statusClass, visitStatus } = getCompanyViewStatus(company, userInfo?.email);
                    return (
                      <div key={index} ref={(el) => (cardRefs.current[index] = el)} className="card-wrapper">
                        <div className={`outer-card ${index === focusedCardIndex ? "focused-card" : ""}`} onClick={() => handleCardClick(company.company_domain, index)}>
                          <div className="inner-content" >
                            <div className="company-card">
                              <div className={`company-info-container ${statusClass}`}>
                                <div className="company-header d-flex justify-content-between align-items-center">
                                  <div className="logo-box">
                                    {company?.company_logo && company?.company_logo !== 'n/a' ? (
                                      <a href={`https://${company?.company_domain}`} target="_blank" rel="noopener noreferrer">
                                        <div className="logo-container">
                                            <img
                                              className="company-logo"
                                              id="company-logo-id"
                                              alt="company-logo"
                                              src={company?.company_logo}
                                            />
                                        </div>
                                      </a>
                                    ) : null}
                                    <div className="company-name">{company.company_name}</div>
                                  </div>
                                  <div className="company-logos">
                                    {company?.company_domain && company?.company_domain !== 'n/a' && (
                                      <span className="company-logo-img" onClick={() => handleLogoClick(company.company_domain)}>
                                        <CompanyUrlSvg />
                                      </span>
                                    )}
                                    {company?.url && company?.url !== 'n/a' && (
                                      <span className="company-logo-img" onClick={() => handleLogoClick(company.url)}>
                                        <AffinitySvg style={{ marginLeft: "6px" }} />
                                      </span>
                                    )}
                                    {company?.linkedin_url && company?.linkedin_url !== 'n/a' && (
                                      <span className="company-logo-img" onClick={() => handleLogoClick(company.linkedin_url)}>
                                        <LinkedInSvg />
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="year-founded">
                                  <div className="company-founded-data">
                                    <span className="year-founded-content">
                                      <FoundedYearIcon className='founded-year-icon' />
                                      <span className='company-year-founded'>Founded year: <strong className='year-founded-data'>{company?.year_founded || 'n/a'}</strong></span>
                                    </span>
                                    {company?.current_rank && company?.current_rank !== 'n/a' ? (
                                      <span className="current-rank">
                                        <span className="current-rank-content">
                                          <CurrentRankIcon className="current-rank-icon" />
                                          <span className="current-rank-data">Current Rank: <strong className="current-ranking">{company?.current_rank}</strong></span>
                                        </span>
                                      </span>
                                    ) : null}
                                    {company?.previous_rank && company?.previous_rank !== 'n/a' ? (
                                      <span className="current-rank">
                                        <span className="current-rank-content">
                                          <CurrentRankIcon className="current-rank-icon" />
                                          <span className="current-rank-data">Previous Rank: <strong className="current-ranking">{company?.previous_rank}</strong></span>
                                        </span>
                                      </span>
                                    ) : null}
                                  </div>
                                  <div className="visited-data">
                                    {visitStatus && (
                                      <span className="visit-status">
                                        <span className="visit-status-content">
                                          <VisitedByIcon className="visited-by-icon" />
                                          <span className="visited-by">{visitStatus}</span>
                                        </span>
                                      </span>
                                    )}
                                    {company?.last_viewed && company?.last_viewed !== 'n/a' ? (
                                      <span className="last-viewed">
                                        <span className="last-viewed-content">
                                          <ClockIcon className="last-viewed-clock-icon" />
                                          <span className="last-viewed-data">Last visited: <span className="last-viewed-time">{getDaysAgo(company?.last_viewed)}</span></span>
                                        </span>
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="company-description">
                                <div className="tp-info">
                                  <div className="tp-status">
                                    <i>TP Status: </i>
                                    <span className="tp-status-value">{company.tp_status?.toUpperCase() || 'N/A'}</span>
                                  </div>
                                  <div className="tp-owners">
                                    <i>TP Owners: </i>
                                    {company.tp_owners?.map((owner, idx) => (
                                      <span key={idx} className="tp-owner-value">{owner}</span>
                                    ))}
                                  </div>
                                </div>
                                {/* <div className="lightdivider"></div> */}
                                <div className={`growth-funding-block ${!CompanyGrowth({ ...company }) ? "no-growth-data" : ""}`}>
                                    <CompanyGrowth {...company} />
                                  <div className="funding-info mb-2 d-flex ">
                                    <div>
                                      <strong>Total Funded:</strong> {company.total_funding_usd === "n/a" ? "n/a" : `$${formatNumber(company.total_funding_usd)}`}
                                    </div>
                                    <div>
                                      <strong>Last Funded On:</strong>{" "}
                                      {company.last_funding_on === "n/a" || !isValidDate(company.last_funding_on)
                                        ? "n/a"
                                        : formatDate(company.last_funding_on)}
                                    </div>
                                  </div>
                                </div>
                                {company?.description && company?.description !== 'n/a' && (
                                  <div className="desc mb-2">
                                    <span className="description-text">
                                      {isDescriptionExpanded[index] ? company?.description : truncateDescription(company?.description)}
                                      {company?.description?.length > 250 && (
                                        <span className="view-more-description" onClick={(e) => handleDescriptionToggleView(e, index)}>
                                          {isDescriptionExpanded[index] ? ' View Less' : 'View More'}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {loading && <div className="loading-more-data"> Kindly wait, we are loading more companies...</div>}
              </div>
            </div>
          </div>
        </div>
  );
};

export default DiscoveryCards;
